import React from 'react';
import { Label } from 'semantic-ui-react'
import { version } from '../../package.json';

export default () =>
  <Label
    style={{ position: 'fixed' }}
    attached='bottom right'
  >
    {`v ${version}`}
  </Label>
