import React, { Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import Version from '../components/version';
import LoginForm from '../components/forms/login';
import ForgotPasswordForm from '../components/forms/forgotPassword';
import SetPasswordForm from '../components/forms/setPassword';
import { Mutation, withApollo } from 'react-apollo';
import { SIGNIN, SEND_RESET_PASSWORD_EMAIL, SET_NEW_PASSWORD } from '../graphql/mutations';
import services from '../services';
const session = services.session;

const PublicLayout = ({
  path,
  client,
  location,
}) =>
  <Fragment>
    <Switch>
      <Route
        path={path}
        exact
        render={() =>
          <Mutation
            mutation={SIGNIN}
            onCompleted={async ({ response }) => {
              await session.save(response);
            }}
          >
          {(signin, { loading, error } ) =>
            <LoginForm
              signin={signin}
              loading={loading}
              error={error}
            />
          }
          </Mutation>
        }
      />
      <Route
        path={`${path}forgot_password`}
        render={() =>
          <Mutation
            mutation={SEND_RESET_PASSWORD_EMAIL}
          >
          {(sendResetPasswordEmail, { loading, error } ) =>
            <ForgotPasswordForm
              sendResetPasswordEmail={sendResetPasswordEmail}
              loading={loading}
              error={error}
            />
          }
        </Mutation>
        }
      />
      <Route
        path={`${path}set_password`}
        render={() =>
          <Mutation
            mutation={SET_NEW_PASSWORD}
            onCompleted={async ({ response }) => {
              await session.save(response);
            }}
          >
          {(setNewPassword, { loading, error } ) =>
            <SetPasswordForm
              location={location}
              setNewPassword={setNewPassword}
              loading={loading}
              error={error}
            />
          }
        </Mutation>
        }
      />
    </Switch>
    <Version />
  </Fragment>

export default withApollo(withRouter(PublicLayout));
