import React, { useState, Fragment } from "react";
import {
  Icon,
  Accordion,
  Segment,
  Popup,
  Label,
  Header,
  Container,
  Message,
  Card,
  Menu,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment";
import CustomerContent from "./content";

import GatewaysList from "../gateway/list";
import CompressorsList from "../compressor/list";
import ChildrenList from "../child/list";
import CalendarsList from "../calendar/list";
import EmailAlarmsList from "../emailAlarms/list";
import RemainingHoursList from "../remainingHours/list";
import {MutateEmailProcessing} from "../../mutations/dashboard";

const CustomersList = ({ currentUser, dashboardCustomers, alarmemailbox }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [type, setType] = useState("all");
  const [status, setStatus] = useState("all");

  const handleToggle = index => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
    setType("all");
    setStatus("all");
  };

  const getMalfunctionsCount = (customer, status) => {
    return (
      customer.gateways.reduce(
        (acc, gateway) =>
          acc +
          gateway.alarms.malfunction.filter(
            malfunction => malfunction.status === status
          ).length,
        0
      ) +
      customer.emailAlarms.filter(emailAlarm => emailAlarm.status === status)
        .length
    );
  };

  const getWarningsCount = (customer, status) => {
    return (
      customer.compressors.reduce(
        (acc, compressor) =>
          acc +
          compressor.alarms.warning.filter(warning => warning.status === status)
            .length,
        0
      ) +
      customer.children.reduce(
        (acc, child) =>
          acc +
          child.alarms.warning.filter(warning => warning.status === status)
            .length,
        0
      ) +
      customer.calendars.reduce(
        (acc, calendar) =>
          acc +
          calendar.alarms.warning.filter(warning => warning.status === status)
            .length,
        0
      )+
      customer.remainingHours.reduce(
          (acc, remainingHours) =>
              acc +
              remainingHours.alarms.warning.filter(warning => warning.status === status)
                  .length,
          0
      )
    );
  };

  const getMaintenanceCount = (customer, status) => {
    return (
      customer.compressors.reduce(
        (acc, compressor) =>
          acc +
          compressor.alarms.maintenance.filter(
            maintenance => maintenance.status === status
          ).length,
        0
      ) +
      customer.children.reduce(
        (acc, child) =>
          acc +
          child.alarms.maintenance.filter(
            maintenance => maintenance.status === status
          ).length,
        0
      ) +
      customer.calendars.reduce(
        (acc, calendar) =>
          acc +
          calendar.alarms.maintenance.filter(
            maintenance => maintenance.status === status
          ).length,
        0
      )+
      customer.remainingHours.reduce(
          (acc, remainingHours) =>
              acc +
              remainingHours.alarms.maintenance.filter(
                  maintenance => maintenance.status === status
              ).length,
          0
      )
    );
  };

  const getLastAlarm = customer => {
    const emailAlarms = customer.emailAlarms;
    const gatewayAlarms = customer.gateways.reduce(
      (acc, gateway) => [...acc, ...gateway.alarms.malfunction],
      []
    );
    const compressorAlarms = customer.compressors.reduce(
      (acc, compressor) => [
        ...acc,
        ...compressor.alarms.warning,
        ...compressor.alarms.maintenance
      ],
      []
    );
    const calendarAlarms = customer.calendars.reduce(
      (acc, calendar) => [
        ...acc,
        ...calendar.alarms.warning,
        ...calendar.alarms.maintenance
      ],
      []
    );

    const remainingHoursAlarms = customer.remainingHours.reduce(
        (acc, remainingHours) => [
          ...acc,
          ...remainingHours.alarms.warning,
          ...remainingHours.alarms.maintenance
        ],
        []
    );

    const allCustomerAlarms = [
      ...emailAlarms,
      ...gatewayAlarms,
      ...compressorAlarms,
      ...calendarAlarms,
      ...remainingHoursAlarms,
    ];

    const sortedCustomerAlarms = allCustomerAlarms.sort((a, b) =>
      moment(a.timestamp) < moment(b.timestamp) ? 1 : -1
    );

    const lastCustomerAlarm = sortedCustomerAlarms.length
      ? sortedCustomerAlarms[0]
      : null;

    return lastCustomerAlarm;
  };

  const showFiltered = (newType, newStatus, index) => {
    const newIndex =
      activeIndex === index && status === newStatus && type === newType
        ? -1
        : index;
    setActiveIndex(newIndex);
    setType(newType);
    setStatus(newStatus);
  };

  const labelStyles = {display: 'flex', alignItems: 'center', minWidth: '440px', justifyContent: 'space-between'};

  return (
    <Fragment>
      <Card fluid style={{ minWidth: "584px" }}>
        <Card.Content>
          <Card.Header>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <span>Maintenance and warning status dashboard</span>
              {alarmemailbox.enabled
                ? <div style={labelStyles}>{currentUser.role === 4 ? <MutateEmailProcessing enabled={alarmemailbox.enabled}>Stop email processing</MutateEmailProcessing> : <span></span>}<span>{`Processing  email  [${alarmemailbox.hoursFrom}:00 – ${alarmemailbox.hoursTo}:00]`}</span></div>
                : <div style={labelStyles}>{currentUser.role === 4 ? <MutateEmailProcessing enabled={alarmemailbox.enabled}>Start email processing</MutateEmailProcessing> : <span></span>}<span>Processing  email  stopped</span></div>}
            </div>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Accordion>
            <Segment.Group>
              {dashboardCustomers.length ? (
                dashboardCustomers
                  .filter(
                    customer =>
                      getMalfunctionsCount(customer, "ACKNOWLEDGED") +
                        getMalfunctionsCount(customer, "ACTIVE") +
                        getWarningsCount(customer, "ACKNOWLEDGED") +
                        getWarningsCount(customer, "ACTIVE") +
                        getMaintenanceCount(customer, "ACKNOWLEDGED") +
                        getMaintenanceCount(customer, "ACTIVE") >
                      0
                  )
                  .sort((a, b) =>
                    moment(getLastAlarm(a).timestamp) <
                    moment(getLastAlarm(b).timestamp)
                      ? 1
                      : -1
                  )
                  .map((customer, i) => (
                    <Segment key={customer.id}>
                      <Accordion.Title style={{ cursor: "inherit" }}>
                        <Container
                          style={{
                            display: "inline-flex",
                            alignItems: "baseline",
                            flexWrap: "wrap"
                          }}
                        >
                          <Header
                            as="h4"
                            style={{
                              display: "flex",
                              width: "100%",
                              maxWidth: "522px",
                              justifyContent: "space-between",
                              marginBottom: "0.2rem",
                              marginRight: "1rem",
                              color:
                                customer.id === "TEMP_ID" ? "lightgrey" : ""
                            }}
                          >
                            <Link
                              style={{ width: "30%" }}
                              to={{
                                pathname: "customers",
                                search: `?customer=${customer.id}`
                              }}
                            >
                              {customer.name}
                            </Link>
                            <Label style={{ width: "30%" }}>
                              Number:
                              <Label.Detail>{customer.number}</Label.Detail>
                            </Label>
                            <Label style={{ width: "30%" }}>
                              Contract Number:
                              <Label.Detail>
                                {customer.contractNumber}
                              </Label.Detail>
                            </Label>
                          </Header>
                          <Menu
                            compact
                            size="mini"
                            borderless
                            style={{
                              width: "100%",
                              maxWidth: "522px",
                              minWidth: "522px"
                            }}
                          >
                            <Menu.Item>
                              Malfunctions
                              <Label
                                color="grey"
                                style={{
                                  cursor: "pointer",
                                  visibility: getMalfunctionsCount(
                                    customer,
                                    "ACKNOWLEDGED"
                                  )
                                    ? "visible"
                                    : "hidden"
                                }}
                                as="button"
                                onClick={showFiltered.bind(
                                  null,
                                  "malfunction",
                                  "ACKNOWLEDGED",
                                  i
                                )}
                              >
                                {getMalfunctionsCount(customer, "ACKNOWLEDGED")}
                              </Label>
                              <Label
                                color="red"
                                style={{
                                  cursor: "pointer",
                                  visibility: getMalfunctionsCount(
                                    customer,
                                    "ACTIVE"
                                  )
                                    ? "visible"
                                    : "hidden"
                                }}
                                as="button"
                                onClick={showFiltered.bind(
                                  null,
                                  "malfunction",
                                  "ACTIVE",
                                  i
                                )}
                              >
                                {getMalfunctionsCount(customer, "ACTIVE")}
                              </Label>
                            </Menu.Item>
                            <Menu.Item>
                              Warnings
                              <Label
                                color="grey"
                                style={{
                                  cursor: "pointer",
                                  visibility: getWarningsCount(
                                    customer,
                                    "ACKNOWLEDGED"
                                  )
                                    ? "visible"
                                    : "hidden"
                                }}
                                as="button"
                                onClick={showFiltered.bind(
                                  null,
                                  "warning",
                                  "ACKNOWLEDGED",
                                  i
                                )}
                              >
                                {getWarningsCount(customer, "ACKNOWLEDGED")}
                              </Label>
                              <Label
                                color="yellow"
                                style={{
                                  cursor: "pointer",
                                  visibility: getWarningsCount(
                                    customer,
                                    "ACTIVE"
                                  )
                                    ? "visible"
                                    : "hidden"
                                }}
                                as="button"
                                onClick={showFiltered.bind(
                                  null,
                                  "warning",
                                  "ACTIVE",
                                  i
                                )}
                              >
                                {getWarningsCount(customer, "ACTIVE")}
                              </Label>
                            </Menu.Item>
                            <Menu.Item>
                              Maintenance
                              <Label
                                color="grey"
                                style={{
                                  cursor: "pointer",
                                  visibility: getMaintenanceCount(
                                    customer,
                                    "ACKNOWLEDGED"
                                  )
                                    ? "visible"
                                    : "hidden"
                                }}
                                as="button"
                                onClick={showFiltered.bind(
                                  null,
                                  "maintenance",
                                  "ACKNOWLEDGED",
                                  i
                                )}
                              >
                                {getMaintenanceCount(customer, "ACKNOWLEDGED")}
                              </Label>
                              <Label
                                color="red"
                                style={{
                                  cursor: "pointer",
                                  visibility: getMaintenanceCount(
                                    customer,
                                    "ACTIVE"
                                  )
                                    ? "visible"
                                    : "hidden"
                                }}
                                as="button"
                                onClick={showFiltered.bind(
                                  null,
                                  "maintenance",
                                  "ACTIVE",
                                  i
                                )}
                              >
                                {getMaintenanceCount(customer, "ACTIVE")}
                              </Label>
                            </Menu.Item>
                            <Menu.Item>
                              <Popup
                                trigger={
                                  <Icon
                                    name={
                                      activeIndex === i ? "eye slash" : "eye"
                                    }
                                    link
                                    style={{ float: "right" }}
                                    onClick={handleToggle.bind(null, i)}
                                  />
                                }
                                content={
                                  activeIndex === i ? "hide all" : "show all"
                                }
                                size="mini"
                              />
                            </Menu.Item>
                          </Menu>
                        </Container>
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex === i}>
                        {activeIndex === i && (
                          <CustomerContent customer={customer}>
                            {({ data }) => (
                              <Fragment>
                                <EmailAlarmsList
                                  currentUser={currentUser}
                                  data={
                                    data.customerChildren
                                      ? data.customerChildren.emailAlarms.filter(
                                          emailAlarm =>
                                            (type === "all" &&
                                              status === "all") ||
                                            (type === "malfunction" &&
                                              emailAlarm.status === status)
                                        )
                                      : []
                                  }
                                  customer={customer}
                                />
                                <GatewaysList
                                  currentUser={currentUser}
                                  data={
                                    data.customerChildren
                                      ? data.customerChildren.gateways
                                          .filter(
                                            gateway =>
                                              gateway.alarms.malfunction.length
                                          )
                                          .filter(
                                            gateway =>
                                              (type === "all" &&
                                                status === "all") ||
                                              (type === "malfunction" &&
                                                gateway.alarms.malfunction[0]
                                                  .status === status)
                                          )
                                      : []
                                  }
                                />
                                <CompressorsList
                                  currentUser={currentUser}
                                  data={
                                    data.customerChildren
                                      ? data.customerChildren.compressors
                                          .filter(
                                            compressor =>
                                              compressor.alarms.warning
                                                .length ||
                                              compressor.alarms.maintenance
                                                .length
                                          )
                                          .filter(
                                            compressor =>
                                              (type === "all" &&
                                                status === "all") ||
                                              (compressor.alarms[type].length &&
                                                compressor.alarms[type][0]
                                                  .status === status)
                                          )
                                      : []
                                  }
                                  gateways={
                                    data.customerChildren
                                      ? data.customerChildren.gateways
                                      : []
                                  }
                                />
                                <RemainingHoursList
                                    currentUser={currentUser}
                                    data={
                                      data.customerChildren
                                          ? data.customerChildren.remainingHours
                                              .filter(
                                                  remainingHours =>
                                                      remainingHours.alarms.warning
                                                          .length ||
                                                      remainingHours.alarms.maintenance
                                                          .length
                                              )
                                              .filter(
                                                  remainingHours =>
                                                      (type === "all" &&
                                                          status === "all") ||
                                                      (remainingHours.alarms[type].length &&
                                                          remainingHours.alarms[type][0]
                                                              .status === status)
                                              )
                                          : []
                                    }
                                    gateways={
                                      data.customerChildren
                                          ? data.customerChildren.gateways
                                          : []
                                    }
                                />
                                <ChildrenList
                                  currentUser={currentUser}
                                  data={
                                    data.customerChildren
                                      ? data.customerChildren.children
                                          .filter(
                                            child =>
                                              child.alarms.warning.length ||
                                              child.alarms.maintenance.length
                                          )
                                          .filter(
                                            child =>
                                              (type === "all" &&
                                                status === "all") ||
                                              (child.alarms[type].length &&
                                                child.alarms[type][0].status ===
                                                  status)
                                          )
                                      : []
                                  }
                                  compressors={
                                    data.customerChildren
                                      ? data.customerChildren.compressors
                                      : []
                                  }
                                />
                                <CalendarsList
                                  currentUser={currentUser}
                                  data={
                                    data.customerChildren
                                      ? data.customerChildren.calendars
                                          .filter(
                                            calendar =>
                                              calendar.alarms.warning.length ||
                                              calendar.alarms.maintenance.length
                                          )
                                          .filter(
                                            calendar =>
                                              (type === "all" &&
                                                status === "all") ||
                                              (calendar.alarms[type].length &&
                                                calendar.alarms[type][0]
                                                  .status === status)
                                          )
                                      : []
                                  }
                                />
                              </Fragment>
                            )}
                          </CustomerContent>
                        )}
                      </Accordion.Content>
                    </Segment>
                  ))
              ) : (
                <Message positive>
                  <Message.Content>No alarms found</Message.Content>
                </Message>
              )}
            </Segment.Group>
          </Accordion>
        </Card.Content>
      </Card>
    </Fragment>
  );
};

export default CustomersList;
