import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router";
import { Query } from "react-apollo";
import { ToastContainer } from "react-toastify";
import PrivateLayout from "./containers/privateLayout";
import PublicLayout from "./containers/publicLayout";
import VerifyEmail from "./components/verifyEmail";

import { IS_LOGGED_IN } from "./graphql/queries";
import { VERIFY_EMAIL } from "./graphql/mutations";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isLoggedIn ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !rest.isLoggedIn ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={props.location.state ? props.location.state.from : "/app"}
        />
      )
    }
  />
);

class App extends Component {
  render() {
    return (
      <div className="App">
        <Query query={IS_LOGGED_IN}>
          {({ data, loading, client }) => {
            return loading ? (
              "Loading..."
            ) : (
              <Switch>
                <Route
                  path="/verify_email"
                  render={() => (
                    <VerifyEmail
                      location={this.props.location}
                      verifyEmail={VERIFY_EMAIL}
                      apolloClient={client}
                    />
                  )}
                />
                <PrivateRoute
                  path="/app"
                  component={PrivateLayout}
                  {...this.props}
                  isLoggedIn={data && data.isLoggedIn}
                />
                <PublicRoute
                  path="/"
                  component={PublicLayout}
                  {...this.props}
                  isLoggedIn={data && data.isLoggedIn}
                />
              </Switch>
            );
          }}
        </Query>
        <ToastContainer autoClose={2000} />
      </div>
    );
  }
}

export default withRouter(App);
