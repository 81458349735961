import React from "react";
import {SET_EMAIL_PROCESSING} from '../../../graphql/mutations';
import {Mutation} from "react-apollo";
import {GET_ALARMEMAILBOX} from "../../../graphql/queries";
import {Button} from "semantic-ui-react";

export const MutateEmailProcessing = ({
                                          enabled,
                                          children,
                                      }) =>
    <Mutation
        mutation={SET_EMAIL_PROCESSING}
        update={(cache, {data}) => {
            const cachedData = cache.readQuery({query: GET_ALARMEMAILBOX});
            cache.writeQuery({
                query: GET_ALARMEMAILBOX, data: {
                    dashboardData: {...cachedData.dashboardData, alarmemailbox: data.setEmailProcessing}
                }
            });
        }}
    >
        {(mutateEmailProcessing, {loading, error}) =>
            <Button
                loading={loading}
                onClick={() => mutateEmailProcessing({variables: {payload: {enabled: !enabled}}})}
                positive={!enabled}
                negative={enabled}
            >
                {children}
            </Button>
        }
    </Mutation>;


