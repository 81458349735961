import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Container } from 'semantic-ui-react';
import { MutateGateway } from '../components/mutations/gateway';
import { MAP_PAGE } from '../graphql/queries';
import Map from './map';
import cfg from '../config';

const defaulCenter = { lat: cfg.DEFAULT_CENTER_LAT, lng: cfg.DEFAULT_CENTER_LON };

const initModals = {
  gatewayOpen: false,
  gateway : null,
  customer: null,
};

class MapPage extends Component {
  state = {
    center: defaulCenter,
    zoom: cfg.DEFAULT_ZOOM,
    ...initModals,      
  };

  handleZoomChange = zoom => {
    this.setState({ zoom });
  }
  
  handleCenterChange = center => {
    this.setState({ center });
  }

  openGateway = (gateway, customer) => {
    this.setState({
      gatewayOpen: true,
      gateway,
      customer,
    });
  }

  closeModal = () => {
    this.setState(initModals);
  }

  render() {
    return <Query query={MAP_PAGE} fetchPolicy="network-only" notifyOnNetworkStatusChange>
             {({ data, loading, client, error, fetchMore, networkStatus }) =>
             <Container fluid>
              {loading
                ?  <div>Please wait...</div>

                  : <Map
                      markers={data
                        ? data.mapPage.reduce((acc, customer) =>
                          [...acc, ...(customer.gateways
                            .filter(gateway => gateway.location && !(gateway.location.lat === 0 && gateway.location.lon === 0))
                            .map(gateway => ({
                                gateway,
                                customer: customer.name,
                            })))]
                        , [])
                        : []}
                      onClick={this.openGateway}
                      center={this.state.center}
                      zoom={this.state.zoom}
                      handleCenterChange={this.handleCenterChange}
                      handleZoomChange={this.handleZoomChange}
                  />
              }
              {this.state.gatewayOpen &&
                <MutateGateway
                  gateway={data.mapPage.find(customer => customer.id === this.state.gateway.parentId).gateways.find(gateway => gateway.id === this.state.gateway.id)}
                  customer={this.state.customer}
                  customers={data.mapPage}
                  edit={true}
                  gatewayOpen={this.state.gatewayOpen}
                  closeGatewayModal={this.closeModal}
                />}
              </Container>
             }
           </Query>
  }
}

export default MapPage;
