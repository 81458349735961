import React, { Component, Fragment } from "react";
import {
  Icon,
  Segment,
  Header,
  Divider,
  Loader,
  Label
} from "semantic-ui-react";
import CalendarMaintenance from "../../alarms/calendarMaintenance";
import moment from "moment";

class CalendarsList extends Component {
  render() {
    const { currentUser } = this.props;
    return this.props.data && this.props.data.length ? (
      <Fragment>
        <Divider horizontal>
          <Header as="h5">
            <Icon name="calendar alternate outline" />
            Calendar devices
          </Header>
        </Divider>
        {this.props.data.map((calendar, i) => (
          <Segment
            key={calendar.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline"
            }}
          >
            <Header
              as="h5"
              style={{
                display: "inline-flex",
                width: "70%",
                marginBottom: "0",
                color: calendar.id === "TEMP_ID" ? "lightgrey" : ""
              }}
            >
              <div style={{ width: "40%" }}>{calendar.name}</div>
              <Label style={{ width: "25%" }}>
                warning:
                <Label.Detail>
                  {moment(calendar.maintenanceWarningDateTime).format(
                    "YYYY/MM/DD"
                  )}
                </Label.Detail>
              </Label>
              <Label style={{ width: "25%" }}>
                maintenance:
                <Label.Detail>
                  {moment(calendar.maintenanceDateTime).format("YYYY/MM/DD")}
                </Label.Detail>
              </Label>
            </Header>
            <Loader active={calendar.id === "TEMP_ID"} />
            <CalendarMaintenance
              currentUser={currentUser}
              item={calendar}
              alignRight
            />
          </Segment>
        ))}
      </Fragment>
    ) : null;
  }
}

export default CalendarsList;
