import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import { GoogleMap } from '@react-google-maps/api'
import MarkerWithLabel from './map/markerWithLabel';
import cfg from '../config';

class MapWithMarker extends Component {
  render() {
    const markerPosition = { lat: this.props.lat, lng: this.props.lon };
  
    return (
      <GoogleMap
        zoom={8}
        mapContainerStyle={{
            height: '500px',
        }}
        options={{ streetViewControl: false }}
        center={markerPosition}
      >
        <MarkerWithLabel
          position={markerPosition}
        >
          <div style={{ backgroundColor: "white", fontSize: "12px", padding: "6px", width: '100px', textAlign: 'center' }}>
              {`lat: ${this.props.lat}, lon: ${this.props.lon}`}
          </div>
        </MarkerWithLabel>
      </GoogleMap>
    )
  }
};

export default class Map extends Component {
  render() {
    return (
    this.props.lat === null || this.props.lon === null
    ? <Message info>no location data</Message>
    : <MapWithMarker
        googleMapURL={cfg.GOOGLE_MAPS_URL}
        lat={this.props.lat || 0}
        lon={this.props.lon || 0}
      />
    );
  }
}
