export default {
  apiServerUrl:
    window.location.origin === "http://localhost:3000"
      ? "http://localhost:5001"
      : window.location.origin,
  wsServerUrl:
    (process.env.NODE_ENV === "production" ? "wss://" : "ws://") +
    (window.location.origin === "http://localhost:3000"
      ? "localhost:5001"
      : window.location.host),
  roles: ["viewer", "editor", "admin", "superadmin"],
  GOOGLE_MAPS_URL:
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyBWc7LVIUPMGw0FG8VR493yVYX9B2BLsBg&v=3.exp&libraries=geometry,drawing,places",
  GOOGLE_MAPS_API_KEY: "AIzaSyBWc7LVIUPMGw0FG8VR493yVYX9B2BLsBg",
  DEFAULT_CENTER_LAT: 52.255,
  DEFAULT_CENTER_LON: 5.8397,
  DEFAULT_ZOOM: 8,
  SENTRY_DSN: "https://9acee4c2232f4c15951fb5263087114b@sentry.io/1770108",
  TINY_MCE_EDITOR_APIKEY: "eylfqf9039xcsnb1ec9yhts2x0gvi26vr7hjxmgz7ueerztr",
  DEFAULT_CHART_WINDOW: 14,
  defaultSettings: {
    defaultWarningCycle: 3000,
    defaultWarningCycleBefore: 30,
    defaultMaintenanceCycle: 2900,
    defaultCalendarWarning: 11,
    defaultCalendarMaintenance: 12,
    defaultCalendarWarningCycle: 11,
    defaultCalendarMaintenanceCycle: 12
  }
};
