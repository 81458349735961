import React, { Component } from "react";

import { Modal, Image, Icon } from 'semantic-ui-react'

const sizes = ['mini', 'tiny', 'small', 'large', 'fullscreen'];

class ShowImage extends Component {
    state = {
        size: 3
    }

    increase = () => {
      const currentSize = this.state.size;
      if(currentSize < sizes.length - 1) {
        this.setState({ size: currentSize + 1 })
      }
    }

    decrease = () => {
      const currentSize = this.state.size;
      if(currentSize > 0) {
        this.setState({ size: currentSize - 1 })
      }
    }

    render() {
      const { open, onClose, url } = this.props;
      return (
          <Modal
            open={open}
            onClose={onClose}
            closeOnDocumentClick
            size={sizes[this.state.size]}
          >
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Image fluid src={url} />
              <div style={{display: 'flex', flexDirection: 'column', marginLeft: '8px', height: '64px', justifyContent: 'space-between'}}>
                <Icon
                  name='zoom-in'
                  inverted
                  link
                  color='orange'
                  size='large'
                  disabled={this.state.size === sizes.length - 1}
                  onClick={this.increase}
                />
                <Icon
                  name='zoom-out'
                  link
                  color='orange'
                  inverted
                  size='large'
                  disabled={this.state.size === 0}
                  onClick={this.decrease}
                />
              </div>
            </div>
          </Modal>
        );
    }
}

export default ShowImage;
