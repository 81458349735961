import React, { Component, Fragment }  from "react";
import { Form, Button } from 'semantic-ui-react';
import moment from 'moment';

class AddTime extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selected: props.options[0].value,
        input: props.defaultInput || 0
      }
    }

    handleChange = (e, { value }) => {
      this.setState({ selected: value });
    }

    add = () => {
      if(this.props.calendar) {
        const newValue = +moment(this.props.baseValue).add(this.state.input, this.state.selected);
        this.props.setValue(newValue);
      } else {
        const newValue = this.state.input > 0 ? this.state.input * this.state.selected + this.props.baseValue : this.props.baseValue;
        this.props.setValue(newValue);
      }
    }

    render() {
      return (
      <Fragment>
        <Form.Input
          width='3'
          fluid label='Value to add:'
          value={this.state.input}
          disabled={this.props.disabled}
          onChange={(e) => this.setState({ input: e.target.value })}
        />
        <Form.Select
          fluid
          width='3'
          label='period:'
          disabled={this.props.disabled}
          value={this.state.selected}
          options={this.props.options}
          onChange={this.handleChange}
        />
        <Button
          width='2'
          type='button'
          disabled={this.props.disabled}
          onClick={this.add}
        >
          Add
        </Button>
      </Fragment>);
    }
}

export default AddTime;
