import httpService from '../services/httpService';

const backup = () => {
  const token = localStorage.getItem('token');
  const options = {
    url: '/data/backup',
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`
    },
    responseType: 'blob'
  }

  return httpService(options);
};

export default {
  backup,
}
