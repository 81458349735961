import React from 'react';
import { Mutation } from 'react-apollo';
import { Confirm } from 'semantic-ui-react';
import CompressorModal from '../../forms/compressor';
import { DELETE_COMPRESSOR, ADD_COMPRESSOR, UPDATE_COMPRESSOR } from '../../../graphql/mutations';
import { updateQuery } from '../../../utils';

const newCompressorData = {
  name: '',
  gatewayId: '',
  params: [],
  maintenanceInterval: 0,
  enabled: true
};

export const DeleteCompressor = ({
  compressor,
  customer,
  deleteConfirm,
  closeDeleteCompressor,
  refetch
}) =>
  <Mutation
    mutation={DELETE_COMPRESSOR}
    refetchQueries={[{ query: refetch, variables: { id: customer.id } }]}
    update={(cache, { data }) => { // use optimisticResponse data for cache updating
        const cachedData = cache.readQuery({ query: refetch,  variables: { id: customer.id } });
        cache.writeQuery({
          query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
              compressors: cachedData.customerChildren.compressors.map(compressor => compressor.id === data.response ? { ...compressor, id: 'TEMP_ID' } : compressor)
            }
          }
        });
    }}
  >
    {(deleteCompressor, { loading, error } ) =>
      <Confirm
        open={deleteConfirm}
        content={`Delete compressor ${compressor.name} (${compressor.id}). Are you sure?`}
        onCancel={closeDeleteCompressor}
        onConfirm={async () => {
          try {
            closeDeleteCompressor();
            await deleteCompressor({ variables: { id:  compressor.id, customerId: compressor.parentId }, optimisticResponse: { response: compressor.id } });
          } catch (e) {
            console.log(e.message);
          }
        }}
      />
    }
  </Mutation>;

export const MutateCompressor = ({
  compressor,
  customer,
  customers,
  gateways,
  edit,
  compressorOpen,
  closeCompressorModal,
  displayModal,
  role,
  refetch,
}) =>
  <Mutation
    mutation={edit ? UPDATE_COMPRESSOR : ADD_COMPRESSOR}
    refetchQueries={edit ? [] : [{ query: refetch, variables: { id: customer.id } }]}
    update={(cache, { data }) => { // use optimisticResponse data for cache updating
      if(!edit) {
        const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
        cache.writeQuery({ query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
              compressors: [...cachedData.customerChildren.compressors, data.response]
            }
          }
        });
      } else if(data.__typename !== "Mutation" && customer.id !== data.response.parentId) {
        const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
        cache.writeQuery({ query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
              compressors: cachedData.customerChildren.compressors.filter(gateway => compressor.id !== data.response.id)
            }
          }
        });
      }
    }}
  >
  {(mutateCompressor, { loading, error } ) =>
    <CompressorModal
      open={compressorOpen}
      onClose={closeCompressorModal}
      customer={customer}
      customers={customers}
      gateways={gateways}
      compressor={edit ? compressor : { ...newCompressorData, parentId: customer.id }}
      edit={edit}
      mutateDevice={mutateCompressor}
      displayModal={displayModal}
      loading={loading}
      role={role}
      error={error}
    />}
  </Mutation>;

  export const TableDeleteCompressor = ({
      compressor,
      customer,
      deleteConfirm,
      closeDeleteCompressor,
      fetchMore,
      variables,
    }) =>
      <Mutation
        mutation={DELETE_COMPRESSOR}
        update={(cache, { data }) => {
          if(data.___typename !== "Mutation") {
            fetchMore({variables, updateQuery});
          }
        }}
      >
        {(deleteCompressor, { loading, error } ) =>
          <Confirm
            open={deleteConfirm}
            content={`Delete compressor ${compressor.name} (${compressor.id}). Are you sure?`}
            onCancel={closeDeleteCompressor}
            onConfirm={async () => {
              try {
                closeDeleteCompressor();
                await deleteCompressor({ variables: { id:  compressor.id, customerId: compressor.parentId }, optimisticResponse: { response: compressor.id, ___typename: "Mutation" } });
              } catch (e) {
                console.log(e.message);
              }
            }}
          />
        }
      </Mutation>;

export const TableMutateCompressor = ({
        compressor,
        customer,
        customers,
        gateways,
        edit,
        compressorOpen,
        closeCompressorModal,
        displayModal,
        fetchMore,
        role,
        variables,
      }) =>
        <Mutation
          mutation={edit ? UPDATE_COMPRESSOR : ADD_COMPRESSOR}
          update={(cache, { data }) => {
            if(data.__typename !== "Mutation") {
              if(!edit) {
                fetchMore({variables, updateQuery});
              } else if(customer.id !== data.response.parentId) {
                fetchMore({variables, updateQuery});
              }              
            }
          }}
        >
        {(mutateCompressor, { loading, error } ) =>
          <CompressorModal
            open={compressorOpen}
            onClose={closeCompressorModal}
            customer={customer}
            customers={customers}
            gateways={gateways}
            compressor={edit ? compressor : { ...newCompressorData, parentId: customer.id }}
            edit={edit}
            mutateDevice={mutateCompressor}
            displayModal={displayModal}
            loading={loading}
            role={role}
            error={error}
          />}
        </Mutation>;
