import React, { Component, Fragment } from 'react';
import {Dropdown, Icon, Label} from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { UPDATE_REMAINING_HOURS_ALARM } from '../../graphql/mutations';
import {forecastDate} from "../../utils";
import moment from "moment/moment";

const RED = '#db2828';
const YELLOW = '#fbbd08';

class RemainingHoursMaintenance extends Component {
    composeLabel = item => {
        const leftPart = `[${item.currentValue}]`;

        const isWarning = item.alarms.warning.length;

        if (item.currentValue <= 0) {
            return item.hasOwnProperty('currentValue') ? leftPart : 'parent status';
        }

        if (!item.lastWeekHours) {
            return item.hasOwnProperty('currentValue')
                ? `${leftPart} no data`
                : 'parent status';
        }

        const calculatedForecastDate = forecastDate(item.currentValue, -item.lastWeekHours / 7);

        const rightPart = isWarning ? `${calculatedForecastDate.diff(moment(), 'days')} days left` : calculatedForecastDate.format('YYYY/MM/DD');

        return item.hasOwnProperty('currentValue')
            ? `${leftPart} ${rightPart}`
            : 'parent status';
    }

  render() {
    const { item, alignRight, currentUser } = this.props;
    return (
    item.alarms.warning.length || item.alarms.maintenance.length
    ?(<Mutation
        mutation={UPDATE_REMAINING_HOURS_ALARM}
      >
      {(updateAlarm, { loading, error } ) => {
        return <Fragment>
          <Dropdown
            fluid
            loading={loading}
            disabled={currentUser.role === 1}
            trigger={<span>{this.composeLabel(item)}</span>}
            style={{
              maxWidth: "150px",
              textAlign: 'center',
              float: alignRight ? 'right': '',
              marginRight: alignRight ? '1rem': '',
              padding: '0.29rem',
              paddingRight: '0.58rem',
              borderRadius: '0.29rem',
              color: 'white',
              fontSize: '0.86rem',
              background: item.alarms.maintenance.length
                ? (item.alarms.maintenance[0].status === 'ACKNOWLEDGED' ? 'grey' : RED)
                : (item.alarms.warning[0].status === 'ACKNOWLEDGED' ? 'grey' : YELLOW)
            }}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text='ACKNOWLEDGE'
                disabled={((item.alarms.maintenance.length > 0 && item.alarms.maintenance[0].status === 'ACKNOWLEDGED')
                    || (item.alarms.warning.length > 0 && item.alarms.warning[0].status === 'ACKNOWLEDGED'))}
                onClick={async () => {
                  try {
                    await updateAlarm({ variables: {
                      id: item.alarms.maintenance.length > 0 ? item.alarms.maintenance[0].id : item.alarms.warning[0].id,
                      status: "ACKNOWLEDGED",
                      remainingHoursId: item.id,
                      customerId: item.parentId
                    }
                  });
                  } catch (e) {
                    console.log(e.message);
                  }
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Fragment>}
      }
      </Mutation>
    ):(<Label
          color='green'
          style={alignRight
            ? {float: 'right', marginRight: '1rem', width: '100%', maxWidth: "150px", textAlign: 'center'}
            : {width: '100%', maxWidth: "150px", textAlign: 'center'}}
        >
            {`[${item.currentValue}] ${item.lastWeekHours ? forecastDate(item.currentValue, -item.lastWeekHours / 7).format('YYYY/MM/DD') : 'no data'}`}
        </Label>)
    );
  }
}

export default RemainingHoursMaintenance;
