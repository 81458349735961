export default {
    isLoggedIn: false,
    user: {
      id: "",
      name: "",
      email: "",
      role: 0,
      __typename: "User"
    }
};
