import {Form, Tab} from "semantic-ui-react";
import FormField from "../../formField";
import UploadFile from "../../../mutations/customer/uploadFile";
import Editor from "../../fields/Editor";
import React from "react";

const General = ({
                     values,
                     setFieldValue,
                     errors,
                     touched,
                 }) => {
    return (
        <Tab.Pane
            style={{
                minHeight: '728px'
            }}
        >
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{width: '30%'}}>
                    <FormField
                        className="required"
                        name="name"
                        label="Name"
                        placeholder="Name"
                        errors={errors}
                        touched={touched}
                        Component={Form.Input}
                    />
                </div>
                <div style={{width: '30%'}}>
                    <FormField
                        name="number"
                        label="Number"
                        placeholder="Number"
                        errors={errors}
                        touched={touched}
                        Component={Form.Input}
                    />
                </div>
                <div style={{width: '30%'}}>
                    <FormField
                        name="contractNumber"
                        label="Contract Number"
                        placeholder="Contract Number"
                        errors={errors}
                        touched={touched}
                        Component={Form.Input}
                    />
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                <UploadFile
                    fieldName='image1'
                    fieldValue={values.image1}
                    setFieldValue={setFieldValue}
                />
                <UploadFile
                    fieldName='image2'
                    fieldValue={values.image2}
                    setFieldValue={setFieldValue}
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <UploadFile
                    fieldName='image3'
                    fieldValue={values.image3}
                    setFieldValue={setFieldValue}
                />
                <UploadFile
                    fieldName='image4'
                    fieldValue={values.image4}
                    setFieldValue={setFieldValue}
                />
            </div>
            <FormField
                name="emailAlarmSender"
                label="Email Alarm Sender"
                placeholder="Email Alarm Sender"
                errors={errors}
                touched={touched}
                Component={Form.Input}
            />
            <FormField
                fieldValue={values.notes}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                Component={Editor}
            />
            <br/>
            <div style={{
                display: 'flex',
                width: '50%',
                justifyContent: 'space-between'
            }}>
                <FormField
                    name="enabled"
                    label="Customer enabled"
                    errors={errors}
                    touched={touched}
                    Component={Form.Checkbox}
                />
                <FormField
                    name="showOnDashboard"
                    label="Show on Dashboard"
                    errors={errors}
                    touched={touched}
                    Component={Form.Checkbox}
                />
            </div>
        </Tab.Pane>
    );
}

export default General;
