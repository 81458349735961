import React, { Component, Fragment }  from "react";
import { Formik, Field, Form as FormikForm } from 'formik';
import {object as yupObject, string as yupString, boolean as yupBoolean } from 'yup';
import { Button, Form, Icon, Container, Modal, Message } from 'semantic-ui-react'

import FormField from './formField';
import { getSorting } from '../../utils';

const RemainingHoursFormVS = yupObject().shape({
  edit: yupBoolean(),
  name: yupString()
    .required('name is required'),
  parentId: yupString()
    .required(),
  gateway: yupString().required('gateway is required'),
  params: yupString().required('parameter is required'),
  enabled: yupBoolean().required(),
});

const RemainingHoursModal = ({
  remainingHours,
  customer,
  edit,
  ...props
}) =>
  <Formik
  	initialValues={{
      ...remainingHours,
      edit,
      params: remainingHours.params[0] || ''
    }}
  	validationSchema={RemainingHoursFormVS}
    enableReinitialize
    isInitialValid={props => {
        RemainingHoursFormVS.isValid(props.initialValues)
      .then(valid => valid)
    }}
  	onSubmit={async (values, {setStatus, setSubmitting}) => {
        const {
          name,
          parentId,
          gateway,
          params,
          enabled
        } = values;
    		const data = {
          name,
          parentId,
          gateway,
          params: [params],
          enabled,
        };
        try {
            const mutateArgs = {
                variables: { remainingHours: data, ...(edit && { id: remainingHours.id }) },
                optimisticResponse: {
                    __typename: "Mutation",
                    response: edit
                        ? {
                            ...data,
                            id: remainingHours.id,
                            __typename: "RemainingHours",
                            createdAt: remainingHours.createdAt,
                            alarms: remainingHours.alarms,
                        }:{
                            ...data,
                            __typename: "RemainingHours",
                            id: 'TEMP_ID',
                            createdAt: 0,
                            alarms: {
                                malfunction: [],
                                warning: [],
                                maintenance: [],
                                __typename: "Alarms"
                            },
                        }
                }
            };
          props.mutateDevice(mutateArgs);
          props.onClose();
        } catch (e) {
          console.log(e.message);
        }
    	}
    }
    render={renderProps => <RemainingHours
      customer={customer}
      {...props}
      {...renderProps}
    />}
  />

class RemainingHours extends Component {
  render() {
    const {
      errors,
      touched,
      values,
      open,
      onClose,
      loading,
      error,
      customer,
      isValid,
      customers,
    } = this.props;
    const selectedGateway = this.props.gateways.find(gateway => gateway.id === values.gateway);

    return (
      <Modal
        open={open}
        onClose={onClose}
        size='tiny'
        closeOnDocumentClick
      >
        <Modal.Header>{values.edit ? `Edit Remaining hours device (customer: ${customer.name})` : `Add Remaining hours device (customer: ${customer.name})`}</Modal.Header>
        <Modal.Content>
          <FormikForm className="ui form error">
              <FormField
                  className="required"
                  name="name"
                  label="Name"
                  placeholder="Name"
                  errors={errors}
                  touched={touched}
                  Component={Form.Input}
              />
              {values.edit &&
                  <Field
                      name="parentId"
                      render={({field, form}) => (
                          <Fragment>
                              <Form.Select
                                  className="required"
                                  name={field.name}
                                  label='Customer'
                                  placeholder="Select customer"
                                  value={field.value}
                                  options={customers
                                      .sort(getSorting('ascending', 'name'))
                                      .map(customer => ({
                                          value: customer.id,
                                          text: customer.name
                                      }))}
                                  error={errors[field.name] && touched[field.name]}
                                  onChange={(e, selected) => {
                                      form.setFieldValue(field.name, selected.value);
                                  }}
                              />
                              {errors[field.name] && touched[field.name] &&
                                  <Message
                                      error
                                      header='error'
                                      content={errors[field.name]}
                                  />
                              }
                          </Fragment>
                      )}
                  />}
              <Field
                  name="gateway"
                  render={({field, form}) => (
                      <Fragment>
                          <Form.Select
                              className="required"
                              name={field.name}
                              label="Gateway"
                              placeholder="Select Gateway"
                              value={field.value}
                              options={this.props.gateways
                                  .sort(getSorting('ascending', 'name'))
                                  .map(gateway => ({
                                      value: gateway.id,
                                      text: gateway.name
                                  }))}
                              error={errors[field.name] && touched[field.name]}
                              onChange={(e, selected) => {
                                  form.setFieldValue(field.name, selected.value);
                              }}
                          />
                          {errors[field.name] && touched[field.name] &&
                              <Message
                                  error
                                  header='error'
                                  content={errors[field.name]}
                              />
                          }
                      </Fragment>
                  )}
              />
              <Field
                  name={"params"}
                  render={({field, form}) => (
                      <Form.Select
                          className="required"
                          name={field.name}
                          label="parameter"
                          placeholder="Select parameter"
                          disabled={!selectedGateway || !selectedGateway.lastMessage.length}
                          value={field.value}
                          onChange={(e, selected) => {
                              form.setFieldValue(field.name, selected.value);
                          }}
                          options={selectedGateway
                              ? selectedGateway.lastMessage
                                  .filter(entry => entry.key !== 'timestamp')
                                  .filter(entry => !/_maintenanceInterval/.test(entry.key))
                                  .filter(entry => /_remaingTime/.test(entry.key))
                                  .map((entry, i) => ({
                                      value: entry.key,
                                      text: `${entry.key}: ${entry.value}`
                                  }))
                              : []
                          }
                      />)}
              />
              {error &&
                  <Message
                      error
                      header='Server error'
                      content={error.message}
                  />}
            <Container textAlign='right' style={{ marginTop: '1rem' }}>
              <Button
                primary
                type='submit'
                icon='checkmark'
                content='Save'
                role='button'
                disabled={loading || !isValid}
                loading={loading}
              />
              <Button secondary basic onClick={onClose}>
                <Icon name='remove' /> Cancel
              </Button>
            </Container>
          </FormikForm>
        </Modal.Content>
      </Modal>
    );
  }
}

export default RemainingHoursModal;
