import React, { Fragment, useState } from 'react';
import { Marker, InfoBox } from '@react-google-maps/api'

const MarkerWithLabel = ({ position, children, markerOnClick, labelVisible = true }) => {
  const pos = new window.google.maps.LatLng(position);
  const [offsetX, setOffsetX] = useState(0);
  const [infobox, setInfoBox] = useState(null);
  
  const handleClick = e => {
    markerOnClick && markerOnClick(e);
  }
  
  return (
    <Fragment>
      <Marker
        position={pos}
        onClick={handleClick}
      />
      <InfoBox
        onLoad={infobox => {
          setInfoBox(infobox);
        }}
        onDomReady={() => {
          setOffsetX(-infobox.div.clientWidth / 2);
        }}
        options={{
          closeBoxURL: '',
          enableEventPropagation: true,
          position: pos,
          pixelOffset: new window.google.maps.Size(offsetX, 10),
          visible: labelVisible,
          disableAutoPan: true,
        }}
      >
        {children}
      </InfoBox>
    </Fragment>              
  )
}

export default MarkerWithLabel;
