import React, { Component, Fragment } from "react";
import {
  Icon,
  Segment,
  Container,
  Header,
  Divider,
  Label,
  Loader
} from "semantic-ui-react";
import Malfunction from "../../alarms/malfunction";

class GatewaysList extends Component {
  render() {
    const { currentUser } = this.props;
    return this.props.data && this.props.data.length ? (
      <Fragment>
        <Divider horizontal>
          <Header as="h5">
            <Icon name="server" />
            Gateways
          </Header>
        </Divider>
        {this.props.data.map((gateway, i) => (
          <Segment
            key={i}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline"
            }}
          >
            <Container>
              <Header
                as="h5"
                style={{
                  display: "inline-flex",
                  width: "70%",
                  marginBottom: "0",
                  color: gateway.id === "TEMP_ID" ? "lightgrey" : ""
                }}
              >
                <div style={{ width: "20%" }}>{gateway.name}</div>
                <Label style={{ width: "50%" }}>
                  url:
                  <Label.Detail>{gateway.URL_IP_Host}</Label.Detail>
                </Label>
                <Label style={{ width: "20%" }}>
                  protocol:
                  <Label.Detail>{gateway.TypePullProtocol}</Label.Detail>
                </Label>
              </Header>
              <Loader active={gateway.id === "TEMP_ID"} />
              <Malfunction
                currentUser={currentUser}
                item={gateway}
                refetch={this.props.refetch}
                rootName={this.props.rootName}
                alignRight
              />
            </Container>
          </Segment>
        ))}
      </Fragment>
    ) : null;
  }
}

export default GatewaysList;
