import React from 'react';
import { Dropdown, Menu, Icon } from 'semantic-ui-react';

const UserAvatar = ({
  user,
  signout,
  openProfile,
  close,
  role,
}) => (
  <Menu.Menu position='right'>
    <span className='item'>{role}</span>
    <Dropdown text={user.name} item>
        <Dropdown.Menu>
          <Dropdown.Header style={{textTransform: "none"}}>
            <Icon name='mail' />
            {user.email}
          </Dropdown.Header>
          <Dropdown.Divider />
          <Dropdown.Item icon='user' text='Profile' onClick={() => {
            openProfile();
            close && close();
          }} />
          <Dropdown.Item icon='sign-out' text='Signout' onClick={signout} />
        </Dropdown.Menu>
    </Dropdown>
  </Menu.Menu>
)

export default UserAvatar
