import gql from 'graphql-tag';

export const DATA_UPDATE_SUB = gql`
  subscription onDataUpdated($customerId: ID) {
    dataUpdated(customerId: $customerId) {
      source
      updateReason
    }
  }
`;
