import gql from 'graphql-tag';
import {
    USER_FIELDS,
    CUSTOMER_FIELDS,
    GATEWAY_FIELDS,
    CALENDAR_FIELDS,
    CHILD_FIELDS,
    COMPRESSOR_FIELDS,
    MAINTENANCE_FIELDS,
    REMAINING_HOURS_FIELDS,
} from './fragments';

export const SIGNIN = gql`
  mutation Signin($email: String!, $password: String!) {
    response: signin(email: $email, password: $password) {
      expiration,
      token,
      user {
        id,
        name,
        role,
        email
      }
    }
  }
`;

export const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation SendResetPasswordEmail($email: String!) {
    response: sendResetPasswordEmail(email: $email)
  }
`;

export const SET_NEW_PASSWORD = gql`
  mutation SetNewPassword($email: String!, $token: String!, $password: String!) {
    response: setNewPassword(email: $email, token: $token, password: $password)
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($email: String!, $token: String!) {
    response: verifyEmail(email: $email, token: $token)
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    response: deleteUser(id: $id)
  }
`;

export const ADD_USER = gql`
  mutation AddUser($user: InputNewUser!) {
    response: addUser(user: $user) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $user: InputUpdateUser!) {
    response: updateUser(id: $id, user: $user) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($name: String!, $email: String!, $password: String) {
    response: updateProfile(name: $name, email: $email, password: $password) {
      name
      email
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($id: ID!, $withGateways: Boolean = true) {
    response: deleteCustomer(id: $id, withGateways: $withGateways)
  }
`;

export const ADD_CUSTOMER = gql`
  mutation AddCustomer($customer: InputCustomer!) {
    response: addCustomer(customer: $customer) {
      ...CustomerFields
    }
  }
  ${CUSTOMER_FIELDS}
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($id: ID!, $customer: InputCustomer!) {
    response: updateCustomer(id: $id, customer: $customer) {
      ...CustomerFields
    }
  }
  ${CUSTOMER_FIELDS}
`;

export const ADD_GATEWAY = gql`
  mutation AddGateway($gateway: InputGateway!) {
    response: addGateway(gateway: $gateway) {
      ...GatewayFields
    }
  }
  ${GATEWAY_FIELDS}
`;

export const UPDATE_GATEWAY = gql`
  mutation UpdateGateway($id: ID!, $gateway: InputGateway!) {
    response: updateGateway(id: $id, gateway: $gateway) {
      ...GatewayFields
    }
  }
  ${GATEWAY_FIELDS}
`;

export const DELETE_GATEWAY = gql`
  mutation DeleteGateway($id: ID!, $customerId: ID!) {
    response: deleteGateway(id: $id, customerId: $customerId)
  }
`;

export const ADD_COMPRESSOR = gql`
  mutation AddCompressor($compressor: InputCompressor!) {
    response: addCompressor(compressor: $compressor) {
      ...CompressorFields
    }
  }
  ${COMPRESSOR_FIELDS}
`;

export const UPDATE_COMPRESSOR = gql`
  mutation UpdateCompressor($id: ID!, $compressor: InputCompressor!) {
    response: updateCompressor(id: $id, compressor: $compressor) {
      ...CompressorFields
    }
  }
  ${COMPRESSOR_FIELDS}
`;

export const ADD_MAINTENANCE = gql`
  mutation AddMaintenance($maintenance: InputMaintenance!) {
    response: addMaintenance(maintenance: $maintenance) {
      ...MaintenanceFields
    }
  }
  ${MAINTENANCE_FIELDS}
`;

export const UPDATE_MAINTENANCE = gql`
  mutation UpdateMaintenance($id: ID!, $maintenance: InputMaintenance!) {
    response: updateMaintenance(id: $id, maintenance: $maintenance) {
      ...MaintenanceFields
    }
  }
  ${MAINTENANCE_FIELDS}
`;

export const ADD_REMAINING_HOURS = gql`
  mutation AddRemainingHours($remainingHours: InputRemainingHours!) {
    response: addRemainingHours(remainingHours: $remainingHours) {
      ...RemainingHoursFields
    }
  }
  ${REMAINING_HOURS_FIELDS}
`;

export const UPDATE_REMAINING_HOURS = gql`
  mutation UpdateRemainingHours($id: ID!, $remainingHours: InputRemainingHours!) {
    response: updateRemainingHours(id: $id, remainingHours: $remainingHours) {
      ...RemainingHoursFields
    }
  }
  ${REMAINING_HOURS_FIELDS}
`;

export const ADD_CHILD = gql`
  mutation AddChild($child: InputChild!) {
    response: addChild(child: $child) {
      ...ChildFields
    }
  }
  ${CHILD_FIELDS}
`;

export const UPDATE_CHILD = gql`
  mutation UpdateChild($id: ID!, $child: InputChild!) {
    response: updateChild(id: $id, child: $child) {
      ...ChildFields
    }
  }
  ${CHILD_FIELDS}
`;

export const ADD_CALENDAR = gql`
  mutation AddCalendar($calendar: InputCalendar!) {
    response: addCalendar(calendar: $calendar) {
      ...CalendarFields
    }
  }
  ${CALENDAR_FIELDS}
`;

export const UPDATE_CALENDAR = gql`
  mutation UpdateCalendar($id: ID!, $calendar: InputCalendar!) {
    response: updateCalendar(id: $id, calendar: $calendar) {
      ...CalendarFields
    }
  }
  ${CALENDAR_FIELDS}
`;

export const DELETE_COMPRESSOR = gql`
  mutation DeleteCompressor($id: ID!, $customerId: ID!) {
    response: deleteCompressor(id: $id, customerId: $customerId)
  }
`;

export const DELETE_MAINTENANCE = gql`
  mutation DeleteMaintenance($id: ID!, $customerId: ID!) {
    response: deleteMaintenance(id: $id, customerId: $customerId)
  }
`;

export const DELETE_REMAINING_HOURS = gql`
  mutation DeleteRemainingHours($id: ID!, $customerId: ID!) {
    response: deleteRemainingHours(id: $id, customerId: $customerId)
  }
`;

export const DELETE_CHILD = gql`
  mutation DeleteChild($id: ID!) {
    response: deleteChild(id: $id)
  }
`;

export const DELETE_CALENDAR = gql`
  mutation DeleteCalendar($id: ID!, $customerId: ID!) {
    response: deleteCalendar(id: $id, customerId: $customerId)
  }
`;

export const UPDATE_EMAIL_ALARM = gql`
  mutation UpdateEmailAlarm($id: ID!, $status: String!, $customerId: ID!) {
    response: updateEmailAlarm(id: $id, status: $status, customerId: $customerId) {
      ...CustomerFields
      emailAlarms {
        id
        status
        text
        timestamp
      }
      gateways {
        ...GatewayFields
      }
      compressors {
        ...CompressorFields
      }
      children {
        ...ChildFields
      }
      calendars {
        ...CalendarFields
      }
    }
  }
  ${GATEWAY_FIELDS}
  ${COMPRESSOR_FIELDS}
  ${CALENDAR_FIELDS}
  ${CHILD_FIELDS}
  ${CUSTOMER_FIELDS}
`;

export const UPDATE_EMAIL_ALARMS = gql`
  mutation UpdateEmailAlarms($ids: [ID!]!, $status: String!, $customerId: ID!) {
    response: updateEmailAlarms(ids: $ids, status: $status, customerId: $customerId) {
      ...CustomerFields
      emailAlarms {
        id
        status
        text
        timestamp
      }
      gateways {
        ...GatewayFields
      }
      compressors {
        ...CompressorFields
      }
      children {
        ...ChildFields
      }
      calendars {
        ...CalendarFields
      }
    }
  }
  ${GATEWAY_FIELDS}
  ${COMPRESSOR_FIELDS}
  ${CALENDAR_FIELDS}
  ${CHILD_FIELDS}
  ${CUSTOMER_FIELDS}
`;

export const UPDATE_GATEWAY_ALARM = gql`
  mutation UpdateGatewayAlarm($id: ID!, $status: String!, $gatewayId: ID!, $customerId: ID!) {
    response: updateGatewayAlarm(id: $id, status: $status, gatewayId: $gatewayId, customerId: $customerId) {
      ...GatewayFields
    }
  }
  ${GATEWAY_FIELDS}
`;

export const UPDATE_COMPRESSOR_ALARM = gql`
  mutation UpdateCompressorAlarm($id: ID!, $status: String!, $compressorId: ID!, $maintenanceInterval: Float, $customerId: ID!) {
    response: updateCompressorAlarm(id: $id, status: $status, compressorId: $compressorId, maintenanceInterval: $maintenanceInterval, customerId: $customerId) {
      ...CompressorFields
    }
  }
  ${COMPRESSOR_FIELDS}
`;

export const UPDATE_REMAINING_HOURS_ALARM = gql`
  mutation UpdateRemainingHoursAlarm($id: ID!, $status: String!, $remainingHoursId: ID!, $customerId: ID!) {
    response: updateRemainingHoursAlarm(id: $id, status: $status, remainingHoursId: $remainingHoursId, customerId: $customerId) {
      ...RemainingHoursFields
    }
  }
  ${REMAINING_HOURS_FIELDS}
`;

export const UPDATE_CALENDAR_ALARM = gql`
  mutation UpdateCalendarAlarm($id: ID!, $status: String!, $calendarId: ID!, $maintenanceDateTime: Float, $maintenanceWarningDateTime: Float, $customerId: ID!) {
    response: updateCalendarAlarm(id: $id, status: $status, calendarId: $calendarId, maintenanceDateTime: $maintenanceDateTime, maintenanceWarningDateTime: $maintenanceWarningDateTime, customerId: $customerId) {
      ...CalendarFields
    }
  }
  ${CALENDAR_FIELDS}
`;

export const UPDATE_GATEWAY_MESSAGE = gql`
  mutation UpdateGatewayMessage($id: ID!){
    updateGatewayMessage(id: $id) {
      ...GatewayFields,
      error
    }
  }
  ${GATEWAY_FIELDS}
`;

export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file)
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($fileName: String!) {
    deleteFile(fileName: $fileName)
  }
`;

export const DATA_RESTORE = gql`
  mutation DataRestore($file: Upload!) {
    dataRestore(file: $file)
  }
`;

export const SET_EMAIL_PROCESSING = gql`
  mutation SetEmailProcessing($payload: InputAlarmemailbox!) {
    setEmailProcessing(payload: $payload) {
          hoursFrom
          hoursTo
          enabled
    }
  }
`;
