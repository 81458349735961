import gql from 'graphql-tag';

export default gql`
  type Query {
    isLoggedIn: Boolean!
    user: User!
  }
  type User {
    id: ID!
    name: String!
    role: Int!
    email: String!
  }
  
  input Location {
    lat: Float!
    lon: Float!
  }
  input InputNewUser {
    name: String!
    email: String!
    emailVerified: Boolean!
    password: String!
    enabled: Boolean!
    role: Int!
  }
  input InputUpdateUser {
    name: String!
    email: String!
    emailVerified: Boolean!
    password: String
    enabled: Boolean!
    role: Int!
  }
  input InputGateway {
    parentId: String!
    name: String!
    TypePullProtocol: String!
    URL_IP_Host: String!
    login: String!
    password: String!
    location: Location
    enabled: Boolean!
  }
  input InputCompressor {
    parentId: String!
    name: String!
    gateway: String!
    params: [String!]!
    maintenanceInterval: Float!
    notification_email: String
    enableWarningEmail: Boolean
    enableMaintenanceEmail: Boolean
    enableRunningHoursEmail: Boolean
    runningHoursEmailValue: Int
    defaultWarningCycleBefore: Int
    defaultMaintenanceCycle: Int
    defaultCalendarWarning: Int
    defaultCalendarMaintenance: Int
    enabled: Boolean!
  }
  input Child {
    parentId: String!
    name: String!
    compressor: String!
    notes: String!
    enabled: Boolean!
  }
  input InputCalendar {
    parentId: String!
    name: String!
    maintenanceDateTime: Float!
    maintenanceWarningDateTime: Float!
    defaultCalendarWarningCycle: Int
    defaultCalendarMaintenanceCycle: Int
    enabled: Boolean!
  }
`;
