import React, { Component, Fragment } from 'react';
import { Table, Icon, Pagination, Dropdown, Input, Select, Label, Button, Popup } from 'semantic-ui-react'
import { withRouter } from 'react-router';
import Malfunction from '../components/alarms/malfunction';
import CompressorMaintenance from '../components/alarms/compressorMaintenance';
import CalendarMaintenance from '../components/alarms/calendarMaintenance';
import { getSorting, updateQuery } from '../utils';
import RemainingHoursMaintenance from "./alarms/remainingHoursMaintenance";

const getMaintenanceComponent = (item, currentUser, refetch) => {
  if (item.__typename === 'Calendar') {
    return (
        <CalendarMaintenance
            currentUser={currentUser}
            item={item}
            alignRight
        />
    );
  }
  if (item.__typename === 'RemainingHours') {
    return (
        <RemainingHoursMaintenance
            currentUser={currentUser}
            item={item}
            updateChildren
            refetch={refetch}
            alignRight
        />
    );
  }
  return (
      <CompressorMaintenance
          currentUser={currentUser}
          item={item}
          updateChildren
          refetch={refetch}
          alignRight
      />
  );
};

class DataTable extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(this.props.location.search);
    const customer = params.get("customer");

    this.state = {
      order: null,
      orderBy: null,
      filter: '',
      customerFilter: customer ? customer : 'all',
      typeFilter: 'all',
      isFiltering: false,
    };    
    
  }

  componentDidUpdate() {
    const newLastPage = Math.max(1, Math.ceil(this.props.total / this.props.limit));
    if(this.state.activePage > newLastPage) {
      this.setState({
        activePage: newLastPage
      });
    }
  }

  handlePageChange = (e, { activePage }) => {
    this.props.fetchMore({
        variables: {
          skip: (activePage - 1) * this.props.limit,
          limit: this.props.limit,
          filter: this.state.filter,
          customer: this.state.customerFilter,
          type: this.state.typeFilter,
        },
        updateQuery
    });
  }

  hanleRecordsNumberChange = (e, { value }) => {
    this.props.fetchMore({
        variables: {
          skip: 0,
          limit: value,
          filter: this.state.filter,
          customer: this.state.customerFilter,
          type: this.state.typeFilter,
        },
        updateQuery
    });
  }

  handleFilterChange = (e) => {
    if(this.state.filter !== e.target.value) {
      this.setState({
        [e.target.name]: e.target.value,
        // customerFilter: 'all',
        isFiltering: true
      }, () => this.props.fetchMore({
              variables: {
                skip: 0,
                limit: this.props.limit,
                filter: this.state.filter,
                customer: this.state.customerFilter,
                type: this.state.typeFilter,
              },
              updateQuery
          }).then(() => this.setState({ isFiltering: false }))
      );
    }
  }

  handleSelectChange = (_, { name, value }) => {
    if(value !== this.state[name]) {
      this.setState({
        [name]: value,
        // filter: '',
      }, () => this.props.fetchMore({
              variables: {
                skip: 0,
                limit: this.props.limit,
                filter: this.state.filter,
                customer: this.state.customerFilter,
                type: this.state.typeFilter,
              },
              updateQuery
          })
      );
    }
  }

  handleSort = clickedColumn => () => {
    if (this.state.orderBy !== clickedColumn) {
      this.setState({
        orderBy: clickedColumn,
        order: 'ascending',
      })
      return;
    }
    this.setState({
      order: this.state.order === 'ascending' ? 'descending' : 'ascending',
    })
  }

  render() {
    const { data, columns, actions, status, maintenance, total, filterData, skip, limit, refetch, currentUser, users, isRequestPending } = this.props;
    const { order, orderBy } = this.state;

    const pageCount = Math.ceil(total / limit);
    const emptyRows = limit - data.length > 0 ? limit - data.length : 0;
    return (<Fragment>
              <Input
                name='filter'
                size='small'
                icon='search'
                placeholder='Search...'
                loading={this.state.isFiltering}
                value={this.state.filter}
                onChange={this.handleFilterChange}
              />
              {this.props.filterData &&
                <Fragment>
                  <strong> Customer: </strong>
                  <Select
                    name='customerFilter'
                    label='Customer'
                    size='small'
                    placeholder="Select customer..."
                    value={this.state.customerFilter}
                    options={[{value: 'all', text: '-Show All-'}, ...filterData.map(data => ({
                      value: data.id,
                      text: data.name,
                    }))]}
                    onChange={this.handleSelectChange}
                  />
                </Fragment>
              }
              {this.props.types &&
                <Fragment>
                  <strong> Device type: </strong>
                  <Select
                    name='typeFilter'
                    label='Type'
                    size='small'
                    placeholder="Select type..."
                    value={this.state.typeFilter}
                    options={[
                        {value: 'all', text: '-Show All-'},
                        {value: 'Compressor', text: 'Running hours device'},
                        {value: 'Maintenance', text: 'Maintenance hours device'},
                        {value: 'RemainingHours', text: 'Remaining hours device'},
                        {value: 'Child', text: 'Child'},
                        {value: 'Calendar', text: 'Calendar'},
                      ]}
                    onChange={this.handleSelectChange}
                  />
                </Fragment>
              }
              {(currentUser.role === 3 || currentUser.role === 4)
              ? <Button icon disabled={isRequestPending} floated='right' labelPosition='right' primary size='small' onClick={() => this.props.openAddNew()}>
                  <Icon name={this.props.addNewIcon} /> {this.props.addNewText}
                </Button>
              : null}
              <Table sortable fixed celled stackable>
                <Table.Header>
                  <Table.Row textAlign='center'>
                    {columns.map((column, i) =>
                      <Table.HeaderCell
                        key={i}
                        sorted={column.sort && orderBy === column.field ? order : null}
                        onClick={column.sort ? this.handleSort(column.field) : null}
                        width={column.width}
                      >
                        {column.label}
                      </Table.HeaderCell>)
                    }
                    {status ? <Table.HeaderCell width={2}>status</Table.HeaderCell> : null}
                    {maintenance ? <Table.HeaderCell width={3}>maintenance</Table.HeaderCell> : null}
                    {actions.length ? <Table.HeaderCell width={2}>actions</Table.HeaderCell> : null}
                  </Table.Row>
                </Table.Header>
                <Table.Body style={this.props.isRequestPending ? { visibility: 'hidden' } : {}}>
                {data
                  .sort(getSorting(order, orderBy))
                  .map((item, i) =>
                   <Table.Row key={i}>
                    {columns.map((column, i) => column.bool
                      ? (<Table.Cell key={i} textAlign='center'>{item[column.field]
                            ? <Icon color='green' name='checkmark' size='small' />
                            : <Icon color='red' name='close' size='small' />}
                        </Table.Cell>)
                      : <Table.Cell key={i}>
                        {(column.label === 'type' && ['Compressor', 'RemainingHours'].includes(item[column.field]))
                          ? (item[column.field] === 'Compressor' ? 'Running hours device' : 'Remaining hours device')
                          : (column.label === 'url'
                            ? <a href={item[column.field]} target="_blank" rel="noopener noreferrer">{item[column.field]}</a>
                            : item[column.field])}
                        </Table.Cell>)}
                      {status
                      ? <Table.Cell textAlign='center' style={{overflow: 'visible'}}>
                        {item.__typename !== 'Calendar' &&
                        <Malfunction
                          currentUser={currentUser}
                          item={item}
                        />}
                        </Table.Cell>
                      : null}
                      {maintenance
                      ? <Table.Cell textAlign='center' style={{overflow: 'visible'}}>
                        {getMaintenanceComponent(item, currentUser, refetch)}
                        </Table.Cell>
                      : null}
                      <Table.Cell textAlign='center'>
                        {actions
                          .filter(action => action.access === 'all')
                          .filter(action => action.hasOwnProperty('devices')
                            ? action.devices.find(actionDevice => actionDevice === item.__typename)
                            : true)
                          .map((action, i) =>
                          <Popup
                            key={i}
                            trigger={
                              <Icon
                                link
                                color={action.color}
                                name={action.iconName}
                                onClick={() => action.onClick(item)}
                              />
                            }
                            content={action.tooltip}
                            size='mini'
                          />)}
                        {((currentUser.role === 3 || currentUser.role === 4) && currentUser.id !== item.id)
                          ? actions
                            .filter(action => action.access !== 'all')
                            .filter(action => action.hasOwnProperty('devices')
                              ? action.devices.find(actionDevice => actionDevice === item.__typename)
                              : true)
                            .map((action, i) =>
                            <Popup
                              key={i}
                              trigger={
                                <Icon
                                  link
                                  color={action.color}
                                  name={action.iconName}
                                  onClick={() => action.onClick(item)}
                                />
                              }
                              content={action.tooltip}
                              size='mini'
                            />)
                          : (users ? <Label color="green">you</Label> : null)}
                      </Table.Cell>
                    </Table.Row>)}
                    {Array.from(new Array(emptyRows)).map((_, i) =>
                      <Table.Row key={i}>
                        {columns.map((column, i) => <Table.Cell key={i}><br /></Table.Cell>)}
                        {status ? <Table.Cell><br /></Table.Cell> : null}
                        {maintenance ? <Table.Cell><br /></Table.Cell> : null}
                        {actions ? <Table.Cell><br /></Table.Cell> : null}
                      </Table.Row>
                    )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell
                      textAlign='center'
                      colSpan={columns.length + (actions ? 1 : 0) + (status ? 1 : 0) + (maintenance ? 1 : 0)}
                      style={{overflow: 'visible'}}
                    >
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <span>
                      Records on page:{' '}
                      <Dropdown
                        text={String(this.props.limit)}
                        compact
                        pointing='top'
                        options={[10, 25, 50, 100].map(value => ({ text: value, value }))}
                        onChange={this.hanleRecordsNumberChange}
                      />
                    </span>
                    {pageCount > 1 &&
                      <Pagination
                        activePage={skip / limit + 1}
                        totalPages={pageCount}
                        onPageChange={this.handlePageChange}
                      />}
                      </div>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Fragment>)
  }
}

export default withRouter(DataTable);
