import React, { Component } from 'react';
import { Card, Container, Loader } from 'semantic-ui-react';
import DataTable from '../components/dataTable';
import { Query, withApollo } from 'react-apollo';
import { GATEWAYS_PAGE } from '../graphql/queries';
import { TableDeleteGateway, TableMutateGateway } from '../components/mutations/gateway';
import SelectCustomerModal from '../components/forms/selectCustomer';

import ErrorMessage from '../components/errorMessage';

const initModals = {
  deleteConfirm: false,
  gatewayOpen: false,
  edit: false,
  gateway : null,
  customer: null,
  selectCustomer: false,
};

class Gateways extends Component {
  state = {
      isLoading: true,
      ...initModals
  };

  openAddGateway = () => {
    this.setState({ selectCustomer: true })
  }

  selectCustomer = (customer) => {
    this.setState({
      selectCustomer: false,
      gatewayOpen: true,
      customer
    })
  }

  openEditGateway = (gateway, customers) => {
    const customer = customers.find(customer => customer.id === gateway.parentId);
    this.setState({ gatewayOpen: true, edit: true, gateway: gateway.id, customer });
  }

  openDeleteGateway = (gateway, customers) => {
    const customer = customers.find(customer => customer.id === gateway.parentId);
    this.setState({
      deleteConfirm: true,
      gateway: gateway.id,
      customer,
    })
  }

  closeModal = () => {
    this.setState(initModals);
  }

  render() {
    const { currentUser } = this.props;
    return <Query query={GATEWAYS_PAGE} fetchPolicy="network-only" notifyOnNetworkStatusChange>
            {({ data, loading, client, error, fetchMore, networkStatus }) =>
              <Container>
                {error
                ? <ErrorMessage error={error} />
                : (<Card fluid>
                    <Card.Content style={{paddingBottom: '0.7rem', paddingTop: '0.7rem'}}>
                      <Card.Header>
                        Gateways
                      </Card.Header>
                    </Card.Content>
                    <Card.Content>
                      <Loader active={loading} />
                      <DataTable
                        networkStatus={networkStatus}
                        currentUser={currentUser}
                        isRequestPending={loading}
                        openAddNew={this.openAddGateway}
                        addNewIcon='server'
                        addNewText='Add New Gateway'
                        data={(data && data.gatewaysPage)
                          ? data.gatewaysPage.values.map(gateway =>
                              ({ ...gateway, customer: data.gatewaysPage.customers.find(customer => customer.id === gateway.parentId).name }))
                          : []}
                        filterData={(data && data.gatewaysPage) ? data.gatewaysPage.customers : []}
                        limit={(data && data.gatewaysPage) ? data.gatewaysPage.limit : 10}
                        skip={(data && data.gatewaysPage) ? data.gatewaysPage.skip : 0}
                        total={(data && data.gatewaysPage) ? data.gatewaysPage.total : 0}
                        columns={[
                          { label: 'name', field: 'name', sort: true, width: 2 },
                          { label: 'protocol', field: 'TypePullProtocol', sort: true, width: 2 },
                          { label: 'url', field: 'URL_IP_Host', sort: true },
                          { label: 'customer', field: 'customer', sort: true, width: 2 },
                          { label: 'enabled', field: 'enabled', bool: true, width: 1 },
                        ]}
                        status
                        refetch={GATEWAYS_PAGE}
                        rootName='gatewaysPage'
                        actions={[
                          { color: 'blue', iconName: 'edit outline', onClick: (gateway) => this.openEditGateway(gateway, data.gatewaysPage.customers), tooltip: 'edit' },
                          { color: 'red', iconName: 'trash alternate outline', onClick: (gateway) => this.openDeleteGateway(gateway, data.gatewaysPage.customers), tooltip: 'delete' },
                        ]}
                        fetchMore={fetchMore}
                      />
                    </Card.Content>
                  </Card>)}
                  {this.state.gateway &&
                    <TableDeleteGateway
                      gateway={data.gatewaysPage.values.find(gateway => gateway.id === this.state.gateway)}
                      customer={this.state.customer}
                      deleteConfirm={this.state.deleteConfirm}
                      closeDeleteGateway={this.closeModal}
                      fetchMore={fetchMore}
                      variables={{
                        skip: data.gatewaysPage.skip,
                        limit: data.gatewaysPage.limit,
                        filter: data.gatewaysPage.filter,
                        customer: data.gatewaysPage.customer,
                        type: "all"
                      }}
                    />}
                  {this.state.gatewayOpen &&
                    <TableMutateGateway
                      gateway={data.gatewaysPage.values.find(gateway => gateway.id === this.state.gateway)}
                      customer={this.state.customer}
                      customers={data.gatewaysPage.customers}
                      edit={this.state.edit}
                      gatewayOpen={this.state.gatewayOpen}
                      closeGatewayModal={this.closeModal}
                      rootName='gatewaysPage'
                      parameterName='values'
                      fetchMore={fetchMore}
                      variables={{
                        skip: data.gatewaysPage.skip,
                        limit: data.gatewaysPage.limit,
                        filter: data.gatewaysPage.filter,
                        customer: data.gatewaysPage.customer,
                        type: "all"
                      }}
                    />}
                    {this.state.selectCustomer &&
                      <SelectCustomerModal
                        open={this.state.selectCustomer}
                        customers={data.gatewaysPage.customers || []}
                        selectCustomer={this.selectCustomer}
                        onClose={this.closeModal}
                      />}
                </Container>
            }
          </Query>
  }
}

export default withApollo(Gateways);
