import React from "react";
import {Tab, Form} from "semantic-ui-react";
import {Field} from "formik";
import FormField from "../../formField";


const CallcenterEmail = ({
                             errors,
                             touched,
                      }) => {
    return (
        <Tab.Pane
            style={{
                height: '728px',
                overflow: 'auto'
            }}
        >
            <FormField
                name="callcenterEmails.monFri16_22"
                label="Mon – Fri 16:00 – 22:00"
                errors={errors}
                touched={touched}
                Component={Form.Checkbox}
            />
            <FormField
                name="callcenterEmails.monFri22_7"
                label="Mon – Sat 22:00 – 7:00"
                errors={errors}
                touched={touched}
                Component={Form.Checkbox}
            />
            <FormField
                name="callcenterEmails.satSun7_22"
                label="Sat – Sun 7:00 – 22:00"
                errors={errors}
                touched={touched}
                Component={Form.Checkbox}
            />
            <FormField
                name="callcenterEmails.satSun22_7"
                label="Sat – Mon 22:00 – 7:00"
                errors={errors}
                touched={touched}
                Component={Form.Checkbox}
            />
            <div
                style={{
                    width: "30%"
                }}
            >
                <Form.Field>
                    <label htmlFor={`callcenterEmails.email1`}>
                        Email 1
                    </label>
                    <Field
                        name={`callcenterEmails.email1`}
                        placeholder={'email address'}
                    />
                </Form.Field>
                <Form.Field>
                    <label htmlFor={`callcenterEmails.email2`}>
                        Email 2
                    </label>
                    <Field
                        name={`callcenterEmails.email2`}
                        placeholder={'email address'}
                    />
                </Form.Field>
            </div>
        </Tab.Pane>
    );
}

export default CallcenterEmail;
