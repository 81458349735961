import React from 'react';
import { Message } from 'semantic-ui-react';

export default ({ error }) =>
    <Message negative>
      <Message.Header>
        {error.code || error.statusCode}
      </Message.Header>
      {error.message}
    </Message>
