import { toast } from "react-toastify";
import { CURRENT_USER, REFRESH_TOKEN } from "../graphql/queries";

export default class Session {
  constructor(client) {
    this.client = client;
  }

  _setAccessTokenExpirationTimer = timeout => {
    if (this.accessTokenExpirationTimer) {
      clearTimeout(this.accessTokenExpirationTimer);
    }
    this.accessTokenExpirationTimer = setTimeout(() => {
      toast.error("Access token has been expired");
      this.signout();
    }, timeout);
  };

  save = response => {
    if (response.expiration > Date.now()) {
      localStorage.setItem("token", response.token);
      localStorage.setItem("expiration", response.expiration);
      localStorage.setItem("user", JSON.stringify(response.user));
      this._setAccessTokenExpirationTimer(response.expiration - Date.now());
      this.client.writeData({
        data: { isLoggedIn: true, user: response.user }
      });
    }
  };

  update = response => {
    const { token, expiration } = response;

    if (token) localStorage.setItem("token", token);
    if (expiration) localStorage.setItem("expiration", expiration);

    localStorage.setItem(
      "user",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("user")),
        ...response
      })
    );
    const data = this.client.readQuery({ query: CURRENT_USER });
    this.client.writeQuery({
      query: CURRENT_USER,
      data: {
        user: {
          ...data.user,
          ...response
        }
      }
    });
  };

  refresh = async () => {
    try {
      const { data } = await this.client.query({
        query: REFRESH_TOKEN,
        fetchPolicy: "network-only"
      });
      const { token, expiration } = data.response;
      this.update({ token, expiration });
    } catch (e) {
      toast.error(e.message);
    }
  };

  signout = async () => {
    try {
      if (this.accessTokenExpirationTimer) {
        clearTimeout(this.accessTokenExpirationTimer);
      }

      localStorage.clear();

      for (const query of this.client.queryManager.queries) {
        if (query[1].document) {
          if (query[1].document.loc.source.body.indexOf("isLoggedIn") === -1) {
            this.client.queryManager.removeObservableQuery(query[0]); // remove every query which is not isLoggedIn
          }
        }
      }

      await this.client.resetStore();
    } catch (e) {
      console.log(e.message);
    }
  };

  restore = () => {
    const expiration = localStorage.getItem("expiration");
    if (expiration) {
      // this._setAccessTokenExpirationTimer(expiration - Date.now());
      this.client.writeData({
        data: {
          isLoggedIn: !!localStorage.getItem("token"),
          user: JSON.parse(localStorage.getItem("user"))
        }
      });
    }
  };
}
