import React, { Component }  from "react";
import { Formik, Field, Form as FormikForm } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import { Button, Form, Icon, Container, Modal } from 'semantic-ui-react'

const SelectDeviceTypeVS = yupObject().shape({
  deviceType: yupString()
    .required('Device type is required'),
});

const SelectDeviceTypeModal = ({
  customers,
  selectDeviceType,
  ...props
}) =>
  <Formik
  	initialValues={{ deviceType: '' }}
  	validationSchema={SelectDeviceTypeVS}
  	onSubmit={(values, {setStatus, setSubmitting}) => {
      selectDeviceType(values.deviceType);
    }}
    render={renderProps => <SelectDeviceType
      customers={customers}
      {...props}
      {...renderProps}
    />}
  />

class SelectDeviceType extends Component {
  render() {
    const {
      open,
      onClose,
      isValid
    } = this.props;
    return (
      <Modal
        open={open}
        onClose={onClose}
        size='tiny'
        closeOnDocumentClick
      >
        <Modal.Header>{'Select device type'}</Modal.Header>
        <Modal.Content>
          <FormikForm className="ui form error">
            <Field
              className="required"
              name="deviceType"
              render={({field, form}) => (
                <Form.Select
                  name={field.name}
                  label='Device type'
                  placeholder="Select device type"
                  value={field.value}
                  options={[{
                    value: 'compressor',
                    text: 'Running hours device'
                  },
                  {
                      value: 'Maintenance',
                      text: 'Maintenance hours device'
                  },
                  {
                      value: 'RemainingHours',
                      text: 'Remaining hours device'
                  },
                  {
                    value: 'child',
                    text: 'Child'
                  },
                  {
                    value: 'calendar',
                    text: 'Calendar'
                  },
                ]}
                  onChange={(e, selected) => {
                    form.setFieldValue(field.name, selected.value);
                  }}
                />
              )}
            />
            <Container textAlign='right'>
              <Button primary type='submit' role='button' disabled={!isValid}>
                <Icon name='chevron right' /> Next
              </Button>
            </Container>
          </FormikForm>
        </Modal.Content>
      </Modal>
    );
  }
}

export default SelectDeviceTypeModal;
