import React, { Component, Fragment } from 'react';
import { Dropdown, Label } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import moment from 'moment';
import { UPDATE_CALENDAR_ALARM } from '../../graphql/mutations';
import CalendarSetNewDate from '../forms/calendarSetNewDate';

const RED = '#db2828';
const YELLOW = '#fbbd08';

class CalendarMaintenance extends Component {
  state = {
    openModal: false
  }

  render() {
    const { item, alignRight, currentUser } = this.props;
    const text = moment(item.maintenanceDateTime).fromNow();
    return (
    item.alarms.warning.length || item.alarms.maintenance.length
    ?(<Mutation
        mutation={UPDATE_CALENDAR_ALARM}
      >
      {(updateAlarm, { loading, error } ) => {
        return <Fragment>
          <Dropdown
            fluid
            loading={loading}
            text={text}
            disabled={currentUser.role === 1}
            style={{
              maxWidth: "150px",
              textAlign: 'center',
              float: alignRight ? 'right': '',
              marginRight: alignRight ? '1rem': '',
              padding: '0.29rem',
              paddingRight: '0.58rem',
              borderRadius: '0.29rem',
              color: 'white',
              fontSize: '0.86rem',
              background: item.alarms.maintenance.length
                ? (item.alarms.maintenance[0].status === 'ACKNOWLEDGED' ? 'grey' : RED)
                : (item.alarms.warning[0].status === 'ACKNOWLEDGED' ? 'grey' : YELLOW)
            }}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text='ACKNOWLEDGE'
                disabled={((item.alarms.maintenance.length > 0 && item.alarms.maintenance[0].status === 'ACKNOWLEDGED')
                    || (item.alarms.warning.length > 0 && item.alarms.warning[0].status === 'ACKNOWLEDGED'))}
                onClick={async () => {
                  try {
                    await updateAlarm({ variables: {
                      id: item.alarms.maintenance.length > 0 ? item.alarms.maintenance[0].id : item.alarms.warning[0].id,
                      status: "ACKNOWLEDGED",
                      calendarId: item.id,
                      customerId: item.parentId
                    }
                  });
                  } catch (e) {
                    console.log(e.message);
                  }
                }}
              />
              <Dropdown.Item
                text='Clear...'
                onClick={() => this.setState({ openModal: true })}
              />
            </Dropdown.Menu>
          </Dropdown>
          {this.state.openModal &&
            <CalendarSetNewDate
              updateAlarm={updateAlarm}
              calendar={item}
              role={currentUser.role}
              onClose={() => this.setState({ openModal: false })}
              open={this.state.openModal}
              loading={loading}
              error={error}
            />}
        </Fragment>}
      }
      </Mutation>
    ):(<Label
          color='green'
          style={alignRight
            ? {float: 'right', marginRight: '1rem', width: '100%', maxWidth: "150px", textAlign: 'center'}
            : {width: '100%', maxWidth: "150px", textAlign: 'center'}}
        >
          {text}
        </Label>)
    );
  }
}

export default CalendarMaintenance;
