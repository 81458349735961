import React, { Component }  from "react";
import { Formik, Field, Form as FormikForm } from 'formik';
import { object as yupObject, string as yupString } from 'yup';
import { Button, Form, Icon, Container, Modal } from 'semantic-ui-react'

import { getSorting } from '../../utils';

const SelectCustomerVS = yupObject().shape({
  customer: yupString()
    .required('customer is required'),
});

const SelectCustomerModal = ({
  customers,
  selectCustomer,
  ...props
}) =>
  <Formik
  	initialValues={{ customer: '' }}
  	validationSchema={SelectCustomerVS}
  	onSubmit={(values, {setStatus, setSubmitting}) => {
      selectCustomer(customers.find(customer => customer.id === values.customer));
    }}
    render={renderProps => <SelectCustomer
      customers={customers}
      {...props}
      {...renderProps}
    />}
  />

class SelectCustomer extends Component {
  render() {
    const {
      open,
      customers,
      onClose,
      isValid
    } = this.props;
    return (
      <Modal
        open={open}
        onClose={onClose}
        size='tiny'
        closeOnDocumentClick
      >
        <Modal.Header>{'Select customer'}</Modal.Header>
        <Modal.Content>
          <FormikForm className="ui form error">
            <Field
              className="required"
              name="customer"
              render={({field, form}) => (
                <Form.Select
                  name={field.name}
                  label='Customer'
                  placeholder="Select Customer"
                  value={field.value}
                  options={customers
                    .sort(getSorting('ascending', 'name'))
                    .map(customer => ({
                    value: customer.id,
                    text: customer.name
                  }))}
                  onChange={(e, selected) => {
                    form.setFieldValue(field.name, selected.value);
                  }}
                />
              )}
            />
            <Container textAlign='right'>
              <Button primary type='submit' role='button' disabled={!isValid}>
                <Icon name='chevron right' /> Next
              </Button>
            </Container>
          </FormikForm>
        </Modal.Content>
      </Modal>
    );
  }
}

export default SelectCustomerModal;
