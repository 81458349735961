import React from 'react';
import { Container, Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import { object as yupObject, string as yupString, ref as yupRef } from 'yup';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import logo from '../../img/kaeser_logo-300x138.png';

const SetPasswordForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  loading,
  error,
  isValid,
}) => (
  <div className='login-form'>
    {values.success
    ? (<Container>
        <Message positive>
          <Message.Header>Success!</Message.Header>
          New password has been set. Continue with <Link to='/'>Login</Link>
        </Message>
      </Container>
    ):(
     <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Link to='/'>
          <Image src={logo} style={{marginLeft: 'auto', marginRight: 'auto'}} />
        </Link>
        <Header as='h2' color='blue' textAlign='center'>
          Set password form
        </Header>
        <Form size='large' error onSubmit={handleSubmit}>
          <Segment stacked>
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='New password'
              type='password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.new_password}
              name='new_password'
              disabled={loading}
            />
            {errors.new_password && touched.new_password &&
              <Message
                error
                header='error'
                content={errors.new_password}
              />
            }
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Confirm password'
              type='password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.conf_password}
              name='conf_password'
              disabled={loading}
            />
            {errors.conf_password && touched.conf_password &&
              <Message
                error
                header='error'
                content={errors.conf_password}
              />
            }
            <Button
              color='blue'
              type='submit'
              fluid
              size='large'
              disabled={loading || !isValid}
              loading={loading}
            >
              Set password
            </Button>
            {(errors.token || errors.email) &&
              <Message
                error
                header='error'
                content={`${errors.token} ${errors.email} `}
              />
            }
            {error &&
              <Message
                error
                header='error'
                content={error.message}
              />
            }
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>)}
  </div>
)

export default withFormik({
  mapPropsToValues: (props) => ({
    new_password: '',
    conf_password: '',
    token: new URLSearchParams(props.location.search).get('token') || '',
    email: new URLSearchParams(props.location.search).get('email') || '',
    success: false
  }),
  validationSchema: yupObject().shape({
    new_password: yupString()
    		    .required('required'),
    conf_password: yupString()
      	    .oneOf([yupRef('new_password', null)],  'match')
      	    .required('required'),
    token: yupString()
            .required('not_found'),
    email: yupString()
            .email('invalid')
            .required('not_found'),
  }),
  handleSubmit: async (values, { setFieldValue, props }) => {
    try {
      await props.setNewPassword({
        variables: {
          email: values.email,
          token: values.token,
          password: values.new_password,
        }
      });
      setFieldValue('success', true);
    } catch (e) {
      setFieldValue('success', false);
      console.log(e.message);
    }
  },
})(SetPasswordForm);
