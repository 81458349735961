import React, { Component, Fragment }  from "react";
import { Formik, Field, Form as FormikForm } from 'formik';
import {object as yupObject, string as yupString, number as yupNumber, boolean as yupBoolean } from 'yup';
import { Button, Form, Icon, Container, Modal, Message, Tab } from 'semantic-ui-react'

import Map from '../../components/map';
import FormField from './formField';
import AddTime from './addTime';
import { getSorting } from '../../utils';
import cfg from '../../config';

const shouldAddField = (compressor, fieldName, fieldValue) => {
  return Boolean((compressor.hasOwnProperty(fieldName) && compressor[fieldName] !== null) || (fieldValue !== cfg.defaultSettings[fieldName]));
}


const CompressorFormVS = yupObject().shape({
  edit: yupBoolean(),
  name: yupString()
    .required('name is required'),
  parentId: yupString()
    .required(),
  gateway: yupString().required('gateway is required'),
  params: yupString().required('parameter is required'),
  maintenanceInterval: yupNumber()
    .required('maintenanceInterval is required'),
  enabled: yupBoolean().required(),
  notification_email: yupString().email('invalid email'),
  enableWarningEmail: yupBoolean().required(),
  enableMaintenanceEmail: yupBoolean().required(),
  enableRunningHoursEmail: yupBoolean().required(),
  runningHoursEmailValue: yupNumber().when('enableRunningHoursEmail', {
			is: true,
			then: yupNumber().required(),
			otherwise: yupNumber(),
		}),
});

const CompressorModal = ({
  compressor,
  customer,
  edit,
  ...props
}) =>
  <Formik
  	initialValues={{
      ...compressor,
      notification_email: compressor.notification_email || '',
      defaultWarningCycleBefore: compressor.defaultWarningCycleBefore || cfg.defaultSettings.defaultWarningCycleBefore,
      defaultMaintenanceCycle: compressor.defaultMaintenanceCycle || cfg.defaultSettings.defaultMaintenanceCycle,
      defaultCalendarWarning: compressor.defaultCalendarWarning || cfg.defaultSettings.defaultCalendarWarning,
      defaultCalendarMaintenance: compressor.defaultCalendarMaintenance || cfg.defaultSettings.defaultCalendarMaintenance,
      enableWarningEmail: compressor.enableWarningEmail || false,
      enableMaintenanceEmail: compressor.enableMaintenanceEmail || false,
      enableRunningHoursEmail: compressor.enableRunningHoursEmail || false,
      runningHoursEmailValue: compressor.runningHoursEmailValue || 0,
      edit,
      params: compressor.params[0] || ''
    }}
  	validationSchema={CompressorFormVS}
    enableReinitialize
    isInitialValid={props => {
      CompressorFormVS.isValid(props.initialValues)
      .then(valid => valid)
    }}
  	onSubmit={async (values, {setStatus, setSubmitting}) => {
        const {
          name,
          parentId,
          gateway,
          params,
          maintenanceInterval,
          notification_email,
          enableWarningEmail,
          enableMaintenanceEmail,
          enableRunningHoursEmail,
          runningHoursEmailValue,
          defaultWarningCycleBefore,
          defaultMaintenanceCycle,
          defaultCalendarWarning,
          defaultCalendarMaintenance,
          enabled
        } = values;
    		const data = {
          name,
          parentId,
          gateway,
          params: [params],
          maintenanceInterval,
          notification_email,
          enableWarningEmail,
          enableMaintenanceEmail,
          enableRunningHoursEmail,
          runningHoursEmailValue,
          ...(shouldAddField(compressor, 'defaultWarningCycleBefore', defaultWarningCycleBefore) && { defaultWarningCycleBefore }),
          ...(shouldAddField(compressor, 'defaultMaintenanceCycle', defaultMaintenanceCycle) && { defaultMaintenanceCycle }),
          ...(shouldAddField(compressor, 'defaultCalendarWarning', defaultCalendarWarning) && { defaultCalendarWarning }),
          ...(shouldAddField(compressor, 'defaultCalendarMaintenance', defaultCalendarMaintenance) && { defaultCalendarMaintenance }),
          enabled,
        };
        try {
          props.mutateDevice({
            variables: { compressor: data, ...(edit && { id: compressor.id }) },
            optimisticResponse: {
              __typename: "Mutation",
              response: edit
                ? {
                  ...data,
                  id: compressor.id,
                  __typename: "Compressor",
                  createdAt: compressor.createdAt,
                  currentValue: compressor.currentValue,
                  alarms: compressor.alarms,
                  location: compressor.location,
                  lastResetDatetime: compressor.lastResetDatetime,
                  lastWeekHours : compressor.lastWeekHours,
                }:{
                  ...data,
                  __typename: "Compressor",
                  id: 'TEMP_ID',
                  createdAt: 0,
                  currentValue: 0,
                  lastWeekHours : 0,
                  
                  location: {
                    lat: '',
                    lon: '',
                    __typename: "Loc"
                  },
                  alarms: {
                    malfunction: [],
                    warning: [],
                    maintenance: [],
                    __typename: "Alarms"
                  },
                }
            }
          });
          props.onClose();
        } catch (e) {
          console.log(e.message);
        }
    	}
    }
    render={renderProps => <Compressor
      customer={customer}
      {...props}
      {...renderProps}
    />}
  />

class Compressor extends Component {
  state = {
    activeIndex: 0
  }
  
  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex })
  
  _handleSelect = (_, { name, value }) => {
      this.props.setFieldValue(name, value);
      const selectedGateway = this.props.gateways.find(gateway => gateway.id === this.props.values.gateway);
      if(selectedGateway) {
        const gatewayValue = selectedGateway.lastMessage.find(field => field.key === value).value;
        this.props.setFieldValue('maintenanceInterval', gatewayValue);
      }
  }

  render() {
    const {
      errors,
      touched,
      values,
      open,
      onClose,
      loading,
      error,
      customer,
      isValid,
      customers,
      role,
    } = this.props;
    const selectedGateway = this.props.gateways.find(gateway => gateway.id === values.gateway);
    const panes = [
      {
        menuItem: 'Settings',
        render: () => (
          <Tab.Pane textAlign='left' style={{ minHeight: '450px' }}>
            <FormField
              className="required"
              name="name"
              label="Name"
              placeholder="Name"
              errors={errors}
              touched={touched}
              Component={Form.Input}
            />
            {values.edit &&
            <Field
              name="parentId"
              render={({field, form}) => (
                <Fragment>
                <Form.Select
                  className="required"
                  name={field.name}
                  label='Customer'
                  placeholder="Select customer"
                  value={field.value}
                  options={customers
                    .sort(getSorting('ascending', 'name'))
                    .map(customer => ({
                    value: customer.id,
                    text: customer.name
                  }))}
                  error={errors[field.name] && touched[field.name]}
                  onChange={(e, selected) => {
                    form.setFieldValue(field.name, selected.value);
                  }}
                />
                {errors[field.name] && touched[field.name] &&
                  <Message
                    error
                    header='error'
                    content={errors[field.name]}
                  />
                }
                </Fragment>
              )}
            />}
            <Field
              name="gateway"
              render={({field, form}) => (
              <Fragment>
                <Form.Select
                  className="required"
                  name={field.name}
                  label="Gateway"
                  placeholder="Select Gateway"
                  value={field.value}
                  options={this.props.gateways
                    .sort(getSorting('ascending', 'name'))
                    .map(gateway => ({
                    value: gateway.id,
                    text: gateway.name
                  }))}
                  error={errors[field.name] && touched[field.name]}
                  onChange={(e, selected) => {
                    form.setFieldValue(field.name, selected.value);
                  }}
                />
                {errors[field.name] && touched[field.name] &&
                  <Message
                    error
                    header='error'
                    content={errors[field.name]}
                  />
                }
                </Fragment>
              )}
            />
            <Field
              name={"params"}
              render={({field, form}) => (
                <Form.Select
                  className="required"
                  name={field.name}
                  label="parameter"
                  placeholder="Select parameter"
                  disabled={!selectedGateway || !selectedGateway.lastMessage.length}
                  value={field.value}
                  onChange={this._handleSelect}
                  options={selectedGateway
                      ? selectedGateway.lastMessage
                        .filter(entry => entry.key !== 'timestamp')
                        .filter(entry => !/_maintenanceInterval/.test(entry.key))
                        .map((entry, i) => ({
                          value: entry.key,
                          text: `${entry.key}: ${entry.value}`
                        }))
                      : []
                      }
                />)}
            />
            <Form.Group widths='equal' style={{alignItems: 'flex-end'}}>
              <FormField
                width='7'
                fluid
                className="required"
                name="maintenanceInterval"
                label="Maintenance Interval"
                placeholder="Maintenance Interval"
                type="number"
                errors={errors}
                noError
                touched={touched}
                Component={Form.Input}
              />
            <AddTime
              baseValue={values.maintenanceInterval}
              setValue={(value) => this.props.setFieldValue("maintenanceInterval", value)}
              options={[
                { value: 1 , text: 'hours' },
                { value: 24 , text: 'days' },
                { value: 24 * 30 , text: 'months' }
              ]}
            />
            </Form.Group>
            <FormField
              name="enabled"
              label="Running hours device enabled"
              errors={errors}
              touched={touched}
              Component={Form.Checkbox}
            />
            {error &&
            <Message
              error
              header='Server error'
              content={error.message}
            />}
          </Tab.Pane>)},
          {
            menuItem: 'Location',
            render: () => (
              <Container textAlign='right'>
                <Tab.Pane textAlign='left' style={{ minHeight: '450px' }}>
                  <Map
                    lat={selectedGateway ? selectedGateway.location.lat : null}
                    lon={selectedGateway ? selectedGateway.location.lon : null}
                  />
                </Tab.Pane>
              </Container>
          )},
          {
            menuItem: 'Notification',
            render: () => (
              <Container textAlign='right' >
                <Tab.Pane textAlign='left' style={{ minHeight: '450px' }}>
                  <FormField
                    fluid
                    name="notification_email"
                    type="email"
                    label="Email address for notifications:"
                    placeholder="email"
                    errors={errors}
                    touched={touched}
                    Component={Form.Input}
                  />
                  <FormField
                    name="enableWarningEmail"
                    label="enable warning email"
                    errors={errors}
                    touched={touched}
                    Component={Form.Checkbox}
                  />
                  <FormField
                    name="enableMaintenanceEmail"
                    label="enable maintenance email"
                    errors={errors}
                    touched={touched}
                    Component={Form.Checkbox}
                  />
                  <Form.Group style={{ alignItems: 'center' }}>
                    <FormField
                      name="enableRunningHoursEmail"
                      label="enable running hours email on (hours):"
                      errors={errors}
                      touched={touched}
                      Component={Form.Checkbox}
                    />
                    <FormField
                      name="runningHoursEmailValue"
                      type="number"
                      readOnly={!values.enableRunningHoursEmail}
                      errors={errors}
                      touched={touched}
                      Component={Form.Input}
                    />
                  </Form.Group>
                  <Message info>
                    <Message.Header>Current running hours:</Message.Header>
                    {values.currentValue}
                  </Message>
                </Tab.Pane>
              </Container>
          )},
          {
            menuItem: 'Default settings',
            render: () => (
              <Container textAlign='right' >
                <Tab.Pane textAlign='left' style={{ minHeight: '450px' }}>
                  <FormField
                    fluid
                    type="number"
                    readOnly={role < 3}
                    name="defaultWarningCycleBefore"
                    label="Default warning cycle in days before maintenance:"
                    placeholder="hours"
                    errors={errors}
                    touched={touched}
                    Component={Form.Input}
                  />
                  <FormField
                    fluid
                    type="number"
                    readOnly={role < 3}
                    name="defaultMaintenanceCycle"
                    label="Default maintenance cycle hours:"
                    placeholder="hours"
                    errors={errors}
                    touched={touched}
                    Component={Form.Input}
                  />
                  <FormField
                    fluid
                    type="number"
                    readOnly={role < 3}
                    name="defaultCalendarWarning"
                    label="Default calendar warning in month:"
                    placeholder="month"
                    errors={errors}
                    touched={touched}
                    Component={Form.Input}
                  />
                  <FormField
                    fluid
                    type="number"
                    readOnly={role < 3}
                    name="defaultCalendarMaintenance"
                    label="Default calendar maintenance in month:"
                    placeholder="month"
                    errors={errors}
                    touched={touched}
                    Component={Form.Input}
                  />
                  <Button
                    type="button"
                    floated='right'
                    disabled={role < 3}
                    onClick={() => {
                      Object.keys(cfg.defaultSettings).forEach(key => {
                          if(values.hasOwnProperty(key)) {
                            this.props.setFieldValue(key, cfg.defaultSettings[key]);  
                          }
                      })
                    }}
                  >
                    Load defaults
                  </Button>
                </Tab.Pane>
              </Container>
          )},
        ];
    return (
      <Modal
        open={open}
        onClose={onClose}
        size='tiny'
        closeOnDocumentClick
      >
        <Modal.Header>{values.edit ? `Edit Running hours device (customer: ${customer.name})` : `Add Running hours device (customer: ${customer.name})`}</Modal.Header>
        <Modal.Content>
          <FormikForm className="ui form error">
            <Tab
              activeIndex={this.state.activeIndex}
              menu={{ secondary: true, pointing: true }}
              panes={selectedGateway && selectedGateway.location ? panes : panes.filter(pane => pane.menuItem !== 'Location')}
              onTabChange={this.handleTabChange}
            />
            <Container textAlign='right' style={{ marginTop: '1rem' }}>
              <Button
                primary
                type='submit'
                icon='checkmark'
                content='Save'
                role='button'
                disabled={loading || !isValid}
                loading={loading}
              />
              <Button secondary basic onClick={onClose}>
                <Icon name='remove' /> Cancel
              </Button>
            </Container>
          </FormikForm>
        </Modal.Content>
      </Modal>
    );
  }
}

export default CompressorModal;
