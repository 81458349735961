import React, {Component, Fragment, createRef} from 'react';
import {
    Icon,
    Accordion,
    Segment,
    Popup,
    Label,
    Header,
    Container,
    Message,
    Card,
    Button,
    Loader,
    Input,
    Image
} from 'semantic-ui-react';
import {Query} from 'react-apollo';
import {withRouter} from 'react-router';

import {DeleteCustomer, MutateCustomer} from '../mutations/customer';
import GatewaysList from '../gateway/list';
import CompressorsList from '../compressor/list';
import MaintenancesList from "../maintenance/list";
import RemainingHoursList from "../remainingHours/list";
import ChildrenList from '../child/list';
import CalendarsList from '../calendar/list';
import ShowImage from '../forms/showImage';
import {getSorting} from '../../utils';

import {CUSTOMER_CHILDREN} from '../../graphql/queries';

const initModals = {
    modalOpen: false,
    modalMounted: false,
    edit: false,
    customer: null,
    deleteConfirm: false,
    showImage: false,
    url: '',
    filter: ''
}

class CustomersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 'ascending',
            activeIndex: -1,
            ...initModals
        };

        this.customerRefs = props.data
            .reduce((acc, value) => {
                acc[value.id] = createRef();
                return acc;
            }, {});
    }

    scrollToActive = () => {
        if (this.state.activeIndex !== -1) {
            const customerRef = this.customerRefs[this.state.activeIndex];
            if (customerRef) {
                this.customerRefs[this.state.activeIndex].current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const customerId = params.get("customer");

        if (customerId) {
            this.setState({ activeIndex: customerId }, this.scrollToActive);
        }
    }

    closeCustomerModal = () => {
        this.setState(initModals);
    }

    openAddCustomer = () => {
        this.setState({
            modalOpen: true,
            modalMounted: true,
        })
    }

    openEditCustomer = async (customer) => {
        await this.props.client.query({ query: this.props.refetch, variables: { id: customer.id } });
        this.setState({
            modalOpen: true,
            modalMounted: true,
            edit: true,
            customer: {
                ...customer,
                number: customer.number || '', // for customers which were saved whithout fields
                contractNumber: customer.contractNumber || '',
                emailAlarmSender: customer.emailAlarmSender || '',
                notes: customer.notes || '',
                image1: customer.image1 || '',
                image2: customer.image2 || '',
                image3: customer.image3 || '',
                image4: customer.image4 || '',
                showOnDashboard: customer.showOnDashboard || false,
            }
        });
    }

    openDeleteCustomer = (customer) => {
        this.setState({
            deleteConfirm: true,
            customer: {
                id: customer.id,
                number: customer.number,
                name: customer.name,
            }
        })
    }

    displayModal = (state) => this.setState({ modalOpen: state });

    handleToggle = (e, index) => {
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
        this.setState({ activeIndex: newIndex }, this.scrollToActive);
    }

    handleFilterChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    toggleSort = () => {
        this.setState({
            order: this.state.order === 'ascending' ? 'descending' : 'ascending',
        });
    }

    showImage = url => {
        this.setState({
            showImage: true,
            url
        })
    }

    render() {
        const { activeIndex, filter, order } = this.state;
        const { refetch, currentUser } = this.props;
        return (
            <Fragment>
                <Card fluid>
                    <Card.Content>
                        <Card.Header style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '23px'
                        }}>
                            Customers
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Icon
                                    style={{ float: 'right' }}
                                    link
                                    name={order === 'ascending' ? 'sort alphabet up' : 'sort alphabet down'}
                                    onClick={this.toggleSort}
                                />
                                <Input
                                    name='filter'
                                    size='mini'
                                    style={{ float: 'right', height: '34px' }}
                                    icon='search'
                                    placeholder='Search...'
                                    value={filter}
                                    onChange={this.handleFilterChange}
                                />
                                {(currentUser.role === 4 || currentUser.role === 3)
                                    ? <Button floated='right' icon labelPosition='left' primary size='small'
                                              onClick={this.openAddCustomer}>
                                        <Icon name='building'/> Add Customer
                                    </Button>
                                    : null}
                            </div>
                        </Card.Header>
                    </Card.Content>
                    <Card.Content>
                        <Accordion>
                            <Segment.Group>
                                {this.props.data
                                    .filter(customer => customer.name.toLowerCase().includes(this.state.filter.toLowerCase()))
                                    .sort(getSorting(order, 'name'))
                                    .map((customer, i) =>
                                        <Segment
                                            key={i}
                                            className={activeIndex === customer.id ? 'active' : null}
                                            style={{
                                                paddingRight: '0.5rem',
                                                paddingLeft: '0.5rem',
                                                background: activeIndex === -1 || activeIndex === customer.id ? 'white' : 'rgba(211, 211, 211, 0.2)'
                                            }}
                                        >
                                            <div ref={this.customerRefs[customer.id]} style={{}}/>
                                            <Accordion.Title style={{ cursor: 'inherit' }}>
                                                <Container>
                                                    <Header
                                                        as='h4'
                                                        style={{
                                                            display: 'inline-flex',
                                                            alignItems: 'center',
                                                            width: '90%',
                                                            marginBottom: 0,
                                                            color: customer.id === 'TEMP_ID' ? 'lightgrey' : ''
                                                        }}
                                                    >
                                                        <div
                                                            style={{ width: '50%', cursor: 'pointer' }}
                                                            onClick={(e) => this.handleToggle(e, customer.id)}
                                                        >
                                                            {customer.name}
                                                        </div>
                                                        <Label
                                                            style={{ width: '15%' }}>Number:<Label.Detail>{customer.number}</Label.Detail></Label>
                                                        <Popup
                                                            trigger={
                                                                <Icon
                                                                    link
                                                                    color="green"
                                                                    name="image outline"
                                                                    style={{
                                                                        visibility: customer.image1 ? 'visible' : 'hidden',
                                                                        marginLeft: '8px'
                                                                    }}
                                                                    onClick={() => this.showImage(customer.image1)}
                                                                />}
                                                            content={<Image src={customer.image1} size='tiny'/>}
                                                            size='mini'
                                                            hoverable
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => this.showImage(customer.image1)}
                                                        />
                                                        <Popup
                                                            trigger={
                                                                <Icon
                                                                    link
                                                                    color="green"
                                                                    name="image outline"
                                                                    style={{ visibility: customer.image2 ? 'visible' : 'hidden' }}
                                                                    onClick={() => this.showImage(customer.image2)}
                                                                />}
                                                            content={<Image src={customer.image2} size='tiny'/>}
                                                            size='mini'
                                                            hoverable
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => this.showImage(customer.image2)}
                                                        />
                                                        <Popup
                                                            trigger={
                                                                <Icon
                                                                    link
                                                                    color="green"
                                                                    name="image outline"
                                                                    style={{ visibility: customer.image3 ? 'visible' : 'hidden' }}
                                                                    onClick={() => this.showImage(customer.image3)}
                                                                />}
                                                            content={<Image src={customer.image3} size='tiny'/>}
                                                            size='mini'
                                                            hoverable
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => this.showImage(customer.image3)}
                                                        />
                                                        <Popup
                                                            trigger={
                                                                <Icon
                                                                    link
                                                                    color="green"
                                                                    name="image outline"
                                                                    style={{ visibility: customer.image4 ? 'visible' : 'hidden' }}
                                                                    onClick={() => this.showImage(customer.image4)}
                                                                />}
                                                            content={<Image src={customer.image4} size='tiny'/>}
                                                            size='mini'
                                                            hoverable
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => this.showImage(customer.image4)}
                                                        />
                                                        <Label style={{ width: '25%' }}>Contract
                                                            Number:<Label.Detail>{customer.contractNumber}</Label.Detail></Label>
                                                    </Header>
                                                    <Loader active={customer.id === 'TEMP_ID'}/>
                                                    {([3, 4].includes(currentUser.role))
                                                        ?   <Popup
                                                            trigger={
                                                                <Icon
                                                                    style={{ float: 'right' }}
                                                                    link
                                                                    color='red'
                                                                    name='trash alternate outline'
                                                                    onClick={() => this.openDeleteCustomer(customer)}
                                                                />
                                                            }
                                                            content='delete'
                                                            size='mini'
                                                        />
                                                        : null}
                                                    {([2, 3, 4].includes(currentUser.role))
                                                        ?   <Popup
                                                                trigger={
                                                                    <Icon
                                                                        style={{ float: 'right' }}
                                                                        link
                                                                        color='blue'
                                                                        name='edit outline'
                                                                        onClick={() => this.openEditCustomer(customer)}
                                                                    />
                                                                }
                                                                content='edit'
                                                                size='mini'
                                                            />
                                                        : null}
                                                    <Popup
                                                        trigger={<Icon name='setting' link style={{ float: 'right' }}
                                                                       onClick={(e) => this.handleToggle(e, customer.id)}/>}
                                                        content='settings'
                                                        size='mini'
                                                    />
                                                    {customer.notes
                                                        ? <Popup
                                                            trigger={<Icon name='sticky note outline' color="yellow"
                                                                           link style={{ float: 'right' }}/>}
                                                            position='left center'
                                                            hoverable
                                                            size='small'
                                                            style={{ maxWidth: '500px' }}
                                                        >
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: customer.notes
                                                                }}
                                                            />
                                                        </Popup>
                                                        : null}
                                                </Container>
                                            </Accordion.Title>
                                            <Accordion.Content active={activeIndex === customer.id}
                                                               style={{ padding: '0.5rem', background: 'white' }}>
                                                {(activeIndex === customer.id && customer.id !== 'TEMP_ID') &&
                                                    <Query query={CUSTOMER_CHILDREN} variables={{ id: customer.id }}
                                                           fetchPolicy="network-only">
                                                        {({ loading, data, client, error }) =>
                                                            error
                                                                ? <Message negative>{error.message}</Message>
                                                                : (loading
                                                                    ? <Message icon info>
                                                                        <Icon name='circle notched' loading/>
                                                                        <Message.Content>
                                                                            Loading gateways and devices...
                                                                        </Message.Content>
                                                                    </Message>
                                                                    : (<Fragment>
                                                                        <GatewaysList
                                                                            data={data.customerChildren ? data.customerChildren.gateways : []}
                                                                            customer={customer}
                                                                            customers={this.props.data}
                                                                            client={client}
                                                                            refetch={CUSTOMER_CHILDREN}
                                                                            rootName='customerChildren'
                                                                            currentUser={currentUser}
                                                                        />
                                                                        <CompressorsList
                                                                            data={data.customerChildren ? data.customerChildren.compressors : []}
                                                                            customer={customer}
                                                                            customers={this.props.data}
                                                                            gateways={data.customerChildren ? data.customerChildren.gateways : []}
                                                                            refetch={CUSTOMER_CHILDREN}
                                                                            rootName='customerChildren'
                                                                            currentUser={currentUser}
                                                                        />
                                                                        <MaintenancesList
                                                                            data={data.customerChildren ? data.customerChildren.maintenances : []}
                                                                            customer={customer}
                                                                            customers={this.props.data}
                                                                            gateways={data.customerChildren ? data.customerChildren.gateways : []}
                                                                            refetch={CUSTOMER_CHILDREN}
                                                                            rootName='customerChildren'
                                                                            currentUser={currentUser}
                                                                        />
                                                                        <RemainingHoursList
                                                                            data={data.customerChildren ? data.customerChildren.remainingHours : []}
                                                                            customer={customer}
                                                                            customers={this.props.data}
                                                                            gateways={data.customerChildren ? data.customerChildren.gateways : []}
                                                                            refetch={CUSTOMER_CHILDREN}
                                                                            rootName='customerChildren'
                                                                            currentUser={currentUser}
                                                                        />
                                                                        <ChildrenList
                                                                            data={data.customerChildren ? data.customerChildren.children : []}
                                                                            customer={customer}
                                                                            customers={this.props.data}
                                                                            compressors={data.customerChildren ? data.customerChildren.compressors : []}
                                                                            refetch={CUSTOMER_CHILDREN}
                                                                            rootName='customerChildren'
                                                                            currentUser={currentUser}
                                                                        />
                                                                        <CalendarsList
                                                                            data={data.customerChildren ? data.customerChildren.calendars : []}
                                                                            customer={customer}
                                                                            customers={this.props.data}
                                                                            refetch={CUSTOMER_CHILDREN}
                                                                            rootName='customerChildren'
                                                                            currentUser={currentUser}
                                                                        />
                                                                    </Fragment>))}
                                                    </Query>}
                                            </Accordion.Content>
                                        </Segment>
                                    )}
                            </Segment.Group>
                        </Accordion>
                    </Card.Content>
                </Card>
                <ShowImage
                    open={this.state.showImage}
                    onClose={this.closeCustomerModal}
                    url={this.state.url}
                />
                {this.state.customer &&
                    <DeleteCustomer
                        customer={this.state.customer}
                        deleteConfirm={this.state.deleteConfirm}
                        closeDeleteCustomer={this.closeCustomerModal}
                        refetch={refetch}
                    />}
                {this.state.modalMounted &&
                    <MutateCustomer
                        customer={this.state.customer}
                        edit={this.state.edit}
                        modalOpen={this.state.modalOpen}
                        closeCustomerModal={this.closeCustomerModal}
                        displayModal={this.displayModal}
                        refetch={refetch}
                    />}
            </Fragment>);
    }
}

export default withRouter(CustomersList);
