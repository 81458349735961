import React, { Component } from 'react';
import LoaderPage from '../components/loaderPage';
import { Card, Container } from 'semantic-ui-react';
import { Query, Mutation } from 'react-apollo';
import UserProfile from '../components/forms/userProfile';
import { PROFILE } from '../graphql/queries';
import { UPDATE_PROFILE } from '../graphql/mutations';
import services from '../services';
const session = services.session;

class PrtofilePage extends Component {
  render() {
    return <Query query={PROFILE}>
            {({ data, loading }) =>
              loading
              ? <LoaderPage />
              : (
               <Container>
                 <Card fluid>
                   <Card.Content>
                     <Card.Header>Profile</Card.Header>
                   </Card.Content>
                   <Card.Content>
                    <Mutation
                      mutation={UPDATE_PROFILE}
                      update={(store, { data: { response } }) => {
                        const data = store.readQuery({ query: PROFILE });
                        store.writeQuery({ query: PROFILE, data: {
                          profile: {
                            ...data.profile,
                            ...response
                          }}
                        });
                        session.update(response);
                      }}
                    >
                    {(updateProfile, { loading, error } ) =>
                       <UserProfile
                         profile={data.profile}
                         updateProfile={updateProfile}
                         loading={loading}
                         error={error}
                       />
                    }
                    </Mutation>
                   </Card.Content>
                 </Card>
               </Container>
             )
            }
            </Query>
  }
}

export default PrtofilePage;
