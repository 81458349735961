import React, { Fragment }  from "react";
import { Form, Message } from 'semantic-ui-react';
import { Field } from 'formik';

const FormField = ({
  name,
  errors,
  touched,
  Component,
  noError,
  ...props
}) =>
  <Field
    name={name}
    render={({field, form}) => (
      <Fragment>
        <Component
          name={field.name}
          value={Component === Form.Checkbox ? '' : field.value}
          checked={field.value}
          id={field.name}
          error={errors[field.name] && touched[field.name]}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          {...props}
        />
        {(errors[field.name] && touched[field.name] && (!noError)) &&
          <Message
            error
            header='error'
            content={errors[field.name]}
          />
        }
      </Fragment>
    )}
    {...props}
  />

export default FormField;
