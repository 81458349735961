import React, { Fragment } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router';
import { Query } from 'react-apollo';
import TopMenu from '../components/topMenu';
import Users from './users';
import Gateways from './gateways';
import Devices from './devices';
import Customers from './customers';
import Dashboard from './dashboard';
import ProfilePage from './profilePage';
import MapPage from './mapPage';
import Data from './data';
import Version from '../components/version';
import { CURRENT_USER } from '../graphql/queries';

const PrivateLayout = ({path, ...props}) =>
  <Query query={CURRENT_USER}>
  {({ data, client, loading }) => {
    return loading
    ? 'Loading...'
    : <Fragment>
        <TopMenu
          path={path}
          currentUser={data.user}
          client={client}
          {...props}
          openProfile={() => props.history.push(`${path}/profile`)}
        />
        <Switch>
          <Redirect exact from={path} to={`${path}/dashboard`} />
          <Route
            path={`${path}/dashboard`}
            render={() => <Dashboard currentUser={data.user} />}
          />}
          <Route
            path={`${path}/profile`}
            render={() => <ProfilePage currentUser={data.user} />}
          />}
          {data.user && data.user.role === 4 &&
          <Route
            path={`${path}/users`}
            render={() => <Users currentUser={data.user} />}
          />}
          <Route
            path={`${path}/customers`}
            render={() => <Customers currentUser={data.user} />}
          />
          <Route
            path={`${path}/gateways`}
            render={() => <Gateways currentUser={data.user} />}
          />
          <Route
            path={`${path}/devices`}
            render={() => <Devices currentUser={data.user} />}
          />
          <Route
            path={`${path}/map`}
            render={() => <MapPage />}
          />
          {data.user && data.user.role === 4 &&
          <Route
            path={`${path}/data`}
            render={() => <Data currentUser={data.user} />}
          />}
        </Switch>
        <Version />
    </Fragment>}
  }
  </Query>;

export default withRouter(PrivateLayout);
