import React, {Component, Fragment} from "react";
import {Formik, Field, Form as FormikForm} from 'formik';
import {object as yupObject, string as yupString, boolean as yupBoolean} from 'yup';
import {Button, Form, Icon, Container, Modal, Message, Tab} from 'semantic-ui-react'
import DatePicker from "react-datepicker";
import moment from 'moment';

import FormField from './formField';
import AddTime from './addTime';
import {getSorting} from '../../utils';
import cfg from '../../config';

const shouldAddField = (calendar, fieldName, fieldValue) => {
    return Boolean((calendar.hasOwnProperty(fieldName) && calendar[fieldName] !== null) || (fieldValue !== cfg.defaultSettings[fieldName]));
}

const CalendarModal = ({
                           calendar,
                           customer,
                           displayModal,
                           edit,
                           ...props
                       }) =>
    <Formik
        initialValues={{
            ...calendar,
            edit,
            maintenanceDateTime: +moment(calendar.maintenanceDateTime),
            maintenanceWarningDateTime: +moment(calendar.maintenanceWarningDateTime),
            defaultCalendarWarningCycle: calendar.defaultCalendarWarningCycle || cfg.defaultSettings.defaultCalendarWarningCycle,
            defaultCalendarMaintenanceCycle: calendar.defaultCalendarMaintenanceCycle || cfg.defaultSettings.defaultCalendarMaintenanceCycle,
        }}
        validationSchema={yupObject().shape({
            edit: yupBoolean(),
            name: yupString()
                .required('name is required'),
            parentId: yupString()
                .required(),
            enabled: yupBoolean().required()
        })}
        onSubmit={async (values, {setStatus, setSubmitting}) => {
            const {
                name,
                parentId,
                maintenanceDateTime,
                maintenanceWarningDateTime,
                defaultCalendarWarningCycle,
                defaultCalendarMaintenanceCycle,
                enabled
            } = values;
            const data = {
                name,
                parentId,
                maintenanceDateTime: +moment(maintenanceDateTime),
                maintenanceWarningDateTime: +moment(maintenanceWarningDateTime),
                ...(shouldAddField(calendar, 'defaultCalendarWarningCycle', defaultCalendarWarningCycle) && {defaultCalendarWarningCycle}),
                ...(shouldAddField(calendar, 'defaultCalendarMaintenanceCycle', defaultCalendarMaintenanceCycle) && {defaultCalendarMaintenanceCycle}),
                enabled,
            };
            try {
                displayModal(false);
                await props.mutateDevice({
                    variables: {calendar: data, ...(edit && {id: calendar.id})},
                    optimisticResponse: {
                        __typename: "Mutation",
                        response: edit
                            ? {
                                ...data,
                                id: calendar.id,
                                alarms: calendar.alarms,
                                lastResetDatetime: calendar.lastResetDatetime,
                                __typename: "Calendar",
                            }
                            : {
                                ...data,
                                __typename: "Calendar",
                                id: 'TEMP_ID',
                                alarms: {
                                    malfunction: [],
                                    warning: [],
                                    maintenance: [],
                                    __typename: "Alarms"
                                },
                            }
                    }
                });
                props.onClose();
            } catch (e) {
                displayModal(true);
                console.log(e.message);
            }
        }
        }
        render={renderProps => <Calendar
            calendar={calendar}
            customer={customer}
            edit={edit}
            {...props}
            {...renderProps}
        />}
    />

class Calendar extends Component {
    state = {
        activeIndex: 0
    }

    handleTabChange = (e, {activeIndex}) => this.setState({activeIndex})

    handleChange = (name, value) => {
        this.props.setFieldValue(name, +moment(value));
    }

    render() {
        const {
            calendar,
            edit,
            errors,
            touched,
            values,
            open,
            onClose,
            loading,
            error,
            customer,
            isValid,
            role,
            customers
        } = this.props;

        const panes = [
            {
                menuItem: 'Settings',
                render: () => (
                    <Container>
                        <Tab.Pane>
                            <FormField
                                className="required"
                                name="name"
                                label="Name"
                                placeholder="Name"
                                errors={errors}
                                touched={touched}
                                Component={Form.Input}
                            />
                            {edit &&
                                <Field
                                    name="parentId"
                                    render={({field, form}) => (
                                        <Fragment>
                                            <Form.Select
                                                className="required"
                                                name={field.name}
                                                label='Customer'
                                                placeholder="Select customer"
                                                value={field.value}
                                                options={customers
                                                    .sort(getSorting('ascending', 'name'))
                                                    .map(customer => ({
                                                        value: customer.id,
                                                        text: customer.name
                                                    }))}
                                                error={errors[field.name] && touched[field.name]}
                                                onChange={(e, selected) => {
                                                    form.setFieldValue(field.name, selected.value);
                                                }}
                                            />
                                            {errors[field.name] && touched[field.name] &&
                                                <Message
                                                    error
                                                    header='error'
                                                    content={errors[field.name]}
                                                />
                                            }
                                        </Fragment>
                                    )}
                                />}
                            <Form.Group style={{alignItems: 'flex-end'}}>
                                <Form.Field
                                    width='8'
                                    className="required"
                                >
                                    <label>Maintenance DateTime</label>
                                    <DatePicker
                                        name="maintenanceDateTime"
                                        value={moment(values.maintenanceDateTime).format('YYYY-MM-DD HH:mm')}
                                        selected={moment(values.maintenanceDateTime).toDate()}
                                        placeholder="Maintenance Warning DateTime"
                                        minDate={moment(values.maintenanceWarningDateTime).toDate()}
                                        onChange={(value) => this.handleChange('maintenanceDateTime', value)}
                                        error={errors["maintenanceDateTime"] && touched["maintenanceDateTime"]}
                                        noError
                                        showTimeSelect
                                    />
                                    {errors["maintenanceDateTime"] && touched["maintenanceDateTime"] &&
                                        <Message
                                            error
                                            header='error'
                                            content={errors["maintenanceDateTime"]}
                                        />
                                    }
                                </Form.Field>
                                <AddTime
                                    baseValue={values.maintenanceDateTime}
                                    calendar
                                    setValue={(value) => this.props.setFieldValue("maintenanceDateTime", value)}
                                    options={[
                                        {value: 'hour', text: 'hours'},
                                        {value: 'day', text: 'days'},
                                        {value: 'month', text: 'months'}
                                    ]}
                                />
                            </Form.Group>
                            <Form.Group style={{alignItems: 'flex-end'}}>
                                <Form.Field
                                    width='8'
                                    className="required"
                                >
                                    <label>Maintenance Warning DateTime</label>
                                    <DatePicker
                                        name="maintenanceWarningDateTime"
                                        value={moment(values.maintenanceWarningDateTime).format('YYYY-MM-DD HH:mm')}
                                        selected={moment(values.maintenanceWarningDateTime).toDate()}
                                        placeholder="Maintenance Warning DateTime"
                                        minDate={edit ? moment(calendar.maintenanceWarningDateTime).toDate() : moment().add(1, 'day').toDate()}
                                        onChange={(value) => this.handleChange('maintenanceWarningDateTime', value)}
                                        error={errors["maintenanceWarningDateTime"] && touched["maintenanceWarningDateTime"]}
                                        noError
                                        showTimeSelect
                                    />
                                    {errors["maintenanceWarningDateTime"] && touched["maintenanceWarningDateTime"] &&
                                        <Message
                                            error
                                            header='error'
                                            content={errors["maintenanceWarningDateTime"]}
                                        />
                                    }
                                </Form.Field>
                                <AddTime
                                    baseValue={values.maintenanceWarningDateTime}
                                    calendar
                                    setValue={(value) => this.props.setFieldValue("maintenanceWarningDateTime", value)}
                                    options={[
                                        {value: 'hour', text: 'hours'},
                                        {value: 'day', text: 'days'},
                                        {value: 'month', text: 'months'}
                                    ]}
                                />
                            </Form.Group>
                            <FormField
                                name="enabled"
                                label="Calendar enabled"
                                errors={errors}
                                touched={touched}
                                Component={Form.Checkbox}
                            />
                            {error &&
                                <Message
                                    error
                                    header='Server error'
                                    content={error.message}
                                />}
                        </Tab.Pane>
                    </Container>
                )
            },
            {
                menuItem: 'Default settings',
                render: () => (
                    <Container textAlign='right'>
                        <Tab.Pane textAlign='left' style={{minHeight: '345px'}}>
                            <FormField
                                fluid
                                type="number"
                                readOnly={role < 3}
                                name="defaultCalendarWarningCycle"
                                label="Default warning cycle in month:"
                                placeholder="months"
                                errors={errors}
                                touched={touched}
                                Component={Form.Input}
                            />
                            <FormField
                                fluid
                                type="number"
                                readOnly={role < 3}
                                name="defaultCalendarMaintenanceCycle"
                                label="Default maintenance cycle in month:"
                                placeholder="months"
                                errors={errors}
                                touched={touched}
                                Component={Form.Input}
                            />
                            <Button
                                type="button"
                                floated='right'

                                onClick={() => {
                                    Object.keys(cfg.defaultSettings).forEach(key => {
                                        if (values.hasOwnProperty(key)) {
                                            this.props.setFieldValue(key, cfg.defaultSettings[key]);
                                        }
                                    })
                                }}
                            >
                                Load defaults
                            </Button>
                        </Tab.Pane>
                    </Container>
                )
            }
        ];

        return (
            <Modal
                open={open}
                onClose={onClose}
                size='tiny'
                closeOnDocumentClick
            >
                <Modal.Header>{values.edit ? `Edit calendar (customer: ${customer.name})` : `Add calendar (customer: ${customer.name})`}</Modal.Header>
                <Modal.Content>
                    <FormikForm className="ui form error">
                        <Tab
                            activeIndex={this.state.activeIndex}
                            menu={{secondary: true, pointing: true}}
                            panes={panes}
                            onTabChange={this.handleTabChange}
                        />
                        <Container textAlign='right' style={{marginTop: '1rem'}}>
                            <Button
                                primary
                                type='submit'
                                icon='checkmark'
                                content='Save'
                                role='button'
                                disabled={loading || !isValid}
                                loading={loading}
                            />
                            <Button secondary basic onClick={onClose}>
                                <Icon name='remove'/> Cancel
                            </Button>
                        </Container>
                    </FormikForm>
                </Modal.Content>
            </Modal>
        );
    }
}

export default CalendarModal;
