import React from 'react';
import { Mutation } from 'react-apollo';
import { Confirm } from 'semantic-ui-react';
import CustomerModal from '../../forms/customer';
import { DELETE_CUSTOMER, ADD_CUSTOMER, UPDATE_CUSTOMER } from '../../../graphql/mutations';

const newCustomerData = {
  name: '',
  number: '',
  contractNumber: '',
  emailAlarmSender: '',
  notes: '',
  image1: '',
  image2: '',
  image3: '',
  image4: '',
  enabled: true,
  alarmFilters: [],
  callcenterEmails: {
    monFri16_22: false,
    monFri22_7: false,
    satSun7_22: false,
    satSun22_7: false,
    email1: 'emailopvang@interswitch.nl',
    email2: 'service.nl@kaeser.com'
  },
  showOnDashboard: true,
};

export const DeleteCustomer = ({
  customer,
  deleteConfirm,
  closeDeleteCustomer,
  refetch,
}) =>
  <Mutation
    mutation={DELETE_CUSTOMER}
    refetchQueries={[{query: refetch}]}
    update={(cache, { data }) => { // use optimisticResponse data for cache updating
        const cachedData = cache.readQuery({ query: refetch });
        cache.writeQuery({ query: refetch, data: {
          customers: cachedData.customers.map(customer => customer.id === data.response ? { ...customer, id: 'TEMP_ID' } : customer)
        } });
    }}
  >
    {(deleteCustomer, { loading } ) =>
      <Confirm
        open={deleteConfirm}
        content={`Delete customer ${customer.name} (${customer.number}) with connected gateways and devices. Are you sure?`}
        cancelButton={loading ? null : 'Cancel'}
        confirmButton={loading ? null : 'Ok'}
        onCancel={closeDeleteCustomer}
        onConfirm={async () => {
          try {
            closeDeleteCustomer()
            await deleteCustomer({ variables: { id: customer.id }, optimisticResponse: { response: customer.id } });
          } catch (e) {
            console.log(e.message);
          }
        }}
      />
    }
  </Mutation>;

export const MutateCustomer = ({
  customer,
  edit,
  modalOpen,
  closeCustomerModal,
  displayModal,
  refetch,
}) =>
  <Mutation
    mutation={edit ? UPDATE_CUSTOMER : ADD_CUSTOMER}
    refetchQueries={edit ? [] : [{ query: refetch }]}
    update={(cache, { data }) => { // use optimisticResponse data for cache updating
      if(!edit) {
        const cachedData = cache.readQuery({ query: refetch });
        cache.writeQuery({ query: refetch, data: {
          customers: [...cachedData.customers, data.response]
        } });
      }
    }}
  >
  {(mutateCustomer, { loading, error } ) =>
    <CustomerModal
      open={modalOpen}
      onClose={closeCustomerModal}
      customer={edit ? customer : newCustomerData}
      edit={edit}
      mutateCustomer={mutateCustomer}
      displayModal={displayModal}
      loading={loading}
      error={error}
    />
  }
  </Mutation>;
