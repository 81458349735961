import React, { Component } from 'react';
import { Form, Button, Icon, Image, Popup } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';

import { UPLOAD_FILE, DELETE_FILE } from '../../../graphql/mutations';

const SIZE_LIMIT = 5 * 1024 * 1024;

class UploadFile extends Component {
  state = {
    file: ''
  }

  handleChange = ({
    target: {
      validity,
      files: [file]
    }
  }) => {
    if(validity.valid && (!file || file.size < SIZE_LIMIT)) {
      this.setState({ file });
    }
  }

  render() {
    const { fieldName, fieldValue, setFieldValue } = this.props;
    return (
            fieldValue
            ? <Mutation
                mutation={DELETE_FILE}
                update={(cache, { data }) => {
                    setFieldValue(fieldName, '');
                }}
              >
                {(deleteFile, { loading, error }) => (
                  <Form.Group style={{minHeight: '80px', width: '45%', alignItems: 'center', marginLeft: 0, marginRight: 0, marginBottom: 0, justifyContent: 'space-between'}}>
                      <label>{fieldName}:</label>
                      <Image
                        src={fieldValue}
                        size='tiny'
                      />
                      <Popup
                        trigger={
                          <Button
                            icon
                            loading={loading}
                            color='red'
                            type="button"
                            onClick={() => deleteFile({ variables: { fileName: fieldValue.substring(fieldValue.lastIndexOf('/') + 1) } })}
                          >
                            <Icon name='trash alternate' />
                          </Button>}
                        content={`remove ${fieldName}`}
                        size='mini'
                      />
                  </Form.Group>)}
              </Mutation>
            : <Mutation
                mutation={UPLOAD_FILE}
                update={(cache, { data }) => {
                  if(data && data.uploadFile) {
                    this.setState({ file: '' });
                      setFieldValue(fieldName, data.uploadFile);
                    }
                  }}
                >
                {(uploadFile, { loading, error }) => (
                  <Form.Group widths='equal' style={{alignItems: 'flex-end', marginRight: 0}}>
                    <Form.Input
                        type='file'
                        label="Select image"
                        onChange={this.handleChange}
                        accept="image/x-png, image/gif, image/jpeg"
                    />
                    <Button
                        disabled={!this.state.file}
                        icon
                        loading={loading}
                        type="button"
                        labelPosition='left'
                        primary
                        style={{width: '100%', marginBottom: '4px'}}
                        onClick={() => uploadFile({ variables: { file: this.state.file } })}
                      >
                        <Icon name='upload' />
                        Upload
                    </Button>
                  </Form.Group>)}
              </Mutation>
        );
    }
}

export default UploadFile;
