import moment from 'moment';

export const getSorting = (order, orderBy) => {
  return order === 'descending'
    ? (a, b) => ((typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy]) < (typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy]) ? -1 : 1)
    : (a, b) => ((typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy]) < (typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy]) ? -1 : 1);
}

export const updateQuery = (prev, { fetchMoreResult }) => {
  return !fetchMoreResult ? prev : { ...prev, ...fetchMoreResult };
}

export const forecastDate = (hoursLeft, hoursPerDay) => {
  return moment().add(Math.floor(hoursLeft / hoursPerDay), 'day');
}

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
