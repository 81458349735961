import {Accordion, Button, Tab, Form} from "semantic-ui-react";
import {FieldArray, Field} from "formik";
import React, {useState} from "react";

const emptyAlarmFilter = {
    name: 'New Alarm Filter',
    emailBodyText: 'Text to search',
    time: {
        from: 8,
        to: 18
    },
    days: ["1", "2", "3", "4", "5"],
    enable: true,
    forwardToEmail: '',
};

const AlarmFilters = ({
                          values,
                      }) => {
    const [alarmFilterActiveIndex, setArmFilterActiveIndex] = useState(-1);

    return (
        <Tab.Pane
            style={{
                height: '728px',
                overflow: 'auto'
            }}
        >
            <FieldArray
                name={"alarmFilters"}
                render={(arrayHelpers) => (
                    (values.alarmFilters && values.alarmFilters.length)
                        ? (<Accordion
                                style={{width: '100%'}}
                                styled
                            >
                                {values.alarmFilters.map((alarmFilter, index) => (
                                    <div
                                        key={index}
                                    >
                                        <Accordion.Title
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'baseline',
                                                background: '#e8e8e8',
                                            }}
                                            onClick={() => setArmFilterActiveIndex(alarmFilterActiveIndex === index ? -1 : index)}
                                            active={Boolean(alarmFilterActiveIndex === index)}
                                        >
                                            <h4>
                                                {alarmFilter.name}
                                            </h4>
                                            <div>
                                                <Button
                                                    type="button"
                                                    icon="trash alternate outline"
                                                    size='mini'
                                                    basic
                                                    color='red'
                                                    title="Remove Alarm Filter"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        arrayHelpers.remove(index)
                                                    }}
                                                />
                                                <Button
                                                    type="button"
                                                    icon="add"
                                                    size='mini'
                                                    basic
                                                    color='green'
                                                    title="Add new Alarm Filter"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        arrayHelpers.insert(index + 1, emptyAlarmFilter)
                                                    }}
                                                />
                                            </div>
                                        </Accordion.Title>
                                        <Accordion.Content
                                            active={Boolean(alarmFilterActiveIndex === index)}
                                        >
                                            <Form.Field>
                                                <label htmlFor={`alarmFilters.${index}.name`}>Filter Name:</label>
                                                <Field
                                                    name={`alarmFilters.${index}.name`}
                                                    placeholder={'name'}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label htmlFor={`alarmFilters.${index}.emailBodyText`}>Email body text:</label>
                                                <Field
                                                    name={`alarmFilters.${index}.emailBodyText`}
                                                    placeholder={'Text'}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Days</label>
                                                <div
                                                    role="group"
                                                    aria-labelledby="checkbox-group"
                                                    style={{
                                                        display: 'flex',
                                                        width: '250px',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            minWidth: '30px'
                                                        }}
                                                    >
                                                        <Field type="checkbox" name={`alarmFilters.${index}.days`}
                                                               value="1"/>
                                                        Mon
                                                    </label>
                                                    <label
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            minWidth: '30px'
                                                        }}
                                                    >
                                                        <Field type="checkbox" name={`alarmFilters.${index}.days`}
                                                               value="2"/>
                                                        Tue
                                                    </label>
                                                    <label
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            minWidth: '30px'
                                                        }}
                                                    >
                                                        <Field type="checkbox" name={`alarmFilters.${index}.days`}
                                                               value="3"/>
                                                        Wed
                                                    </label>
                                                    <label
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            minWidth: '30px'
                                                        }}
                                                    >
                                                        <Field type="checkbox" name={`alarmFilters.${index}.days`}
                                                               value="4"/>
                                                        Thu
                                                    </label>
                                                    <label
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            minWidth: '30px'
                                                        }}
                                                    >
                                                        <Field type="checkbox" name={`alarmFilters.${index}.days`}
                                                               value="5"/>
                                                        Fri
                                                    </label>
                                                    <label
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            color: 'indianred',
                                                            minWidth: '30px'
                                                        }}
                                                    >
                                                        <Field type="checkbox" name={`alarmFilters.${index}.days`}
                                                               value="6"/>
                                                        Sat
                                                    </label>
                                                    <label
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            color: 'indianred',
                                                            minWidth: '30px'
                                                        }}
                                                    >
                                                        <Field type="checkbox" name={`alarmFilters.${index}.days`}
                                                               value="7"/>
                                                        Sun
                                                    </label>
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <label>Time</label>
                                                <div
                                                    style={{
                                                        display: 'flex'
                                                    }}
                                                >
                                                    <label
                                                        style={{
                                                            marginRight: '1rem',
                                                            maxWidth: '10%'
                                                        }}
                                                    >
                                                        from:
                                                        <Field
                                                            min={0}
                                                            max={24}
                                                            type={'number'}
                                                            name={`alarmFilters.${index}.time.from`}
                                                        />
                                                    </label>
                                                    <label
                                                        style={{
                                                            marginRight: '1rem',
                                                            maxWidth: '10%'
                                                        }}
                                                    >
                                                        to:
                                                        <Field
                                                            min={0}
                                                            max={24}
                                                            type={'number'}
                                                            name={`alarmFilters.${index}.time.to`}
                                                        />
                                                    </label>
                                                </div>
                                            </Form.Field>
                                            <Form.Field
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'baseline'
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        marginRight: '1rem'
                                                    }}
                                                >
                                                    Enable
                                                </label>
                                                <Field
                                                    type={'checkbox'}
                                                    checked={alarmFilter.enable}
                                                    name={`alarmFilters.${index}.enable`}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label htmlFor={`alarmFilters.${index}.forwardToEmail`}>Forward to
                                                    Email:</label>
                                                <Field
                                                    name={`alarmFilters.${index}.forwardToEmail`}
                                                    placeholder={'email address, comma separated list if multiple'}
                                                />
                                            </Form.Field>
                                        </Accordion.Content>
                                    </div>
                                ))}
                            </Accordion>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <Button
                                    onClick={() => arrayHelpers.push(emptyAlarmFilter)}
                                >
                                    Add Alarm Filter
                                </Button>
                            </div>
                        )
                )}
            />
        </Tab.Pane>
    );
}

export default AlarmFilters;
