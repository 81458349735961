import React, { Component } from "react";
import { Card, Container, Loader } from "semantic-ui-react";
import DataTable from "../components/dataTable";
import { Query, withApollo } from "react-apollo";
import { DEVICES_PAGE, CHART_DATA } from "../graphql/queries";
import {
  TableDeleteCompressor,
  TableMutateCompressor
} from "../components/mutations/compressor";

import {
  TableDeleteMaintenance,
  TableMutateMaintenance
} from "../components/mutations/maintenance";

import {
  TableDeleteChild,
  TableMutateChild
} from "../components/mutations/child";
import {
  TableDeleteCalendar,
  TableMutateCalendar
} from "../components/mutations/calendar";
import SelectCustomerModal from "../components/forms/selectCustomer";
import SelectDeviceTypeModal from "../components/forms/selectDeviceType";
import ErrorMessage from "../components/errorMessage";
import DataChart from "../components/forms/dataChart";
import cfg from "../config";
import moment from "moment";
import {TableDeleteRemainingHours, TableMutateRemainingHours} from "../components/mutations/remainingHours";

const initModals = {
  deleteConfirm: false,
  compressorOpen: false,
  compressorMounted: false,
  edit: false,
  compressor: null,
  maintenance: null,
  maintenanceOpen: false,
  maintenanceMounted: false,
  childOpen: false,
  childMounted: false,
  child: null,
  calendarOpen: false,
  calendarMounted: false,
  calendar: null,
  customer: null,
  gateways: [],
  compressors: [],
  maintenances: [],
  remainingHours: [],
  remaininghours: null,
  remaininghoursOpen: false,
  remaininghoursMounted: false,
  selectCustomer: false,
  selectDeviceType: false,
  chartParameters: null
};

class Devices extends Component {
  state = {
    ...initModals
  };

  openAddDevice = () => {
    this.setState({ selectCustomer: true });
  };

  selectCustomer = customer => {
    this.setState({
      selectCustomer: false,
      selectDeviceType: true,
      customer,
      gateways: customer.gateways,
      compressors: customer.compressors
    });
  };

  selectDeviceType = deviceType => {
    this.setState({
      selectDeviceType: false,
      [`${deviceType.toLowerCase()}Mounted`]: true,
      [`${deviceType.toLowerCase()}Open`]: true
    });
  };

  openEditDevice = (device, customers) => {
    const customer = customers.find(
      customer => customer.id === device.parentId
    );
    this.setState({
      [`${device.__typename.toLowerCase()}Mounted`]: true,
      [`${device.__typename.toLowerCase()}Open`]: true,
      edit: true,
      [device.__typename.toLowerCase()]: device,
      gateways: customer.gateways,
      compressors: customer.compressors,
      customer
    });
  };

  openDeleteDevice = (device, customers) => {
    const customer = customers.find(
      customer => customer.id === device.parentId
    );
    this.setState({
      deleteConfirm: true,
      [device.__typename.toLowerCase()]: device,
      customer
    });
  };

  openDataChart = compressor => {
    this.setState({
      chartParameters: {
        id: compressor.gateway,
        param: compressor.params[0],
        lastResetDatetime: compressor.lastResetDatetime || compressor.createdAt
      }
    });
  };

  displayModal = (state, name) => this.setState({ [`${name}Open`]: state });

  closeModal = () => {
    this.setState(initModals);
  };

  render() {
    const { currentUser } = this.props;
    return (
      <Query
        query={DEVICES_PAGE}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
      >
        {({ data, loading, client, error, fetchMore, networkStatus }) => (
          <Container>
            {error ? (
              <ErrorMessage error={error} />
            ) : (
              <Card fluid>
                <Card.Content
                  style={{ paddingBottom: "0.7rem", paddingTop: "0.7rem" }}
                >
                  <Card.Header>Devices</Card.Header>
                </Card.Content>
                <Card.Content>
                  <Loader active={loading} />
                  <DataTable
                    currentUser={currentUser}
                    networkStatus={networkStatus}
                    isRequestPending={loading}
                    openAddNew={this.openAddDevice}
                    addNewIcon="industry"
                    addNewText="Add New Device"
                    data={
                      data && data.devicesPage
                        ? data.devicesPage.values.map(device => ({
                            ...device,
                            customer: data.devicesPage.customers.find(
                              customer => customer.id === device.parentId
                            ).name
                          }))
                        : []
                    }
                    filterData={
                      data && data.devicesPage ? data.devicesPage.customers : []
                    }
                    limit={
                      data && data.devicesPage ? data.devicesPage.limit : 10
                    }
                    skip={data && data.devicesPage ? data.devicesPage.skip : 0}
                    total={
                      data && data.devicesPage ? data.devicesPage.total : 0
                    }
                    columns={[
                      { label: "name", field: "name", sort: true },
                      { label: "type", field: "__typename", sort: true },
                      { label: "customer", field: "customer", sort: true },
                      {
                        label: "enabled",
                        field: "enabled",
                        bool: true,
                        width: 1
                      }
                    ]}
                    status={false}
                    maintenance
                    types
                    refetch={DEVICES_PAGE}
                    rootName="devicesPage"
                    actions={[
                      {
                        color: "black",
                        iconName: "chart bar outline",
                        onClick: device => this.openDataChart(device),
                        tooltip: "data chart",
                        devices: ["Compressor"],
                        access: "all"
                      },
                      {
                        color: "blue",
                        iconName: "edit outline",
                        onClick: device =>
                          this.openEditDevice(
                            device,
                            data.devicesPage.customers
                          ),
                        tooltip: "edit"
                      },
                      {
                        color: "red",
                        iconName: "trash alternate outline",
                        onClick: device =>
                          this.openDeleteDevice(
                            device,
                            data.devicesPage.customers
                          ),
                        tooltip: "delete"
                      }
                    ]}
                    fetchMore={fetchMore}
                  />
                </Card.Content>
              </Card>
            )}
            {this.state.compressor && (
              <TableDeleteCompressor
                compressor={this.state.compressor}
                customer={this.state.customer}
                deleteConfirm={this.state.deleteConfirm}
                closeDeleteCompressor={this.closeModal}
                fetchMore={fetchMore}
                variables={{
                  skip: data.devicesPage.skip,
                  limit: data.devicesPage.limit,
                  filter: data.devicesPage.filter,
                  customer: data.devicesPage.customer,
                  type: data.devicesPage.type
                }}
              />
            )}
            {this.state.compressorMounted && (
              <TableMutateCompressor
                compressor={this.state.compressor}
                customer={this.state.customer}
                customers={data.devicesPage.customers}
                gateways={this.state.gateways}
                edit={this.state.edit}
                compressorOpen={this.state.compressorOpen}
                displayModal={state => this.displayModal(state, "compressor")}
                closeCompressorModal={this.closeModal}
                fetchMore={fetchMore}
                role={currentUser.role}
                variables={{
                  skip: data.devicesPage.skip,
                  limit: data.devicesPage.limit,
                  filter: data.devicesPage.filter,
                  customer: data.devicesPage.customer,
                  type: data.devicesPage.type
                }}
              />
            )}
            {this.state.maintenance && (
                <TableDeleteMaintenance
                    maintenance={this.state.maintenance}
                    customer={this.state.customer}
                    deleteConfirm={this.state.deleteConfirm}
                    closeDeleteMaintenance={this.closeModal}
                    fetchMore={fetchMore}
                    variables={{
                      skip: data.devicesPage.skip,
                      limit: data.devicesPage.limit,
                      filter: data.devicesPage.filter,
                      customer: data.devicesPage.customer,
                      type: data.devicesPage.type
                    }}
                />
            )}
            {this.state.maintenanceMounted && (
                <TableMutateMaintenance
                    maintenance={this.state.maintenance}
                    customer={this.state.customer}
                    customers={data.devicesPage.customers}
                    gateways={this.state.gateways}
                    edit={this.state.edit}
                    maintenanceOpen={this.state.maintenanceOpen}
                    displayModal={state => this.displayModal(state, "maintenance")}
                    closeMaintenanceModal={this.closeModal}
                    fetchMore={fetchMore}
                    role={currentUser.role}
                    variables={{
                      skip: data.devicesPage.skip,
                      limit: data.devicesPage.limit,
                      filter: data.devicesPage.filter,
                      customer: data.devicesPage.customer,
                      type: data.devicesPage.type
                    }}
                />
            )}
            {this.state.remaininghours && (
                <TableDeleteRemainingHours
                    remainingHours={this.state.remaininghours}
                    customer={this.state.customer}
                    deleteConfirm={this.state.deleteConfirm}
                    closeDeleteRemainingHours={this.closeModal}
                    fetchMore={fetchMore}
                    variables={{
                      skip: data.devicesPage.skip,
                      limit: data.devicesPage.limit,
                      filter: data.devicesPage.filter,
                      customer: data.devicesPage.customer,
                      type: data.devicesPage.type
                    }}
                />
            )}
            {this.state.remaininghoursMounted && (
                <TableMutateRemainingHours
                    remainingHours={this.state.remaininghours}
                    customer={this.state.customer}
                    customers={data.devicesPage.customers}
                    gateways={this.state.gateways}
                    edit={this.state.edit}
                    remainingHoursOpen={this.state.remaininghoursOpen}
                    displayModal={state => this.displayModal(state, "remaininghours")}
                    closeRemainingHoursModal={this.closeModal}
                    fetchMore={fetchMore}
                    role={currentUser.role}
                    variables={{
                      skip: data.devicesPage.skip,
                      limit: data.devicesPage.limit,
                      filter: data.devicesPage.filter,
                      customer: data.devicesPage.customer,
                      type: data.devicesPage.type
                    }}
                />
            )}
            {this.state.child && (
              <TableDeleteChild
                child={this.state.child}
                customer={this.state.customer}
                deleteConfirm={this.state.deleteConfirm}
                closeDeleteChild={this.closeModal}
                fetchMore={fetchMore}
                variables={{
                  skip: data.devicesPage.skip,
                  limit: data.devicesPage.limit,
                  filter: data.devicesPage.filter,
                  customer: data.devicesPage.customer,
                  type: data.devicesPage.type
                }}
              />
            )}
            {this.state.childMounted && (
              <TableMutateChild
                child={this.state.child}
                customer={this.state.customer}
                customers={data.devicesPage.customers}
                compressors={this.state.compressors}
                edit={this.state.edit}
                childOpen={this.state.childOpen}
                displayModal={state => this.displayModal(state, "child")}
                closeChildModal={this.closeModal}
                fetchMore={fetchMore}
                variables={{
                  skip: data.devicesPage.skip,
                  limit: data.devicesPage.limit,
                  filter: data.devicesPage.filter,
                  customer: data.devicesPage.customer,
                  type: data.devicesPage.type
                }}
              />
            )}
            {this.state.calendar && (
              <TableDeleteCalendar
                calendar={this.state.calendar}
                customer={this.state.customer}
                deleteConfirm={this.state.deleteConfirm}
                closeDeleteCalendar={this.closeModal}
                fetchMore={fetchMore}
                variables={{
                  skip: data.devicesPage.skip,
                  limit: data.devicesPage.limit,
                  filter: data.devicesPage.filter,
                  customer: data.devicesPage.customer,
                  type: data.devicesPage.type
                }}
              />
            )}
            {this.state.calendarMounted && (
              <TableMutateCalendar
                calendar={this.state.calendar}
                customer={this.state.customer}
                customers={data.devicesPage.customers}
                edit={this.state.edit}
                calendarOpen={this.state.calendarOpen}
                displayModal={state => this.displayModal(state, "calendar")}
                closeCalendarModal={this.closeModal}
                role={currentUser.role}
                fetchMore={fetchMore}
                variables={{
                  skip: data.devicesPage.skip,
                  limit: data.devicesPage.limit,
                  filter: data.devicesPage.filter,
                  customer: data.devicesPage.customer,
                  type: data.devicesPage.type
                }}
              />
            )}
            {this.state.selectCustomer && (
              <SelectCustomerModal
                open={this.state.selectCustomer}
                customers={data.devicesPage.customers || []}
                selectCustomer={this.selectCustomer}
                onClose={this.closeModal}
              />
            )}
            {this.state.selectDeviceType && (
              <SelectDeviceTypeModal
                open={this.state.selectDeviceType}
                selectDeviceType={this.selectDeviceType}
                onClose={this.closeModal}
              />
            )}
            {Boolean(this.state.chartParameters) && (
              <Query
                query={CHART_DATA}
                variables={{
                  id: this.state.chartParameters.id,
                  param: this.state.chartParameters.param,
                  from: +moment().subtract(
                    cfg.DEFAULT_CHART_WINDOW + 2,
                    "days"
                  ),
                  until: +moment()
                }}
                fetchPolicy="network-only"
              >
                {({ data, loading, client, error, refetch }) => (
                  <DataChart
                    open={Boolean(this.state.chartParameters)}
                    data={data && data.chartData}
                    loading={loading}
                    onClose={this.closeModal}
                    label={this.state.chartParameters.param}
                    refetch={refetch}
                    chartParameters={this.state.chartParameters}
                    defaultWindow={cfg.DEFAULT_CHART_WINDOW}
                  />
                )}
              </Query>
            )}
          </Container>
        )}
      </Query>
    );
  }
}

export default withApollo(Devices);
