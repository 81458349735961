import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { Message, Container } from 'semantic-ui-react';

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      errorMessage: '',
      redirect: false
    }
  }

  async componentDidMount() {
    const { apolloClient, verifyEmail } = this.props;
    const values = {
      email: new URLSearchParams(this.props.location.search).get('email'),
      token: new URLSearchParams(this.props.location.search).get('token')
    };
    try {
      await apolloClient.mutate({ mutation: verifyEmail, variables: { ...values } });
      this.setState({ success: true },
        () => setTimeout(() => this.setState({ redirect: true }), 3000));
    } catch (e) {
      this.setState({
        success: false,
        errorMessage: e.message
      });
    }
  }

  render()  {
    return this.state.success
    ? <Container>
        {this.state.redirect ? <Redirect to='/' /> : <Message positive header="Success!" content="Email was verified, You will be redirected..." />}
      </Container>
    : this.state.errorMessage &&
      <Container>
        <Message negative header="Error:" content={this.state.errorMessage} />
      </Container>;
  }
}

export default VerifyEmail;
