import React, { Fragment } from 'react';
import { Dropdown, Button } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import {
  UPDATE_EMAIL_ALARM,
  UPDATE_EMAIL_ALARMS,
} from '../../graphql/mutations';

import {
  CUSTOMER_CHILDREN,
} from '../../graphql/queries';

export const EmailAlarm = ({
  item,
  alignRight,
  customer,
  currentUser
}) => {
  return (
   <Mutation
      mutation={UPDATE_EMAIL_ALARM}
      update={(cache, { data }) => { // update customerChildren
        const cachedData = cache.readQuery({ query: CUSTOMER_CHILDREN, variables: { id: customer.id, withAlarms: true } });
        cache.writeQuery({ query: CUSTOMER_CHILDREN, variables: { id: customer.id, withAlarms: true }, data: {
          customerChildren: {
            ...cachedData.customerChildren,
            emailAlarms: [...data.response.emailAlarms]
          }
        } });
     }}
    >
    {(updateAlarm, { loading, error } ) =>
      <Fragment>
        <Dropdown
          fluid
          loading={loading}
          disabled={currentUser.role === 1}
          text={item.status === 'ACKNOWLEDGED' ? 'in progress' : 'malfunction'}
          style={{
            float: alignRight ? 'right' : '',
            margin: '0',
            textAlign: 'center',
            maxWidth: '150px',
            marginRight: alignRight ? '1rem' : '',
            padding: '0.29rem',
            paddingRight: '0.58rem',
            borderRadius: '0.29rem',
            fontSize: '0.86rem',
            color: 'white',
            background: item.status === 'ACKNOWLEDGED' ? 'grey' : '#db2828'
          }}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              text='ACKNOWLEDGE'
              disabled={item.status === 'ACKNOWLEDGED'}
              onClick={async () => {
                try {
                  await updateAlarm({ variables: { id: item.id, status: "ACKNOWLEDGED", customerId: customer.id } });
                } catch (e) {
                  console.log(e.message);
                }
              }}
            />
            <Dropdown.Item
              text='CLEAR'
              onClick={async () => {
                try {
                  await updateAlarm({ variables: { id: item.id, status: "CLEARED", customerId: customer.id } });
                } catch (e) {
                  console.log(e.message);
                }
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Fragment>
    }
    </Mutation>
  );
}

export const EmailAlarms = ({
  ids,
  alarms,
  customer
}) => {
  return (
    <Mutation
       mutation={UPDATE_EMAIL_ALARMS}
       update={(cache, { data }) => { // update customerChildren
         const cachedData = cache.readQuery({ query: CUSTOMER_CHILDREN, variables: { id: customer.id, withAlarms: true } });
         cache.writeQuery({ query: CUSTOMER_CHILDREN, variables: { id: customer.id, withAlarms: true }, data: {
           customerChildren: {
             ...cachedData.customerChildren,
             emailAlarms: [...data.response.emailAlarms]
           }
         } });
      }}
     >
     {(updateAlarms, { loading, error } ) =>
       <div>
         <Button
           size='mini'
           loading={loading}
           disabled={loading || Boolean(
             ids.length === 0 ||
             ids.find(selectedAlarm => alarms.find(alarm => selectedAlarm === alarm.id).status === 'ACKNOWLEDGED'))}
           onClick={async () => {
             try {
               await updateAlarms({ variables: { ids: ids, status: "ACKNOWLEDGED", customerId: customer.id } });
             } catch (e) {
               console.log(e.message);
             }}}
         >
           Acknowledge selected
         </Button>
         <Button
           primary
           basic
           size='mini'
           loading={loading}
           disabled={loading || Boolean(ids.length === 0)}
           onClick={async () => {
             try {
               await updateAlarms({ variables: { ids: ids, status: "CLEARED", customerId: customer.id } });
             } catch (e) {
               console.log(e.message);
             }}}
          >
            Clear selected
          </Button>
       </div>}
    </Mutation>
  );
}
