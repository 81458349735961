import gql from "graphql-tag";
import {
    CURRENT_USER_FIELDS,
    USER_FIELDS,
    CUSTOMER_FIELDS,
    GATEWAY_FIELDS,
    COMPRESSOR_FIELDS,
    MAINTENANCE_FIELDS,
    CHILD_FIELDS,
    CALENDAR_FIELDS,
    CUSTOMER_DEVICES_FIELDS,
    CUSTOMER_CHILDREN_FIELDS,
    REMAINING_HOURS_FIELDS
} from "./fragments";

export const IS_LOGGED_IN = gql`
  {
    isLoggedIn @client
  }
`;

export const CURRENT_USER = gql`
  {
    user @client {
      ...CurrentUserFields
    }
  }
  ${CURRENT_USER_FIELDS}
`;

export const REFRESH_TOKEN = gql`
  query RefreshToken {
    response: refreshToken {
      token
      expiration
    }
  }
`;

export const USERS = gql`
  query GetUsers($skip: Int = 0, $limit: Int = 10, $filter: String = "") {
    users(skip: $skip, limit: $limit, filter: $filter) {
      limit
      skip
      total
      values {
        ...UserFields
      }
    }
  }
  ${USER_FIELDS}
`;

export const USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const CUSTOMERS = gql`
  query GetCustomers($withGateways: Boolean = false) {
    customers(withGateways: $withGateways) {
      ...CustomerFields
      gateways @include(if: $withGateways) {
        name
        TypePullProtocol
        URL_IP_Host
      }
    }
  }
  ${CUSTOMER_FIELDS}
`;

export const DASHBOARD_DATA = gql`
  query GetDasdhboardData {
    dashboardData {
      alarmemailbox {
          hoursFrom
          hoursTo
          enabled
      }
      dashboardCustomers {
        ...CustomerFields
        emailAlarms {
          id
          status
          text
          timestamp
        }
        gateways {
          ...GatewayFields
        }
        compressors {
          ...CompressorFields
        }
        children {
          ...ChildFields
        }
        calendars {
          ...CalendarFields
        }
        maintenances {
          ...MaintenanceFields
        }
        remainingHours {
          ...RemainingHoursFields
        }
      }    
    }
  }
  ${CUSTOMER_FIELDS}
  ${GATEWAY_FIELDS}
  ${COMPRESSOR_FIELDS}
  ${CHILD_FIELDS}
  ${CALENDAR_FIELDS}
  ${MAINTENANCE_FIELDS}
  ${REMAINING_HOURS_FIELDS}
`;

export const GET_ALARMEMAILBOX = gql`
  query GetAlarmemailbox {
      dashboardData {
          alarmemailbox {
              hoursFrom
              hoursTo
              enabled
          }
      }
  }
`;

export const PROFILE = gql`
  query GetProfile {
    profile {
      name
      email
    }
  }
`;

export const CUSTOMER_GATEWAYS = gql`
  query GetCustomerGateways($id: ID!) {
    customerGateways(id: $id) {
      ...GatewayFields
    }
  }
  ${GATEWAY_FIELDS}
`;

export const CUSTOMER_DEVICES = gql`
  query GetCustomerDevices($id: ID!) {
    customerDevices(id: $id) {
      ...CustomerDevicesFields
    }
  }
  ${CUSTOMER_DEVICES_FIELDS}
`;

export const CUSTOMER_CHILDREN = gql`
  query GetCustomerChildren($id: ID!, $withAlarms: Boolean = false) {
    customerChildren(id: $id, withAlarms: $withAlarms) {
      ...CustomerChildrenFields
      emailAlarms {
        id
        status
        text
      }
    }
  }
  ${CUSTOMER_CHILDREN_FIELDS}
`;

export const GATEWAYS_PAGE = gql`
  query GetGatewaysPage(
    $skip: Int = 0
    $limit: Int = 10
    $filter: String = ""
    $customer: String = "all"
  ) {
    gatewaysPage(
      skip: $skip
      limit: $limit
      filter: $filter
      customer: $customer
    ) {
      limit
      skip
      total
      filter
      customer
      values {
        ...GatewayFields
      }
      customers {
        id
        name
        gateways {
          ...GatewayFields
        }
        compressors {
          ...CompressorFields
        }
      }
    }
  }
  ${GATEWAY_FIELDS}
  ${COMPRESSOR_FIELDS}
`;

export const DEVICES_PAGE = gql`
  query GetDevicesPage(
    $skip: Int = 0
    $limit: Int = 10
    $filter: String = ""
    $customer: String = "all"
    $type: String = "all"
  ) {
    devicesPage(
      skip: $skip
      limit: $limit
      filter: $filter
      customer: $customer
      type: $type
    ) {
      limit
      skip
      total
      filter
      customer
      type
      values {
        ... on Compressor {
          ...CompressorFields
        }
        ... on Maintenance {
          ...MaintenanceFields
        }
        ... on RemainingHours {
          ...RemainingHoursFields
        }
        ... on Child {
          ...ChildFields
        }
        ... on Calendar {
          ...CalendarFields
        }
      }
      customers {
        id
        name
        gateways {
          ...GatewayFields
        }
        compressors {
          ...CompressorFields
        }
      }
    }
  }
  ${GATEWAY_FIELDS}
  ${COMPRESSOR_FIELDS}
  ${MAINTENANCE_FIELDS}
  ${REMAINING_HOURS_FIELDS}
  ${CHILD_FIELDS}
  ${CALENDAR_FIELDS}
`;

export const MAP_PAGE = gql`
  query GetMapPage {
    mapPage {
      id
      name
      gateways {
        ...GatewayFields
      }
      compressors {
        ...CompressorFields
      }
    }
  }
  ${GATEWAY_FIELDS}
  ${COMPRESSOR_FIELDS}
`;

export const CHART_DATA = gql`
  query GetChartData(
    $id: ID!
    $param: String!
    $from: Float
    $until: Float
    $grouping: String
    $aggregate: String
  ) {
    chartData(
      id: $id
      param: $param
      from: $from
      until: $until
      grouping: $grouping
      aggregate: $aggregate
    ) {
      t
      v
    }
  }
`;
