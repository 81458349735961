import React, { Fragment } from 'react';
import { Dropdown, Label } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import {
  UPDATE_GATEWAY_ALARM,
  UPDATE_COMPRESSOR_ALARM,
  UPDATE_CALENDAR_ALARM,
} from '../../graphql/mutations';

const selectMutation = typename => {
  switch (typename) {
    case 'Gateway':
      return UPDATE_GATEWAY_ALARM;
    case 'Compressor':
      return UPDATE_COMPRESSOR_ALARM;
    case 'Calendar':
      return UPDATE_CALENDAR_ALARM;
    default:
      return null;
  }
}

const Malfunction = ({
  item,
  alignRight,
  refetch,
  rootName,
  currentUser
}) => {
  const typename = item.__typename;
  const mutation = selectMutation(typename);
  return (
  item.alarms.malfunction.length
  ?(<Mutation
      mutation={mutation}
    >
    {(updateAlarm, { loading, error } ) =>
      <Fragment>
        <Dropdown
          fluid
          loading={loading}
          disabled={currentUser.role === 1}
          text={item.alarms.malfunction[0].status === 'ACKNOWLEDGED' ? 'in progress' : 'malfunction'}
          style={{
            float: alignRight ? 'right' : '',
            margin: '0',
            textAlign: 'center',
            maxWidth: '130px',
            marginRight: alignRight ? '1rem' : '',
            padding: '0.29rem',
            paddingRight: '0.58rem',
            borderRadius: '0.29rem',
            fontSize: '0.86rem',
            color: 'white',
            background: item.alarms.malfunction[0].status === 'ACKNOWLEDGED' ? 'grey' : '#db2828'
          }}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              text='ACKNOWLEDGE'
              disabled={item.alarms.malfunction[0].status === 'ACKNOWLEDGED'}
              onClick={async () => {
                try {
                  await updateAlarm({
                    variables: {
                      id: item.alarms.malfunction[0].id,
                      status: "ACKNOWLEDGED",
                      [`${typename.toLowerCase()}Id`]: item.id,
                      customerId: item.parentId
                    }
                  });
                } catch (e) {
                  console.log(e.message);
                }
              }}
            />
            <Dropdown.Item
              text='CLEAR'
              onClick={async () => {
                try {
                  await updateAlarm({
                    variables: {
                      id: item.alarms.malfunction[0].id,
                      status: "CLEARED",
                      [`${typename.toLowerCase()}Id`]: item.id,
                      customerId: item.parentId
                    }
                  });
                } catch (e) {
                  console.log(e.message);
                }
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Fragment>
    }
    </Mutation>
  ):(<Label
        color='green'
        style={alignRight
          ? {float: 'right', marginRight: '1rem', width: '100%', maxWidth: "130px", textAlign: 'center'}
          : {width: '100%', maxWidth: "130px", textAlign: 'center'}}
      >
        ok
      </Label>)
  );
}


export default Malfunction;
