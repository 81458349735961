import React from 'react';
import { Mutation } from 'react-apollo';
import { Confirm } from 'semantic-ui-react';
import moment from 'moment';
import CalendarModal from '../../forms/calendar';
import { DELETE_CALENDAR, ADD_CALENDAR, UPDATE_CALENDAR } from '../../../graphql/mutations';
import { updateQuery } from '../../../utils';

const newCalendarData = {
  name: '',
  maintenanceDateTime: +moment().add(2, 'day'),
  maintenanceWarningDateTime: +moment().add(1, 'day'),
  enabled: true
};

export const DeleteCalendar = ({
  calendar,
  customer,
  deleteConfirm,
  closeDeleteCalendar,
  refetch
}) =>
  <Mutation
    mutation={DELETE_CALENDAR}
    refetchQueries={[{ query: refetch, variables: { id: customer.id }, optimisticResponse: { response: calendar.id } }]}
    update={(cache, { data }) => { // use optimisticResponse data for cache updating
        const cachedData = cache.readQuery({ query: refetch,  variables: { id: customer.id } });
        cache.writeQuery({
          query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
              calendars: cachedData.customerChildren.calendars.map(calendar => calendar.id === data.response ? { ...calendar, id: 'TEMP_ID' } : calendar)
            }
          }
        });
    }}
  >
    {(deleteCalendar, { loading, error } ) =>
      <Confirm
        open={deleteConfirm}
        content={`Delete calendar ${calendar.name} (${calendar.id}). Are you sure?`}
        onCancel={closeDeleteCalendar}
        onConfirm={async () => {
          try {
            closeDeleteCalendar();
            await deleteCalendar({ variables: { id:  calendar.id, customerId: calendar.parentId }, optimisticResponse: { response: calendar.id }});
          } catch (e) {
            console.log(e.message);
          }
        }}
      />
    }
  </Mutation>;

export const MutateCalendar = ({
  calendar,
  customer,
  customers,
  role,
  edit,
  calendarOpen,
  closeCalendarModal,
  displayModal,
  refetch,
}) =>
  <Mutation
    mutation={edit ? UPDATE_CALENDAR : ADD_CALENDAR}
    refetchQueries={edit ? [] : [{ query: refetch, variables: { id: customer.id } }]}
    update={(cache, { data }) => { // use optimisticResponse data for cache updating
      if(!edit) {
        const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
        cache.writeQuery({ query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
              calendars: [...cachedData.customerChildren.calendars, data.response]
            }
          }
        });
      } else if(data.__typename !== "Mutation" && customer.id !== data.response.parentId) {
        const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
        cache.writeQuery({ query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
              calendars: cachedData.customerChildren.calendars.filter(calendar => calendar.id !== data.response.id)
            }
          }
        });
      }
    }}
  >
  {(mutateCalendar, { loading, error } ) =>
    <CalendarModal
      open={calendarOpen}
      onClose={closeCalendarModal}
      customer={customer}
      customers={customers}
      calendar={edit ? calendar : { ...newCalendarData, parentId: customer.id }}
      edit={edit}
      mutateDevice={mutateCalendar}
      displayModal={displayModal}
      role={role}
      loading={loading}
      error={error}
    />}
  </Mutation>;

export const TableDeleteCalendar = ({
  calendar,
  customer,
  deleteConfirm,
  closeDeleteCalendar,
  fetchMore,
  variables,
}) =>
  <Mutation
    mutation={DELETE_CALENDAR}
    update={(cache, { data }) => {
      if(data.___typename !== "Mutation") {
        fetchMore({variables, updateQuery});
      }
    }}
  >
    {(deleteCalendar, { loading, error } ) =>
      <Confirm
        open={deleteConfirm}
        content={`Delete calendar ${calendar.name} (${calendar.id}). Are you sure?`}
        onCancel={closeDeleteCalendar}
        onConfirm={async () => {
          try {
            closeDeleteCalendar();
            await deleteCalendar({ variables: { id:  calendar.id, customerId: calendar.parentId }, optimisticResponse: { response: calendar.id }});
          } catch (e) {
            console.log(e.message);
          }
        }}
      />
    }
  </Mutation>;

export const TableMutateCalendar = ({
  calendar,
  customer,
  customers,
  edit,
  calendarOpen,
  closeCalendarModal,
  displayModal,
  role,
  fetchMore,
  variables,
}) =>
  <Mutation
    mutation={edit ? UPDATE_CALENDAR : ADD_CALENDAR}
    update={(cache, { data }) => {
      if(data.__typename !== "Mutation") {
        if(!edit) {
          fetchMore({variables, updateQuery});
        } else if(customer.id !== data.response.parentId) {
          fetchMore({variables, updateQuery});
        }        
      }
    }}
  >
  {(mutateCalendar, { loading, error } ) =>
    <CalendarModal
      open={calendarOpen}
      onClose={closeCalendarModal}
      customer={customer}
      customers={customers}
      calendar={edit ? calendar : { ...newCalendarData, parentId: customer.id }}
      edit={edit}
      mutateDevice={mutateCalendar}
      displayModal={displayModal}
      role={role}
      loading={loading}
      error={error}
    />}
  </Mutation>;
