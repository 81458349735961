import React, { Component, Fragment } from "react";
import {
  Icon,
  Segment,
  Message,
  Popup,
  Header,
  Divider,
  Button,
  Loader,
  Label
} from "semantic-ui-react";
import { Query } from "react-apollo";
import { CHART_DATA } from "../../graphql/queries";
import { DeleteMaintenance, MutateMaintenance } from "../mutations/maintenance";
import DataChart from "../forms/dataChart";
import cfg from "../../config";
import moment from "moment";

import { getSorting } from "../../utils";

const initModals = {
  deleteConfirm: false,
  maintenanceOpen: false,
  maintenanceMounted: false,
  edit: false,
  maintenance: null,
  chartParameters: null
};

class MaintenancesList extends Component {
  state = initModals;
  openAddMaintenance = () => {
    this.setState({
      maintenanceOpen: true,
      maintenanceMounted: true,
    });
  };

  openEditMaintenance = maintenance => {
    this.setState({
      maintenanceOpen: true,
      maintenanceMounted: true,
      edit: true,
      maintenance
    });
  };

  openDeleteMaintenance = maintenance => {
    this.setState({
      deleteConfirm: true,
      maintenance
    });
  };

  openDataChart = maintenance => {
    this.setState({
      chartParameters: {
        id: maintenance.gateway,
        param: maintenance.params[0],
        lastResetDatetime: maintenance.lastResetDatetime || maintenance.createdAt
      }
    });
  };

  displayModal = state => this.setState({ maintenanceOpen: state });

  closeModal = () => {
    this.setState(initModals);
  };

  render() {
    const { gateways, customer, customers, refetch, currentUser } = this.props;

    return (
      <Fragment>
        <Divider horizontal>
          <Header as="h5">
            <Icon name="clock outline" />
            Maintenance hours devices
          </Header>
        </Divider>
        <Segment.Group>
          {this.props.data && this.props.data.length ? (
            this.props.data
              .sort(getSorting("ascending", "name"))
              .map((maintenance, i) => {
                const gateway = gateways.find(
                  gateway => gateway.id === maintenance.gateway
                );
                return (
                  <Segment key={i}>
                    <Header
                      as="h5"
                      style={{
                        display: "inline-flex",
                        width: "75%",
                        marginBottom: "0",
                        color: maintenance.id === "TEMP_ID" ? "lightgrey" : ""
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          cursor:
                            currentUser.role === 4 || currentUser.role === 3
                              ? "pointer"
                              : "inherit"
                        }}
                        onClick={() =>
                          currentUser.role === 4 || currentUser.role === 3
                            ? this.openEditMaintenance(maintenance)
                            : {}
                        }
                      >
                        {maintenance.name}
                      </div>
                      <Label
                        style={{ width: "40%" }}
                        color={gateway ? null : "red"}
                      >
                        connected to:
                        <Label.Detail>
                          {gateway ? gateway.name : "No gateway found"}
                        </Label.Detail>
                      </Label>
                    </Header>
                    <Loader active={maintenance.id === "TEMP_ID"} />
                    {currentUser.role === 4 || currentUser.role === 3 ? (
                      <Fragment>
                        <Popup
                          trigger={
                            <Icon
                              link
                              name="trash alternate outline"
                              color="red"
                              style={{ float: "right" }}
                              onClick={() =>
                                this.openDeleteMaintenance(maintenance)
                              }
                            />
                          }
                          content="delete Maintenance hours device"
                          size="mini"
                        />
                        <Popup
                          trigger={
                            <Icon
                              link
                              name="edit outline"
                              color="blue"
                              style={{ float: "right" }}
                              onClick={() =>
                                this.openEditMaintenance(maintenance)
                              }
                            />
                          }
                          content="edit Running hours device"
                          size="mini"
                        />
                      </Fragment>
                    ) : null}
                    <Popup
                      trigger={
                        <Icon
                          link
                          name="chart bar outline"
                          color="black"
                          style={{ float: "right" }}
                          onClick={() => this.openDataChart(maintenance)}
                        />
                      }
                      content="data chart"
                      size="mini"
                    />
                    {/*<Maintenance*/}
                    {/*  currentUser={currentUser}*/}
                    {/*  item={maintenance}*/}
                    {/*  alignRight*/}
                    {/*/>*/}
                  </Segment>
                );
              })
          ) : (
            <Message color="yellow">No Maintenance hours devices found</Message>
          )}
        </Segment.Group>
        {currentUser.role === 4 || currentUser.role === 3 ? (
          <Button
            icon
            labelPosition="right"
            primary
            size="small"
            onClick={() => this.openAddMaintenance()}
          >
            <Icon name="clock outline" /> Add New Maintenance hours device
          </Button>
        ) : null}
        {this.state.maintenance && (
          <DeleteMaintenance
            maintenance={this.state.maintenance}
            customer={customer}
            deleteConfirm={this.state.deleteConfirm}
            closeDeleteMaintenance={this.closeModal}
            refetch={refetch}
          />
        )}
        {this.state.maintenanceMounted && (
          <MutateMaintenance
              maintenance={this.state.maintenance}
            customer={customer}
            customers={customers}
            gateways={gateways}
            edit={this.state.edit}
              maintenanceOpen={this.state.maintenanceOpen}
            displayModal={this.displayModal}
            closeMaintenanceModal={this.closeModal}
            role={currentUser.role}
            refetch={refetch}
          />
        )}
        {Boolean(this.state.chartParameters) && (
          <Query
            query={CHART_DATA}
            variables={{
              id: this.state.chartParameters.id,
              param: this.state.chartParameters.param,
              from: +moment().subtract(cfg.DEFAULT_CHART_WINDOW + 2, "days"),
              until: +moment()
            }}
            fetchPolicy="network-only"
          >
            {({ data, loading, client, error, refetch }) => (
              <DataChart
                open={Boolean(this.state.chartParameters)}
                data={data && data.chartData}
                loading={loading}
                onClose={this.closeModal}
                label={this.state.chartParameters.param}
                refetch={refetch}
                chartParameters={this.state.chartParameters}
                defaultWindow={cfg.DEFAULT_CHART_WINDOW}
              />
            )}
          </Query>
        )}
      </Fragment>
    );
  }
}

export default MaintenancesList;
