const CustomerContent = ({ customer, children, render }) => {
  const { emailAlarms, gateways, compressors, calendars, maintenances, remainingHours } = customer;

  const data = {
    customerChildren: {
      emailAlarms,
      gateways,
      compressors,
      children: customer.children,
      calendars,
      maintenances,
      remainingHours,
    }
  };

  return children({ data });
};

export default CustomerContent;
