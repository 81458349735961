import React from "react";
import General from './General';
import AlarmFilters from "./AlarmFilters";
import CallcenterEmail from "./CallcenterEmail";

const composePanes = ({
                      values,
                      setFieldValue,
                      errors,
                      touched,
                      handleChange,
                  }) => [
    {
        menuItem: 'General',
        render: () => (
            <General
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
            />
        )
    },
    {
        menuItem: 'Alarm filters',
        render: () => (
            <AlarmFilters
                values={values}
            />
        )
    },
    {
        menuItem: 'Callcenter Emails',
        render: () => (
            <CallcenterEmail
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                handleChange={handleChange}
            />
        )
    }
];

export default composePanes;
