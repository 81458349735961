import React from 'react';
import { Mutation } from 'react-apollo';
import { Confirm, Button } from 'semantic-ui-react';
import GatewayModal from '../../forms/gateway';
import {
  DELETE_GATEWAY,
  ADD_GATEWAY,
  UPDATE_GATEWAY,
  UPDATE_GATEWAY_MESSAGE,
} from '../../../graphql/mutations';
import { updateQuery } from '../../../utils';

const newGatewayData = {
  name: '',
  TypePullProtocol: 'SAM1',
  URL_IP_Host: '',
  login: '',
  password: '',
  enabled: true
};

export const DeleteGateway = ({
  gateway,
  customer,
  deleteConfirm,
  closeDeleteGateway,
  refetch,
}) =>
  <Mutation
    mutation={DELETE_GATEWAY}
    refetchQueries={[{ query: refetch, variables: { id: customer.id } }]}
    update={(cache, { data }) => { // use optimisticResponse data for cache updating
        const cachedData = cache.readQuery({ query: refetch,  variables: { id: customer.id } });
        cache.writeQuery({
          query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
              gateways: cachedData.customerChildren.gateways.map(gateway => gateway.id === data.response ? { ...gateway, id: 'TEMP_ID' } : gateway)
            }
          }
        });
    }}
  >
    {(deleteGateway, { loading, error } ) =>
      <Confirm
        open={deleteConfirm}
        content={`Delete gateway ${gateway.name} (${gateway.id}). Are you sure?`}
        onCancel={closeDeleteGateway}
        onConfirm={async () => {
          try {
            closeDeleteGateway();
            await deleteGateway({ variables: { id:  gateway.id, customerId: gateway.parentId }, optimisticResponse: { response: gateway.id } });
          } catch (e) {
            console.log(e.message);
          }
        }}
      />
    }
  </Mutation>;

export const MutateGateway = ({
  gateway,
  customer,
  customers,
  edit,
  gatewayOpen,
  closeGatewayModal,
  displayModal,
  rootName,
  parameterName,
  refetch,
}) =>
  <Mutation
    mutation={edit ? UPDATE_GATEWAY : ADD_GATEWAY}
    refetchQueries={edit ? [] : [{ query: refetch, variables: { id: customer.id } }]}
    update={(cache, { data }) => { // use optimisticResponse data for cache updating
      if(refetch) {
        if(!edit) {
          const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
          cache.writeQuery({ query: refetch,
            variables: { id: customer.id },
            data: {
              customerChildren: {
                ...cachedData.customerChildren,
                gateways: [...cachedData.customerChildren.gateways, data.response]
              }
            }
          });
        } else if(data.__typename !== "Mutation" && customer.id !== data.response.parentId) {
          const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
          cache.writeQuery({ query: refetch,
            variables: { id: customer.id },
            data: {
              customerChildren: {
                ...cachedData.customerChildren,
                gateways: cachedData.customerChildren.gateways.filter(gateway => gateway.id !== data.response.id)
              }
            }
          });
        }        
      }
    }}
  >
  {(mutateGateway, { loading, error } ) =>
    <GatewayModal
      open={gatewayOpen}
      onClose={closeGatewayModal}
      customer={customer}
      customers={customers}
      gateway={edit ? gateway : { ...newGatewayData, parentId: customer.id }}
      edit={edit}
      mutateGateway={mutateGateway}
      displayModal={displayModal}
      loading={loading}
      error={error}
    />}
  </Mutation>;

export const TableDeleteGateway = ({
    gateway,
    customer,
    deleteConfirm,
    closeDeleteGateway,
    fetchMore,
    variables,
  }) =>
    <Mutation
      mutation={DELETE_GATEWAY}
      update={(cache, { data }) => {
        if(data.___typename !== "Mutation") {
          fetchMore({variables, updateQuery});
        }
      }}
    >
      {(deleteGateway, { loading, error } ) =>
        <Confirm
          open={deleteConfirm}
          content={`Delete gateway ${gateway.name} (${gateway.id}). Are you sure?`}
          onCancel={closeDeleteGateway}
          onConfirm={async () => {
            try {
              closeDeleteGateway();
              await deleteGateway({ variables: { id:  gateway.id, customerId: gateway.parentId }, optimisticResponse: { response: gateway.id, ___typename: "Mutation" } });
            } catch (e) {
              console.log(e.message);
            }
          }}
        />
      }
  </Mutation>;

export const TableMutateGateway = ({
    gateway,
    customer,
    customers,
    edit,
    gatewayOpen,
    closeGatewayModal,
    displayModal,
    fetchMore,
    variables,
  }) =>
    <Mutation
      mutation={edit ? UPDATE_GATEWAY : ADD_GATEWAY}
      update={(cache, { data }) => {
        if(data.__typename !== "Mutation") {
          if(!edit) {
            fetchMore({variables, updateQuery});
          } else if(customer.id !== data.response.parentId) {
            fetchMore({variables, updateQuery});
          }          
        }
      }}
    >
    {(mutateGateway, { loading, error } ) =>
      <GatewayModal
        open={gatewayOpen}
        onClose={closeGatewayModal}
        customer={customer}
        customers={customers}
        gateway={edit ? gateway : { ...newGatewayData, parentId: customer.id }}
        edit={edit}
        mutateGateway={mutateGateway}
        displayModal={displayModal}
        loading={loading}
        error={error}
      />}
    </Mutation>;

export const UpdateGatewayMessage = ({
  gatewayId
}) =>
  <Mutation
    mutation={UPDATE_GATEWAY_MESSAGE}
  >
  {(updateGatewayMessage, { loading, error } ) =>
    <Button
      primary
      basic
      type='button'
      icon='sync'
      content='Update'
      loading={loading}
      onClick={() => updateGatewayMessage({ variables: { id: gatewayId } })}
    />
  }
  </Mutation>;
