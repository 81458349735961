import React, { useEffect } from "react";
import LoaderPage from "../components/loaderPage";
import { Container } from "semantic-ui-react";
import { useQuery } from "@apollo/react-hooks";
import { DASHBOARD_DATA } from "../graphql/queries";
import { DATA_UPDATE_SUB } from "../graphql/subscriptions";

import ErrorMessage from "../components/errorMessage";
import DashboardCustomersList from "../components/dashboard/customer/list";

const Dashboard = ({ currentUser }) => {
  const { data, loading, client, error, subscribeToMore, refetch } = useQuery(
      DASHBOARD_DATA,
    { fetchPolicy: "network-only" }
  );

  useEffect(
    () =>
      subscribeToMore({
        document: DATA_UPDATE_SUB,
        updateQuery: prev => {
          refetch();
          return prev;
        }
      }),
    [subscribeToMore, refetch]
  );

  if (loading) return <LoaderPage />;

  return (
    <Container>
      {error ? (
        <ErrorMessage error={error} />
      ) : (
        <DashboardCustomersList
          currentUser={currentUser}
          dashboardCustomers={data.dashboardData.dashboardCustomers}
          alarmemailbox={data.dashboardData.alarmemailbox}
          client={client}
        />
      )}
    </Container>
  );
};

export default Dashboard;
