import gql from 'graphql-tag';

export const CURRENT_USER_FIELDS = gql`
  fragment CurrentUserFields on User {
    id
    name
    role
    email
  }
`;

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    name
    role
    email
    enabled
    emailVerified
  }
`;

export const CUSTOMER_FIELDS = gql`
  fragment CustomerFields on Customer {
    id
    name
    number
    contractNumber
    emailAlarmSender
    notes
    image1
    image2
    image3
    image4
    enabled
    showOnDashboard
    alarmFilters {
        name
        emailBodyText
        enable
        forwardToEmail
        days
        time {
          from
          to
        }
   }
   callcenterEmails {
      monFri16_22
      monFri22_7
      satSun7_22
      satSun22_7
      email1
      email2
   }  
  }
`;

export const GATEWAY_FIELDS = gql`
  fragment GatewayFields on Gateway {
    id
    name
    TypePullProtocol
    URL_IP_Host
    login
    password
    enabled
    parentId
    createdAt
    location {
      lat
      lon
    }
    lastMessage {
      key
      value
    }
    alarms {
      malfunction {
        id
        status
        text
        timestamp
      }
    }
  }
`;

export const COMPRESSOR_FIELDS = gql`
  fragment CompressorFields on Compressor {
    id
    name
    gateway
    currentValue
    params
    maintenanceInterval
    parentId
    notification_email
    enableWarningEmail
    enableMaintenanceEmail
    enableRunningHoursEmail
    runningHoursEmailValue
    defaultWarningCycleBefore
    defaultMaintenanceCycle
    defaultCalendarWarning
    defaultCalendarMaintenance
    lastWeekHours
    createdAt
    lastResetDatetime
    location {
      lat
      lon
    }
    alarms {
      malfunction {
        id
        status
        text
        timestamp
      }
      warning {
        id
        status
        text
        timestamp
      }
      maintenance {
        id
        status
        text
        timestamp
      }
    }
    enabled
  }
`;

export const MAINTENANCE_FIELDS = gql`
  fragment MaintenanceFields on Maintenance {
    id
    name
    gateway
    currentValue
    params
    maintenanceInterval
    parentId
    defaultWarningCycleBefore
    defaultCalendarWarning
    defaultCalendarMaintenance
    lastWeekHours
    createdAt
    lastResetDatetime
    location {
      lat
      lon
    }
    alarms {
      malfunction {
        id
        status
        text
        timestamp
      }
      warning {
        id
        status
        text
        timestamp
      }
      maintenance {
        id
        status
        text
        timestamp
      }
    }
    enabled
  }
`;

export const REMAINING_HOURS_FIELDS = gql`
  fragment RemainingHoursFields on RemainingHours {
    id
    name
    gateway
    currentValue
    params
    parentId
    createdAt
    lastWeekHours
    alarms {
      malfunction {
        id
        status
        text
        timestamp
      }
      warning {
        id
        status
        text
        timestamp
      }
      maintenance {
        id
        status
        text
        timestamp
      }
    }
    enabled
  }
`;

export const CHILD_FIELDS = gql`
  fragment ChildFields on Child {
    id
    name
    compressor
    currentValue
    maintenanceInterval
    lastResetDatetime
    lastWeekHours
    createdAt
    parentId
    notes
    alarms {
      malfunction {
        id
        status
        text
        timestamp
      }
      warning {
        id
        status
        text
        timestamp
      }
      maintenance {
        id
        status
        text
        timestamp
      }
    }
    enabled
  }
`;

export const CALENDAR_FIELDS = gql`
  fragment CalendarFields on Calendar {
    id
    name
    maintenanceDateTime
    maintenanceWarningDateTime
    lastResetDatetime
    defaultCalendarWarningCycle
    defaultCalendarMaintenanceCycle
    createdAt
    parentId
    alarms {
      malfunction {
        id
        status
        text
        timestamp
      }
      warning {
        id
        status
        text
        timestamp
      }
      maintenance {
        id
        status
        text
        timestamp
      }
    }
    enabled
  }
`;

export const CUSTOMER_DEVICES_FIELDS = gql`
  fragment CustomerDevicesFields on Devices {
    compressors {
      ...CompressorFields
    }
    children {
      ...ChildFields
    }
    calendars {
      ...CalendarFields
    }
  }
  ${COMPRESSOR_FIELDS}
  ${CHILD_FIELDS}
  ${CALENDAR_FIELDS}
`;

export const CUSTOMER_CHILDREN_FIELDS = gql`
  fragment CustomerChildrenFields on CustomerChildren {
    gateways {
      ...GatewayFields
    }
    compressors {
      ...CompressorFields
    }
    maintenances {
      ...MaintenanceFields
    }
    remainingHours {
      ...RemainingHoursFields
    }
    children {
      ...ChildFields
    }
    calendars {
      ...CalendarFields
    }
    emailAlarms {
      id
      status
      text
      timestamp
    }
  }
  ${COMPRESSOR_FIELDS}
  ${MAINTENANCE_FIELDS}
  ${REMAINING_HOURS_FIELDS}
  ${CHILD_FIELDS}
  ${CALENDAR_FIELDS}
  ${GATEWAY_FIELDS}
`;
