import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import cfg from '../config';
import { isLocalhost } from '../utils';
import { Button, Message, Icon, Container } from 'semantic-ui-react'

Sentry.init({ dsn: isLocalhost ? null : cfg.SENTRY_DSN });

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { eventId: null };
    }

    static getDerivedStateFromError(error) {
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      Sentry.withScope(scope => {
          scope.setExtras(errorInfo);
          const eventId = Sentry.captureException(error);
          this.setState({eventId: eventId});
      });
    }

    render() {
        if (this.state.hasError) {
            //render fallback UI
            return (
              <Container style={{ marginTop: '2rem'}}>
                <Message warning>
                  <Message.Header>Error occured</Message.Header>
                    <Message.Content style={{ textAlign: 'center' }}>
                      <Button primary onClick={() => window.location.reload()}>
                        <Icon name='refresh' />
                        Reload page
                      </Button>
                      <br />or<br />
                      <Button basic onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</Button>
                    </Message.Content>
                </Message>
              </Container>
            );
        }

        //when there's not an error, render children untouched
        return this.props.children;
    }
}

export default ErrorBoundary;
