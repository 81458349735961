import React, { Component, Fragment } from "react";
import {
  Icon,
  Button,
  Segment,
  Message,
  Popup,
  Container,
  Header,
  Divider,
  Label,
  Loader
} from "semantic-ui-react";
import { DeleteGateway, MutateGateway } from "../mutations/gateway";
import Malfunction from "../alarms/malfunction";

import { getSorting } from "../../utils";

const initModals = {
  deleteConfirm: false,
  gatewayOpen: false,
  gatewayMounted: false,
  edit: false,
  gateway: null,
  isMessageLoading: false
};

class GatewaysList extends Component {
  state = initModals;

  openAddGateway = () => {
    this.setState({
      gatewayOpen: true,
      gatewayMounted: true
    });
  };

  openEditGateway = gateway => {
    this.setState({
      gatewayOpen: true,
      gatewayMounted: true,
      edit: true,
      gateway: gateway.id
    });
  };

  openDeleteGateway = gateway => {
    this.setState({
      deleteConfirm: true,
      gateway: gateway.id
    });
  };

  displayModal = state => this.setState({ gatewayOpen: state });

  closeModal = () => {
    this.setState(initModals);
  };

  render() {
    const { customer, customers, refetch, currentUser } = this.props;

    return (
      <Fragment>
        <Divider horizontal>
          <Header as="h5">
            <Icon name="server" />
            Gateways
          </Header>
        </Divider>
        <Segment.Group>
          {this.props.data && this.props.data.length ? (
            this.props.data
              .sort(getSorting("ascending", "name"))
              .map((gateway, i) => (
                <Segment key={i}>
                  <Container>
                    <Header
                      as="h5"
                      style={{
                        display: "inline-flex",
                        width: "80%",
                        marginBottom: "0",
                        color: gateway.id === "TEMP_ID" ? "lightgrey" : ""
                      }}
                    >
                      <div
                        style={{
                          width: "20%",
                          cursor:
                            currentUser.role === 4 || currentUser.role === 3
                              ? "pointer"
                              : "inherit"
                        }}
                        onClick={() =>
                          currentUser.role === 4 || currentUser.role === 3
                            ? this.openEditGateway(gateway)
                            : {}
                        }
                      >
                        {gateway.name}
                      </div>
                      <Label style={{ width: "50%" }}>
                        url:
                        <Label.Detail>
                          <a
                            href={gateway.URL_IP_Host}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {gateway.URL_IP_Host}
                          </a>
                        </Label.Detail>
                      </Label>
                      <Label style={{ width: "20%" }}>
                        protocol:
                        <Label.Detail>{gateway.TypePullProtocol}</Label.Detail>
                      </Label>
                    </Header>
                    <Loader active={gateway.id === "TEMP_ID"} />
                    {currentUser.role === 4 || currentUser.role === 3 ? (
                      <Fragment>
                        <Popup
                          trigger={
                            <Icon
                              link
                              name="trash alternate outline"
                              color="red"
                              style={{ float: "right" }}
                              onClick={() => this.openDeleteGateway(gateway)}
                            />
                          }
                          content="delete gateway"
                          size="mini"
                        />
                        <Popup
                          trigger={
                            <Icon
                              link
                              name="edit outline"
                              color="blue"
                              style={{ float: "right" }}
                              onClick={() => this.openEditGateway(gateway)}
                            />
                          }
                          content="edit gateway"
                          size="mini"
                        />
                      </Fragment>
                    ) : null}
                    <Malfunction
                      currentUser={currentUser}
                      item={gateway}
                      alignRight
                    />
                  </Container>
                </Segment>
              ))
          ) : (
            <Message color="yellow">No gateways found</Message>
          )}
        </Segment.Group>
        {currentUser.role === 4 || currentUser.role === 3 ? (
          <Button
            icon
            labelPosition="right"
            primary
            size="small"
            onClick={() => this.openAddGateway()}
          >
            <Icon name="server" /> Add New Gateway
          </Button>
        ) : null}
        {this.state.gateway && (
          <DeleteGateway
            gateway={this.props.data.find(
              gateway => gateway.id === this.state.gateway
            )}
            customer={customer}
            deleteConfirm={this.state.deleteConfirm}
            closeDeleteGateway={this.closeModal}
            refetch={refetch}
          />
        )}
        {this.state.gatewayMounted && (
          <MutateGateway
            gateway={this.props.data.find(
              gateway => gateway.id === this.state.gateway
            )}
            customer={customer}
            customers={customers}
            edit={this.state.edit}
            gatewayOpen={this.state.gatewayOpen}
            displayModal={this.displayModal}
            closeGatewayModal={this.closeModal}
            refetch={refetch}
          />
        )}
      </Fragment>
    );
  }
}

export default GatewaysList;
