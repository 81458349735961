import React, { Component }  from "react";
import { Formik, Form as FormikForm } from 'formik';
import {object as yupObject, string as yupString, number as yupNumber, boolean as yupBoolean, ref as yupRef } from 'yup';
import { Button, Form, Icon, Container, Modal, Message } from 'semantic-ui-react';
import cfg from '../../config';

import FormField from './formField';
import AddTime from './addTime';

const CompressorSetNewIntervalVS = yupObject().shape({
  currentValue: yupNumber(),
  edit: yupBoolean(),
  name: yupString()
    .required('name is required'),
  gateway: yupString().required('gateway is required'),
  params: yupString().required('parameter is required'),
  maintenanceInterval: yupNumber()
    .required('maintenanceInterval is required')
    .min(yupRef('currentValue')),
  enabled: yupBoolean().required()
});

const CompressorSetNewIntervalModal = ({
  compressor,
  updateAlarm,
  ...props
}) =>
  <Formik
  	initialValues={{
      ...compressor,
      maintenanceInterval: compressor.currentValue + (compressor.defaultMaintenanceCycle || cfg.defaultSettings.defaultMaintenanceCycle),
    }}
  	validationSchema={CompressorSetNewIntervalVS}
    enableReinitialize
    isInitialValid
  	onSubmit={async (values, {setStatus, setSubmitting}) => {
        try {
          await updateAlarm({ variables: {
            id: compressor.alarms.maintenance.length ? compressor.alarms.maintenance[0].id : compressor.alarms.warning[0].id,
            status: "CLEARED",
            compressorId: values.id,
            maintenanceInterval: values.maintenanceInterval,
            customerId: compressor.parentId,
          }
        });
        } catch (e) {
          console.log(e.message);
        }
    	}
    }
    render={renderProps => <CompressorSetNewInterval
      {...props}
      {...renderProps}
    />}
  />

class CompressorSetNewInterval extends Component {
  render() {
    const {
      errors,
      touched,
      values,
      open,
      onClose,
      loading,
      role,
      isValid
    } = this.props;
    return (
      <Modal
        open={open}
        onClose={onClose}
        size='tiny'
        closeOnDocumentClick
      >
        <Modal.Header>{`Set new Interval for ${values.name}`}</Modal.Header>
        <Modal.Content>
          <Message info>
            <Message.Header>Current value:</Message.Header>
            {values.currentValue}
          </Message>
          <FormikForm className="ui form error">
            <Form.Group widths='equal' style={{alignItems: 'flex-end'}}>
              <FormField
                width='7'
                fluid
                readOnly={role < 3}
                className="required"
                name="maintenanceInterval"
                label="Maintenance Interval"
                placeholder="Maintenance Interval"
                type="number"
                errors={errors}
                noError
                touched={touched}
                Component={Form.Input}
              />
              <AddTime
                baseValue={values.maintenanceInterval}
                setValue={(value) => this.props.setFieldValue("maintenanceInterval", value)}
                disabled={role < 3}
                options={[
                  { value: 1 , text: 'hours' },
                  { value: 24 , text: 'days' },
                  { value: 24 * 30 , text: 'months' }
                ]}
              />
            </Form.Group>
            <Container textAlign='right'>
              <Button primary type='submit' role='button' disabled={loading || !isValid} loading={loading}>
                <Icon name='checkmark' /> Save
              </Button>
              <Button secondary basic onClick={onClose}>
                <Icon name='remove' /> Cancel
              </Button>
            </Container>
          </FormikForm>
        </Modal.Content>
      </Modal>
    );
  }
}

export default CompressorSetNewIntervalModal;
