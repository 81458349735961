import React, {useState, useEffect} from "react";
import {Formik, Form as FormikForm} from 'formik';
import {object as yupObject, string as yupString, boolean as yupBoolean} from 'yup';
import {Button, Icon, Container, Modal, Message, Tab} from 'semantic-ui-react'

import composePanes from './panes';

const CustomerFormVS = yupObject().shape({
    edit: yupBoolean(),
    name: yupString()
        .required('name is required'),
    number: yupString(),
    contractNumber: yupString(),
    emailAlarmSender: yupString()
        .email(),
    notes: yupString(),
    enabled: yupBoolean()
        .required(),
    showOnDashboard: yupBoolean()
});

const FormAction = {
    first: "first",
    second: "second",
    none: "none",
}

const defaultCallcenterEmails = {
    monFri16_22: false,
    monFri22_7: false,
    satSun7_22: false,
    satSun22_7: false,
    email1: "",
    email2: "",
};

const CustomerModal = ({
                           customer,
                           edit,
                           ...props
                       }) =>
    <Formik
        initialValues={{
            action: FormAction.none,
            ...customer,
            ...(
                customer.hasOwnProperty('showOnDashboard')
                    ? {showOnDashboard: customer.showOnDashboard}
                    : {showOnDashboard: true} // default
            ),
            ...(
                customer.callcenterEmails
                    ? {callcenterEmails: customer.callcenterEmails}
                    : {callcenterEmails: defaultCallcenterEmails}
            ),
            edit
        }}
        validationSchema={CustomerFormVS}
        onSubmit={async (values) => {
            const {
                name,
                number,
                contractNumber,
                emailAlarmSender,
                enabled,
                notes,
                image1,
                image2,
                image3,
                image4,
                alarmFilters,
                callcenterEmails,
                showOnDashboard,
                action,
            } = values;
            const data = {
                name,
                enabled,
                number,
                contractNumber,
                emailAlarmSender,
                notes,
                image1,
                image2,
                image3,
                image4,
                showOnDashboard,
                alarmFilters: alarmFilters.map(alarmFilter => {
                    const {__typename, ...rest} = alarmFilter;
                    return {
                        ...rest,
                        time: {
                            from: alarmFilter.time.from,
                            to: alarmFilter.time.to,
                        }
                    };
                }),
                callcenterEmails: (() => {
                    const {__typename, ...rest} = callcenterEmails;
                    return {
                      ...rest
                    };
                })(),
            };
            try {
                props.mutateCustomer({
                    variables: {customer: data, ...(edit && {id: customer.id})},
                });
                if (action === FormAction.first) {
                    props.onClose();
                }
            } catch (e) {
                console.log(e.message);
            }
        }
        }
        render={renderProps =>
            <Index
                {...props}
                {...renderProps}
            />}
    />

const Index = ({
                   errors,
                   touched,
                   values,
                   open,
                   onClose,
                   loading,
                   error,
                   handleChange,
                   setFieldValue,
                   submitForm,
                   isValid
               }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        if(values.action !== FormAction.none) {
            submitForm()
        }
    }, [values.action, submitForm]);

    const handleTabChange = (e, {activeIndex}) => setActiveIndex(activeIndex);

    return (
        <Modal
            open={open}
            closeOnDimmerClick={false}
            size='tiny'
            style={{width: '50%', minWidth: '930px'}}
            closeOnDocumentClick
        >
            <Modal.Header>{values.edit ? 'Edit customer' : 'Add customer'}</Modal.Header>
            <Modal.Content
                style={{
                    paddingTop: '0',
                    paddingBottom: '1rem'
                }}
            >
                <FormikForm className="ui form error">
                    <Tab
                        menu={{secondary: true, pointing: true}}
                        panes={composePanes({
                            values,
                            setFieldValue,
                            errors,
                            touched,
                            handleChange,
                        })}
                        activeIndex={activeIndex}
                        style={{minHeight: '500px'}}
                        onTabChange={handleTabChange}
                    />
                    {error &&
                        <Message
                            error
                            header='Server error'
                            content={error.message}
                        />}
                    <Container
                        textAlign='right'
                        style={{paddingTop: '1rem'}}
                    >
                        <Button
                            primary
                            role='button'
                            disabled={loading || !isValid}
                            onClick={() => setFieldValue("action", FormAction.first)}
                        >
                            <Icon name='checkmark'/> Ok
                        </Button>
                        <Button
                            primary
                            role='button'
                            disabled={loading || !isValid}
                            loading={loading}
                            onClick={() => setFieldValue("action", FormAction.second)}
                        >
                            Apply
                        </Button>
                        <Button secondary basic onClick={onClose}>
                            <Icon name='remove'/> Cancel
                        </Button>
                    </Container>
                </FormikForm>
            </Modal.Content>
        </Modal>
    );
}

export default CustomerModal;
