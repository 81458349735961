import React, { Component, Fragment } from "react";
import {
  Icon,
  Segment,
  Header,
  Divider,
  Loader,
  Label
} from "semantic-ui-react";
import CompressorMaintenance from "../../alarms/compressorMaintenance";

class CompressorsList extends Component {
  render() {
    const { customer, gateways, refetch, rootName, currentUser } = this.props;

    return this.props.data && this.props.data.length ? (
      <Fragment>
        <Divider horizontal>
          <Header as="h5">
            <Icon name="factory" />
            Running hours devices
          </Header>
        </Divider>
        {this.props.data.map((compressor, i) => (
          <Segment
            key={compressor.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline"
            }}
          >
            <Header
              as="h5"
              style={{
                display: "inline-flex",
                width: "70%",
                marginBottom: "0",
                color: compressor.id === "TEMP_ID" ? "lightgrey" : ""
              }}
            >
              <div style={{ width: "50%" }}>{compressor.name}</div>
              <Label style={{ width: "40%" }}>
                connected to:
                <Label.Detail>
                  {
                    gateways.find(gateway => gateway.id === compressor.gateway)
                      .name
                  }
                </Label.Detail>
              </Label>
            </Header>
            <Loader active={compressor.id === "TEMP_ID"} />
            <CompressorMaintenance
              currentUser={currentUser}
              item={compressor}
              customer={customer}
              refetch={refetch}
              rootName={rootName}
              updateChildren
              alignRight
            />
          </Segment>
        ))}
      </Fragment>
    ) : null;
  }
}

export default CompressorsList;
