import React, { Component, Fragment } from 'react';
import { Dropdown, Label, Icon } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { UPDATE_COMPRESSOR_ALARM } from '../../graphql/mutations';
import CompressorSetNewInterval from '../forms/compressorSetNewInterval';
import moment from 'moment';
import { forecastDate } from '../../utils';

const RED = '#db2828';
const YELLOW = '#fbbd08';

class CompressorMaintenance extends Component {
  state = {
    openModal: false
  }

  composeLabel = item => {
    const isWarning = item.alarms.warning.length;
    const leftPart = `[${item.currentValue}]`;
    const calculatedForecastDate = item.lastWeekHours ? forecastDate(item.maintenanceInterval - item.currentValue, item.lastWeekHours / 7) : null;
    const rightPart = item.lastWeekHours
      ? (isWarning ? `${calculatedForecastDate.diff(moment(), 'days')} days left` : calculatedForecastDate.format('YYYY/MM/DD'))
      : 'no data';
    
    return item.currentValue
          ? `${leftPart} ${rightPart}`
          : 'parent status';
  }

  handleOpenModal = () => {
    this.setState({ openModal: true })
  }
  
  handleCloseModal = () => {
    this.setState({ openModal: false })
  }

  render() {
    const { item, customer, updateChildren, refetch, rootName, alignRight, currentUser } = this.props;
    const lastResetDatetime = item.lastResetDatetime || item.createdAt;
    const isLastResetExpired = Boolean(+moment() > +moment(lastResetDatetime).add(11, 'month'));
  
    return (
    item.alarms.warning.length || item.alarms.maintenance.length
    ?(<Mutation
        mutation={UPDATE_COMPRESSOR_ALARM}
        update={(cache, { data }) => { // update children status
          if(updateChildren) {
            if(data.response.alarms.maintenance.length === 0 && data.response.alarms.warning.length === 0) {
              if(rootName === 'customerChildren') {
                const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id, withAlarms: true } });
                console.log(cachedData);
                cache.writeQuery({ query: refetch, variables: { id: customer.id, withAlarms: true }, data: {
                  customerChildren: {
                    ...cachedData.customerChildren,
                    children: cachedData.customerChildren.children.map(child => child.compressor === item.id
                    ? {
                      ...child,
                      alarms: {
                        ...child.alarms,
                        warning: [],
                        maintenance: [],
                      }
                    }
                    : child)
                  }
                } });
              } else {
                const cachedData = cache.readQuery({ query: refetch });
                cache.writeQuery({ query: refetch, data: {
                  devicesPage: {
                    ...cachedData.devicesPage,
                    values: cachedData.devicesPage.values.map(cachedItem => (cachedItem.__typename === "Child" &&  cachedItem.compressor === item.id)
                      ? {
                        ...cachedItem,
                        alarms: {
                          ...cachedItem.alarms,
                          warning: [],
                          maintenance: [],
                        }
                        }
                      : cachedItem
                      )
                  }
                } });
              }
            }
          }
       }}
      >
      {(updateAlarm, { loading, error } ) => {
        return <Fragment>
          <Dropdown
            fluid
            disabled={item.__typename === "Child" || currentUser.role === 1}
            loading={loading}
            trigger={<span>
                      {isLastResetExpired ? <Icon name='clock outline' /> : null}
                      {this.composeLabel(item)}
                     </span>}
            style={{
              float: alignRight ? 'right' : '',
              maxWidth: '150px',
              textAlign: 'center',
              marginRight: alignRight ? '1rem' : '0',
              padding: '0.29rem',
              paddingRight: '0.58rem',
              borderRadius: '0.29rem',
              color: 'white',
              fontSize: '0.86rem',
              background: item.alarms.maintenance.length
                ? (item.alarms.maintenance[0].status === 'ACKNOWLEDGED' ? 'grey' : RED)
                : (item.alarms.warning[0].status === 'ACKNOWLEDGED' ? 'grey' : YELLOW)
            }}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text='ACKNOWLEDGE'
                disabled={((item.alarms.maintenance.length > 0 && item.alarms.maintenance[0].status === 'ACKNOWLEDGED')
                    || (item.alarms.warning.length > 0 && item.alarms.warning[0].status === 'ACKNOWLEDGED'))}
                onClick={async () => {
                  try {
                    await updateAlarm({ variables: {
                      id: item.alarms.maintenance.length > 0 ? item.alarms.maintenance[0].id : item.alarms.warning[0].id,
                      status: "ACKNOWLEDGED",
                      compressorId: item.id,
                      customerId: item.parentId
                    }
                  });
                  } catch (e) {
                    console.log(e.message);
                  }
                }}
              />
              <Dropdown.Item
                text='Clear...'
                onClick={this.handleOpenModal}
              />
            </Dropdown.Menu>
          </Dropdown>
          {this.state.openModal &&
            <CompressorSetNewInterval
              updateAlarm={updateAlarm}
              compressor={item}
              onClose={this.handleCloseModal}
              open={this.state.openModal}
              role={currentUser.role}
              loading={loading}
              error={error}
            />}
        </Fragment>}
      }
      </Mutation>
    ):(item.currentValue === null
      ? <Label
          color='red'
          style={alignRight
            ? {float: 'right', marginRight: '1rem', width: '100%', maxWidth: "150px", textAlign: 'center'}
            : {width: '100%', maxWidth: "150px", textAlign: 'center'  }}
        >
          value not found
        </Label>
      : <Label
          color='green'
          style={alignRight
            ? {float: 'right', marginRight: '1rem', width: '100%', maxWidth: "150px", textAlign: 'center'}
            : {width: '100%', maxWidth: "150px", textAlign: 'center'  }}
        >
          {`[${item.currentValue}] ${item.lastWeekHours ? forecastDate(item.maintenanceInterval - item.currentValue, item.lastWeekHours / 7).format('YYYY/MM/DD') : 'no data'}`}
        </Label>)
    );
  }
}

export default CompressorMaintenance;
