import React from 'react';
import { Mutation } from 'react-apollo';
import { Confirm } from 'semantic-ui-react';
import RemainingHoursModal from '../../forms/remainingHours';
import { DELETE_REMAINING_HOURS, ADD_REMAINING_HOURS, UPDATE_REMAINING_HOURS } from '../../../graphql/mutations';
import { updateQuery } from '../../../utils';

const newRemainingHoursData = {
  name: '',
  gatewayId: '',
  params: [],
  enabled: true
};

export const DeleteRemainingHours = ({
  remainingHours,
  customer,
  deleteConfirm,
  closeDeleteRemainingHours,
  refetch
}) =>
  <Mutation
    mutation={DELETE_REMAINING_HOURS}
    refetchQueries={[{ query: refetch, variables: { id: customer.id } }]}
    update={(cache, { data }) => { // use optimisticResponse data for cache updating
        const cachedData = cache.readQuery({ query: refetch,  variables: { id: customer.id } });
        cache.writeQuery({
          query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
                remainingHours: cachedData.customerChildren.remainingHours.map(remainingHours => remainingHours.id === data.response ? { ...remainingHours, id: 'TEMP_ID' } : remainingHours)
            }
          }
        });
    }}
  >
    {(deleteRemainingHours, { loading, error } ) =>
      <Confirm
        open={deleteConfirm}
        content={`Delete remaining hours device ${remainingHours.name} (${remainingHours.id}). Are you sure?`}
        onCancel={closeDeleteRemainingHours}
        onConfirm={async () => {
          try {
            closeDeleteRemainingHours();
            await deleteRemainingHours({ variables: { id:  remainingHours.id, customerId: remainingHours.parentId }, optimisticResponse: { response: remainingHours.id } });
          } catch (e) {
            console.log(e.message);
          }
        }}
      />
    }
  </Mutation>;

export const MutateRemainingHours = ({
  remainingHours,
  customer,
  customers,
  gateways,
  edit,
  remainingHoursOpen,
  closeRemainingHoursModal,
  displayModal,
  role,
  refetch,
}) =>
  <Mutation
    mutation={edit ? UPDATE_REMAINING_HOURS : ADD_REMAINING_HOURS}
    refetchQueries={edit ? [] : [{ query: refetch, variables: { id: customer.id } }]}
    update={(cache, { data }) => { // use optimisticResponse data for cache updating
      if(!edit) {
        const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
        cache.writeQuery({ query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
              remainingHours: [...cachedData.customerChildren.remainingHours, data.response]
            }
          }
        });
      } else if(data.__typename !== "Mutation" && customer.id !== data.response.parentId) {
        const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
        cache.writeQuery({ query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
                remainingHours: cachedData.customerChildren.remainingHours.filter(remainingHours => remainingHours.id !== data.response.id)
            }
          }
        });
      }
    }}
  >
  {(mutateRemainingHours, { loading, error } ) =>
    <RemainingHoursModal
      open={remainingHoursOpen}
      onClose={closeRemainingHoursModal}
      customer={customer}
      customers={customers}
      gateways={gateways}
      remainingHours={edit ? remainingHours : { ...newRemainingHoursData, parentId: customer.id }}
      edit={edit}
      mutateDevice={mutateRemainingHours}
      displayModal={displayModal}
      loading={loading}
      role={role}
      error={error}
    />}
  </Mutation>;

  export const TableDeleteRemainingHours = ({
      remainingHours,
      customer,
      deleteConfirm,
      closeDeleteRemainingHours,
      fetchMore,
      variables,
    }) =>
      <Mutation
        mutation={DELETE_REMAINING_HOURS}
        update={(cache, { data }) => {
          if(data.___typename !== "Mutation") {
            fetchMore({variables, updateQuery});
          }
        }}
      >
        {(deleteRemainingHours, { loading, error } ) =>
          <Confirm
            open={deleteConfirm}
            content={`Delete remaining hours device ${remainingHours.name} (${remainingHours.id}). Are you sure?`}
            onCancel={closeDeleteRemainingHours}
            onConfirm={async () => {
              try {
                closeDeleteRemainingHours();
                await deleteRemainingHours({ variables: { id:  remainingHours.id, customerId: remainingHours.parentId }, optimisticResponse: { response: remainingHours.id, ___typename: "Mutation" } });
              } catch (e) {
                console.log(e.message);
              }
            }}
          />
        }
      </Mutation>;

export const TableMutateRemainingHours = ({
        remainingHours,
        customer,
        customers,
        gateways,
        edit,
        remainingHoursOpen,
        closeRemainingHoursModal,
        displayModal,
        fetchMore,
        role,
        variables,
      }) =>
        <Mutation
          mutation={edit ? UPDATE_REMAINING_HOURS : ADD_REMAINING_HOURS}
          update={(cache, { data }) => {
            if(data.__typename !== "Mutation") {
              if(!edit) {
                fetchMore({variables, updateQuery});
              } else if(customer.id !== data.response.parentId) {
                fetchMore({variables, updateQuery});
              }              
            }
          }}
        >
        {(mutateRemainingHours, { loading, error } ) =>
          <RemainingHoursModal
            open={remainingHoursOpen}
            onClose={closeRemainingHoursModal}
            customer={customer}
            customers={customers}
            gateways={gateways}
            remainingHours={edit ? remainingHours : { ...newRemainingHoursData, parentId: customer.id }}
            edit={edit}
            mutateDevice={mutateRemainingHours}
            displayModal={displayModal}
            loading={loading}
            role={role}
            error={error}
          />}
        </Mutation>;
