import React, { Component } from 'react'
import { Container, Button, Message, Icon } from 'semantic-ui-react'
import api from '../api';
import DataRestore from '../components/mutations/data';

class Data extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadInProgress: false,
    }
  }
  handleBackup = async () => {
    try {
      this.setState({ downloadInProgress: true });
      const result = await api.backup();
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(new Blob([result.data]));
      link.setAttribute('type', 'hidden');
      link.setAttribute('download', 'backup.json');
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } catch (e) {
      console.log(e.message);
    } finally {
      this.setState({ downloadInProgress: false });
    }
  }

  render() {
      const { downloadInProgress } = this.state;
      return (
        <Container>
          <Message info>
          <Message.Header>Backup</Message.Header>
            <div style={{marginBottom: '1rem'}}>
              Download kaeser-application data backup
              <Button floated='right' icon loading={downloadInProgress} disabled={downloadInProgress} onClick={this.handleBackup}>
                <Icon name='download' />
                Download
              </Button>
            </div>
          </Message>
          <Message info>
          <Message.Header>Restore</Message.Header>
            <div style={{marginBottom: '1rem'}}>
              Choose data file to restore
              <DataRestore />
            </div>
          </Message>
        </Container>
      );
  }
};
export default Data;
