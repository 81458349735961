import React from 'react';
import { Mutation } from 'react-apollo';
import { Confirm } from 'semantic-ui-react';
import UserModal from '../../forms/user';
import { DELETE_USER, ADD_USER, UPDATE_USER } from '../../../graphql/mutations';
import { USERS } from '../../../graphql/queries';

export const DeleteUser = ({
  user,
  deleteConfirm,
  closeDeleteUser,
}) =>
  <Mutation
    mutation={DELETE_USER}
    refetchQueries={[{query: USERS}]}
  >
  {(deleteUser, { loading, error } ) =>
    <Confirm
      open={deleteConfirm}
      content={`Delete user ${user.name} (${user.email}). Are you sure?`}
      cancelButton={loading ? null : 'Cancel'}
      confirmButton={loading ? null : 'Ok'}
      onCancel={closeDeleteUser}
      onConfirm={async () => {
        try {
          closeDeleteUser();
          await deleteUser({ variables: { id: user.id } });
        } catch (e) {
          console.log(e.message);
        }
      }}
    />
  }
  </Mutation>;

export const MutateUser = ({
  user,
  edit,
  modalOpen,
  closeUserModal,
  role
}) =>
  <Mutation
    mutation={edit ? UPDATE_USER : ADD_USER}
    refetchQueries={edit ? [] : [{ query: USERS }]}
  >
  {(mutateUser, { loading, error } ) =>
    <UserModal
      open={modalOpen}
      onClose={closeUserModal}
      user={user || {
        name: '',
        email: '',
        emailVerified: false,
        password: '',
        confirmPassword: '',
        enabled: true,
        role: 1,
      }}
      edit={edit}
      role={role}
      loading={loading}
      error={error}
      mutateUser={mutateUser}
    />
  }
  </Mutation>;
