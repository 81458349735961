import React, { Component, Fragment } from 'react';
import { Icon, Segment, Header, Divider, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import { EmailAlarm, EmailAlarms } from '../../alarms/emailAlarm';

class EmailAlarmsList extends Component {
  state = {
    selected: []
  }

  handleChangeAll = (e, { checked }) => {
    this.setState({
      selected: checked ? this.props.data.map(alarm => alarm.id) : [] 
    });
  }
  
  handleChange = (id, e, { checked }) => {
    const { selected } = this.state;
    this.setState({
      selected: checked ? [ ...selected, id ] : selected.filter(selectedAlarm => selectedAlarm !== id)
    });
  }
  
  getVisibleSelected = () => {
    return this.state.selected.filter(selectedAlarm => this.props.data.find(alarm => alarm.id === selectedAlarm));
  }
  
  render() {
    const { currentUser, customer } = this.props;
    const { selected } = this.state;
    return this.props.data && this.props.data.length
            ?  <Fragment>
                    <Divider horizontal>
                      <Header as='h5'>
                        <Icon name='alarm' />
                        Email alarms
                      </Header>
                    </Divider>
                <Segment.Group>
                <Segment style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                  <Checkbox
                    style={{ marginRight: '1rem' }}
                    checked={this.getVisibleSelected().length === this.props.data.length}
                    onChange={this.handleChangeAll}
                  />
                  <EmailAlarms
                    customer={customer}
                    currentUser={currentUser}
                    ids={this.getVisibleSelected()}
                    alarms={this.props.data}
                  />
                </Segment>
                {this.props.data.map((alarm, i) =>
                  <Segment key={alarm.id} style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        style={{ marginRight: '1rem' }}
                        checked={Boolean(selected.find(selectedAlarm => selectedAlarm === alarm.id))}
                        onChange={this.handleChange.bind(null, alarm.id)}
                      />
                      <div style={{ width: '100%' }}>
                        <Header as='h5' style={{ display: 'inline-flex', width: '80%', marginBottom: 0, justifyContent: 'space-between' }}>
                          {alarm.text}
                        </Header>
                        <EmailAlarm
                          customer={customer}
                          currentUser={currentUser}
                          item={alarm}
                          alignRight
                        />
                        <p><small>{`[Received: ${moment(alarm.timestamp).utc()}]`}</small></p>
                      </div>
                  </Segment>)}
                </Segment.Group>
              </Fragment>
            : null
  }
}

export default EmailAlarmsList;
