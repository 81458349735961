import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { LoadScript } from "@react-google-maps/api";
import services from "./services";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "./containers/errorBoundary";
import cfg from "./config";

const client = services.client;

ReactDOM.render(
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <LoadScript googleMapsApiKey={cfg.GOOGLE_MAPS_API_KEY}>
          <App />
        </LoadScript>
      </BrowserRouter>
    </ApolloProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
