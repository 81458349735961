import React, { Component, Fragment }  from "react";
import { Formik, Field, Form as FormikForm } from 'formik';
import {object as yupObject, string as yupString, boolean as yupBoolean } from 'yup';
import { Button, Form, Icon, Container, Modal, Message, Tab } from 'semantic-ui-react';
import moment from 'moment';

import Map from '../../components/map';
import { UpdateGatewayMessage } from '../mutations/gateway';
import FormField from './formField';
import { getSorting } from '../../utils';

const GatewayFormVS = yupObject().shape({
  edit: yupBoolean(),
  name: yupString()
    .required('name is required'),
  parentId: yupString()
    .required(),
  TypePullProtocol: yupString()
    .required('TypePullProtocol is required'),
  URL_IP_Host: yupString()
      .required('URL_IP_Host number is required'),
  login: yupString(),
  password: yupString(),
  enabled: yupBoolean().required(),
});

const GatewayModal = ({
  gateway,
  customer,
  edit,
  ...props
}) =>
  <Formik
  	initialValues={{
      ...gateway,
      location: { lat: gateway.location ? gateway.location.lat : '', lon: gateway.location ? gateway.location.lon : '' },
      edit
    }}
  	validationSchema={GatewayFormVS}
    enableReinitialize
  	onSubmit={async (values, {setStatus, setSubmitting}) => {
        const { name, TypePullProtocol, URL_IP_Host, enabled, login, password, parentId, location } = values;
        const data = {
          name,
          parentId,
          TypePullProtocol,
          URL_IP_Host,
          login,
          password,
          enabled,
          ...((location.lat !== '' && location.lon !== '') && { location } )
        };
        try {
          props.mutateGateway({
            variables: { gateway: data, ...(edit && { id: gateway.id }) },
            optimisticResponse: {
              __typename: "Mutation",
              response: edit
                ? {
                  ...data,
                  id: gateway.id,
                  __typename: "Gateway",
                  alarms: gateway.alarms,
                  location: gateway.location,
                  lastMessage: gateway.lastMessage
                } : {
                  ...data,
                  alarms: {
                    malfunction: [],
                    warning: [],
                    maintenance: [],
                    __typename: "Alarms"
                  },
                  location: {
                    lat: 0,
                    lon: 0,
                    __typename: "Loc"
                  },
                  lastMessage: [],
                  __typename: "Gateway",
                  id: 'TEMP_ID'
                }
            }
          });
          props.onClose();
        } catch (e) {
          console.log(e);
        }
    	}
    }
    render={renderProps => <Gateway
      gateway={gateway}
      customer={customer}
      lastMessage={gateway.lastMessage}
      edit={edit}
      {...props}
      {...renderProps}
    />}
  />

class Gateway extends Component {
  state = {
    activeIndex: 0
  }
  
  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex })
  
  render() {
    const {
      edit,
      errors,
      touched,
      onClose,
      loading,
      error,
      isValid,
      values,
      open,
      lastMessage,
      customer,
      customers,
    } = this.props;
    const panes = [
      { menuItem: 'Settings', render: () => (
        <Tab.Pane>
            <FormField
              className="required"
              name="name"
              label="Name"
              placeholder="Name"
              errors={errors}
              touched={touched}
              Component={Form.Input}
            />
            {edit &&
            <Field
              name="parentId"
              render={({field, form}) => (
                <Fragment>
                <Form.Select
                  className="required"
                  name={field.name}
                  label='Customer'
                  placeholder="Select customer"
                  value={field.value}
                  options={customers
                    .sort(getSorting('ascending', 'name'))
                    .map(customer => ({
                    value: customer.id,
                    text: customer.name
                  }))}
                  error={errors[field.name] && touched[field.name]}
                  onChange={(e, selected) => {
                    form.setFieldValue(field.name, selected.value);
                  }}
                />
                {errors[field.name] && touched[field.name] &&
                  <Message
                    error
                    header='error'
                    content={errors[field.name]}
                  />
                }
                </Fragment>
              )}
            />}
            <Field
              name="TypePullProtocol"
              render={({field, form}) => (
                <Fragment>
                <Form.Select
                  className="required"
                  name={field.name}
                  label='Protocol Type'
                  placeholder="Select protocol"
                  value={field.value}
                  options={['SAM1', 'SAM2', 'SAM4.0', 'SAM4.0_v2.0', 'SmartFlex'].map(type => ({
                    value: type,
                    text: type
                  }))}
                  error={errors[field.name] && touched[field.name]}
                  onChange={(e, selected) => {
                    form.setFieldValue(field.name, selected.value);
                  }}
                />
                {errors[field.name] && touched[field.name] &&
                  <Message
                    error
                    header='error'
                    content={errors[field.name]}
                  />
                }
                </Fragment>
              )}
            />
            <FormField
              className="required"
              name="URL_IP_Host"
              label="Host url or IP address"
              placeholder="url"
              errors={errors}
              touched={touched}
              Component={Form.Input}
            />
            <FormField
              name="login"
              label="login"
              placeholder="login"
              errors={errors}
              touched={touched}
              Component={Form.Input}
            />
            <FormField
              name="password"
              label="Password"
              placeholder="Password"
              type="password"
              errors={errors}
              touched={touched}
              Component={Form.Input}
            />
            <FormField
              name="enabled"
              label="Gateway enabled"
              errors={errors}
              touched={touched}
              Component={Form.Checkbox}
            />
            {error &&
            <Message
              error
              header='Server error'
              content={error.message}
            />}
          </Tab.Pane>
    )},
    { menuItem: 'Location', render: () => (
      <Container textAlign='right' >
        <Tab.Pane textAlign='left'>
          <Form.Group widths='equal'>
            <FormField
              fluid
              name="location.lat"
              type="number"
              label="latitude:"
              placeholder="latitude"
              errors={errors}
              touched={touched}
              Component={Form.Input}
            />
            <FormField
              fluid
              name="location.lon"
              type="number"
              label="longitude:"
              placeholder="longitude"
              errors={errors}
              touched={touched}
              Component={Form.Input}
            />
          </Form.Group>
          <Map
            lat={values.location.lat === '' ? null : values.location.lat}
            lon={values.location.lon === '' ? null : values.location.lon}
          />
        </Tab.Pane>
      </Container>
    )},
    {
      menuItem: 'LastMessage', render: () => (
      values.id
      ?
      <Container textAlign='right'>
        <Tab.Pane textAlign='left'>
          {lastMessage.find(entry => entry.key === 'timestamp') &&
            <p>{moment(lastMessage.find(entry => entry.key === 'timestamp').value).toString()}</p>}
          {lastMessage.length
            ? <pre>
              {JSON.stringify(lastMessage.map(entry => ({
                key: entry.key,
                value: entry.value,
              }))
              .filter(entry => entry.key !== 'timestamp')
              .reduce((acc, entry) => (
                  {...acc, [entry.key]: entry.value}
              ), {}), null, 2)}
              </pre>
            : <Message>No messages</Message>
            }
        </Tab.Pane>
      </Container>
      : <Message>Create gateway first</Message>
    )}
  ];
    return (
      <Modal
        open={open}
        onClose={onClose}
        size='tiny'
        closeOnDocumentClick
      >
        <Modal.Header>{values.edit ? `Edit gateway (customer: ${customer.name})` : `Add gateway (customer: ${customer.name})`}</Modal.Header>
        <Modal.Content>
          <FormikForm className="ui form error">
            <Tab
              menu={{ secondary: true, pointing: true }}
              panes={edit ? panes : panes.filter((pane, i) => i !== 3)}
              activeIndex={this.state.activeIndex}
              style={{ minHeight: '500px' }}
              onTabChange={this.handleTabChange}
            />
            <Container textAlign='right' style={{ marginTop: '1rem' }}>
              {panes[this.state.activeIndex].menuItem === 'LastMessage'
                ? <UpdateGatewayMessage
                    gatewayId={values.id}
                  />
                : null}
              <Button
                primary type='submit'
                icon='checkmark'
                content='Save'
                role='button'
                disabled={loading || !isValid}
                loading={loading}
              />
              <Button secondary basic onClick={onClose}>
                <Icon name='remove' /> Cancel
              </Button>
            </Container>
          </FormikForm>
        </Modal.Content>
      </Modal>
    );
  }
}

export default GatewayModal;
