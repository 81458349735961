import React, { Component } from 'react';
import { GoogleMap } from '@react-google-maps/api'
import { Message } from 'semantic-ui-react';
import MarkerWithLabel from '../components/map/markerWithLabel';
import _ from 'lodash';

class Map extends Component {
  state = {
    labelsVisible: [],
    mapRef: null,
  }

  setMapRef = mapRef => {
    this.setState({ mapRef });
  }

  handleToggleLable = (id, marker, e) => {
    this.setState({
      labelsVisible: this.state.labelsVisible.find(labelVisible => labelVisible === id)
        ? this.state.labelsVisible.filter(labelVisible => labelVisible !== id)
        : [...this.state.labelsVisible, id]
    });
  }
  
  handleLableClick = (marker, e) => {
    this.props.onClick(marker.gateway, { name: marker.customer });
  }

  handleZoomChange = () => {
    if(this.state.mapRef) {
      const { zoom } = this.props;
      const newZoom = this.state.mapRef.getZoom();
      if(newZoom !== zoom) {
        this.props.handleZoomChange(newZoom);
      }
    }     
  }
  
  handleCenterChanged = () => {
    if(this.state.mapRef) {
      const { lat, lng } = this.props.center;
      const newCenter = this.state.mapRef.getCenter().toJSON();
      if(newCenter.lat !== lat || newCenter.lng !== lng) {
        this.props.handleCenterChange(newCenter);
      }
    } 
  }

  render() {
    const { props } = this;
    
    return (
        <GoogleMap
          mapContainerStyle={{
              height: '800px',
          }}
          zoom={props.zoom}
          options={{ streetViewControl: false }}
          center={props.center}
          onLoad={this.setMapRef}
          onCenterChanged={_.debounce(this.handleCenterChanged, 200)}
          onZoomChanged={_.debounce(this.handleZoomChange, 200)}
        >
          {props.markers.map((marker, i) =>
            <MarkerWithLabel
              key={i}
              position={{ lat: marker.gateway.location.lat, lng: marker.gateway.location.lon }}
              markerOnClick={this.handleToggleLable.bind(null, marker.gateway.id, marker)}
              labelVisible={Boolean(this.state.labelsVisible.find(labelVisible => labelVisible === marker.gateway.id))}
            >
              <div
                style={{ width: '100px', cursor: 'pointer' }}
                onClick={this.handleLableClick.bind(null, marker)}
              >
                <Message
                  info
                >
                  <Message.Header>{marker.customer}</Message.Header>
                  {marker.gateway.name}
                 </Message>
              </div>
            </MarkerWithLabel>
          )}
        </GoogleMap>
    )
  }
};

export default Map;
