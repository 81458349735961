import React, { Component, Fragment } from 'react';
import { Icon, Segment, Message, Popup, Header, Label, Button, Divider, Loader } from 'semantic-ui-react';
import { DeleteChild, MutateChild } from '../mutations/child';
import moment from 'moment';

import { getSorting } from '../../utils';

const initModals = {
  deleteConfirm: false,
  childOpen: false,
  childMounted: false,
  edit: false,
  child : null,
};

class ChildrenList extends Component {
    state = initModals;

    openAddChild = () => {
      this.setState({
        childOpen: true,
        childMounted: true,
      })
    }

    openEditChild = (child, customer, compressors) => {
      this.setState({
        childOpen: true,
        childMounted: true,
        edit: true,
        child: {
          ...child,
          notes: child.notes || '',
        },
        customer,
        compressors
      });
    }

    openDeleteChild = (child, customer) => {
      this.setState({
        deleteConfirm: true,
        child: {
          id: child.id,
          name: child.name,
        },
        customer
      })
    }

    displayModal = (state) => this.setState({ childOpen: state });

    closeModal = () => {
      this.setState(initModals);
    }

    render() {
    const { customer, customers, compressors, refetch, currentUser } = this.props;

    return <Fragment>
            <Divider horizontal>
              <Header as='h5'>
                <Icon name='wrench' />
                Child devices
              </Header>
            </Divider>
            <Segment.Group>
            {this.props.data && this.props.data.length
            ?  this.props.data
            .sort(getSorting('ascending', 'name'))
            .map((child, i) => {
              const compressor = compressors.find(compresssor => compresssor.id === child.compressor);
              return (
                <Segment key={i}>
                  <Header as='h5' style={{display: 'inline-flex', width: '75%', marginBottom: '0', color: child.id === 'TEMP_ID' ? 'lightgrey' : ''}}>
                    <div
                      style={{width: '50%', cursor: (currentUser.role === 4 || currentUser.role === 3) ? 'pointer' : 'inherit'}}
                      onClick={() => (currentUser.role === 4 || currentUser.role === 3) ? this.openEditChild(child) : {}}
                    >
                      {child.name}
                    </div>
                    <Label style={{width: '40%'}} color={compressor ? null : 'red'}>
                      compressor:
                      <Label.Detail>
                        {compressor ? compressor.name : 'not found'}
                      </Label.Detail>
                    </Label>
                  </Header>
                  <Loader active={child.id === 'TEMP_ID'} />
                  {(currentUser.role === 4 || currentUser.role === 3)
                  ? <Fragment>
                      <Popup
                        trigger={<Icon link name='trash alternate outline' color='red' style={{float: 'right'}} onClick={() => this.openDeleteChild(child)} />}
                        content='delete child'
                        size='mini'
                      />
                      <Popup
                        trigger={<Icon link name='edit outline' color='blue' style={{float: 'right'}} onClick={() => this.openEditChild(child)} />}
                        content='edit child'
                        size='mini'
                      />
                    </Fragment>
                  : null}
                  <Fragment>
                    <Label
                      color={child.alarms.warning.length
                        ? (child.alarms.warning[0].status === 'ACKNOWLEDGED' ? 'grey' : 'yellow')
                        : (child.alarms.maintenance.length
                          ? (child.alarms.maintenance[0].status === 'ACKNOWLEDGED' ? 'grey' : 'red')
                          : 'green')}
                      style={{float: 'right', marginRight: '1rem', minWidth: '150px', textAlign: 'center',}}
                    >
                      <span>
                        {Boolean(+moment() > +moment(child.lastResetDatetime).add(11, 'month')) ? <Icon name='clock outline' /> : null}
                        {child.currentValue}
                      </span>
                    </Label>
                  </Fragment>
                </Segment>)})
            : <Message color='yellow'>No children found</Message>}
            </Segment.Group>
            {(currentUser.role === 4 || currentUser.role === 3)
             ? <Button icon labelPosition='right' primary size='small' onClick={() => this.openAddChild()}>
                 <Icon name='wrench' /> Add New Child Device
               </Button>
             : null}
            {this.state.child &&
              <DeleteChild
                child={this.state.child}
                customer={customer}
                deleteConfirm={this.state.deleteConfirm}
                closeDeleteChild={this.closeModal}
                refetch={refetch}
              />}
            {this.state.childMounted &&
              <MutateChild
                child={this.state.child}
                customer={customer}
                customers={customers}
                compressors={compressors}
                edit={this.state.edit}
                childOpen={this.state.childOpen}
                displayModal={this.displayModal}
                closeChildModal={this.closeModal}
                refetch={refetch}
              />}
            </Fragment>
  }
}

export default ChildrenList;
