import React, { Component, Fragment }  from "react";
import { Formik, Field, Form as FormikForm } from 'formik';
import {object as yupObject, string as yupString, boolean as yupBoolean } from 'yup';
import { Button, Form, Icon, Container, Modal, Message } from 'semantic-ui-react'

import FormField from './formField';
import { getSorting } from '../../utils';

const ChildFormVS = yupObject().shape({
  edit: yupBoolean(),
  name: yupString()
    .required('name is required'),
  parentId: yupString()
    .required(),
  notes: yupString(),
  compressor: yupString().required('compressorId is required'),
  enabled: yupBoolean().required()
});

const ChildModal = ({
  child,
  customer,
  edit,
  ...props
}) =>
  <Formik
  	initialValues={{ ...child, edit }}
  	validationSchema={ChildFormVS}
    enableReinitialize
    isInitialValid={props => {
      ChildFormVS.isValid(props.initialValues)
      .then(valid => valid)
    }}
  	onSubmit={async (values, {setStatus, setSubmitting}) => {
        const { name, parentId, compressor, notes, enabled } = values;
        const data = {
          name,
          parentId,
          compressor,
          notes,
          enabled
        };
        const selectedCompressor = props.compressors.find(compressor => compressor.id === values.compressor);
        try {
          props.mutateDevice({
            variables: { child: data, ...(edit && { id: child.id }) },
            optimisticResponse: {
              __typename: "Mutation",
              response: edit
                ? {
                   ...data,
                   id: child.id,
                   __typename: "Child",
                   currentValue: selectedCompressor.currentValue,
                   maintenanceInterval: selectedCompressor.maintenanceInterval,
                   lastResetDatetime: selectedCompressor.lastResetDatetime || 0,
                   lastWeekHours: selectedCompressor.lastWeekHours || 0,
                   alarms: selectedCompressor.alarms,
                 }
                : {
                  ...data,
                  __typename: "Child",
                  id: 'TEMP_ID',
                  currentValue: 0,
                  maintenanceInterval: 0,
                  lastResetDatetime: 0,
                  lastWeekHours: 0,
                  alarms: {
                    malfunction: [],
                    warning: [],
                    maintenance: [],
                    __typename: "Alarms"
                  },
                }
            }
          });
          props.onClose();
        } catch (e) {
          console.log(e.message);
        }
    	}
    }
    render={renderProps => <Child
      customer={customer}
      {...props}
      {...renderProps}
    />}
  />

class Child extends Component {
  render() {
    const {
      errors,
      touched,
      values,
      open,
      onClose,
      loading,
      error,
      customer,
      isValid,
      customers,
    } = this.props;
    return (
      <Modal
        open={open}
        onClose={onClose}
        size='tiny'
        closeOnDocumentClick
      >
        <Modal.Header>{values.edit ? `Edit child device (customer: ${customer.name})` : `Add child device (customer: ${customer.name})`}</Modal.Header>
        <Modal.Content>
          <FormikForm className="ui form error">
            <FormField
              name="name"
              label="Name"
              placeholder="Name"
              errors={errors}
              touched={touched}
              Component={Form.Input}
            />
            {values.edit &&
            <Field
              name="parentId"
              render={({field, form}) => (
                <Fragment>
                <Form.Select
                  className="required"
                  name={field.name}
                  label='Customer'
                  placeholder="Select customer"
                  value={field.value}
                  options={customers
                    .sort(getSorting('ascending', 'name'))
                    .map(customer => ({
                    value: customer.id,
                    text: customer.name
                  }))}
                  error={errors[field.name] && touched[field.name]}
                  onChange={(e, selected) => {
                    form.setFieldValue(field.name, selected.value);
                  }}
                />
                {errors[field.name] && touched[field.name] &&
                  <Message
                    error
                    header='error'
                    content={errors[field.name]}
                  />
                }
                </Fragment>
              )}
            />}
            <Field
              className="required"
              name="compressor"
              render={({field, form}) => (
              <Container>
                <Form.Select
                  name={field.name}
                  label='Parent running hours device'
                  placeholder="Select Parent running hours device"
                  value={field.value}
                  options={this.props.compressors
                    .sort(getSorting('ascending', 'name'))
                    .map(compressor => ({
                    value: compressor.id,
                    text: compressor.name
                  }))}
                  error={errors[field.name] && touched[field.name]}
                  onChange={(e, selected) => {
                    form.setFieldValue(field.name, selected.value);
                  }}
                />
                {errors[field.name] && touched[field.name] &&
                  <Message
                    error
                    header='error'
                    content={errors[field.name]}
                  />
                }
                </Container>
              )}
            />
            <FormField
              name="notes"
              label="Notes"
              placeholder="Notes"
              errors={errors}
              touched={touched}
              Component={Form.TextArea}
            />
            <FormField
              className="required"
              name="enabled"
              label="Child device enabled"
              errors={errors}
              touched={touched}
              Component={Form.Checkbox}
            />
            {error &&
            <Message
              error
              header='Server error'
              content={error.message}
            />}
            <Container textAlign='right'>
              <Button primary type='submit' role='button' disabled={loading || !isValid} loading={loading}>
                <Icon name='checkmark' /> Save
              </Button>
              <Button secondary basic onClick={onClose}>
                <Icon name='remove' /> Cancel
              </Button>
            </Container>
          </FormikForm>
        </Modal.Content>
      </Modal>
    );
  }
}

export default ChildModal;
