import React, { Component }  from "react";
import { Button, Icon, Modal, Loader, Dimmer, Label, Dropdown } from 'semantic-ui-react'
import { Scatter } from 'react-chartjs-2';
import moment from 'moment';
import DatePicker from "react-datepicker";

class DataChart extends Component {
  constructor(props) {
    super(props);

    const window_ms = +moment.duration(props.defaultWindow, 'days');

    this.state = {
        scale: 1, //  default
        from: +moment().subtract(window_ms),
        until: +moment(),
        window: window_ms,
        yStart: null,
    };
  }
  
  stepBack = (k) => {
    const { from, until, window } = this.state;
    
    this.setState({
      from: +moment(from).subtract(window * k),
      until: +moment(until).subtract(window * k),
    }, () => this.updateChart());
  }
  
  stepForward = (k) => {
    const { from, until, window } = this.state;
    
    this.setState({
      from: Math.min(+moment().subtract(window), +moment(from).add(window * k)),
      until: Math.min(+moment(), +moment(until).add(window * k)),
    }, () => this.updateChart());
  }
  
  updateChart = () => {
    const { refetch, chartParameters } = this.props;

    refetch({
        id: chartParameters.id,
        param: chartParameters.param,
        from: +moment(this.state.from).subtract(2, 'day'),
        until: Math.min(+moment(), +moment(this.state.until).add(2, 'day')),
      })
  }
  
  handleSetFrom = (from) => {
    this.setState({
      from,
      window: this.state.until - from,
    }, () => this.updateChart());
  }
  
  handleSetUntil = (until) => {
    this.setState({
      until,
      window: until - this.state.from,
    }, () => this.updateChart());
  }
  
  handleSelectWindow = (e, { value }) => {
    this.setState({
      from: +moment(this.state.until).subtract(+value, 'days'),
      window: +moment.duration(+value, 'days'),
    }, () => this.updateChart());
  }
  
  setYStart = yStart => {
    this.setState({ yStart });
  }
  
  render() {
    const { open, onClose, data, loading, label, chartParameters } = this.props;
    const { from, until, window } = this.state;

    return (
      <Modal
        open={open}
        onClose={onClose}
        size='large'
        closeOnDocumentClick
      >
        <Modal.Header>
          {label}
        </Modal.Header>
        <Modal.Content style={{ minHeight: '400px' }}>
          {loading && !data
            ? <Dimmer active inverted><Loader /></Dimmer>
            : <Scatter
                data={{
                  labels: data.map(dataPoint => moment(dataPoint.t)),
                  datasets: [{
                    label: "time(hours)",
                    fill: false,
                    borderColor: 'rgba(0, 0, 200, 0.1)',
                    lineTension: 0,
                    showLine: true,
                    data: data.map(dataPoint => ({
                      x: dataPoint.t,
                      y: dataPoint.v
                    }))
                  },
                  {
                    label: 'last reset datetime',
                    radius: 7,
                    hoverRadius: 10,
                    pointStyle: 'triangle',
                    backgroundColor: 'rgba(255, 0, 0, 0.5)',
                    borderColor: 'rgba(255, 0, 0, 0.5)',
                    data: [{ x: +moment(chartParameters.lastResetDatetime), y: this.state.yStart }]
                  }
                ].filter((dataset, i) => (i === 1 && this.state.yStart === null) ? false : true),
                }}
                width={100}
                height={50}
                plugins={[{
                  afterUpdate: chart => {
                    const yStart = chart.scales['y-axis-1'].start;
                    const displayResetDateTime = (chartParameters.lastResetDatetime > chart.scales['x-axis-1'].start)
                                                && (chartParameters.lastResetDatetime < chart.scales['x-axis-1'].end);
                    if(this.state.yStart !== yStart && displayResetDateTime) {
                      this.setYStart(yStart);
                    }
                  }
                }]}
                options={{
                  // animation: false,
                  showLines: true,
                  legend: {
                      display: true,
                  },
                  tooltips: {
                      callbacks: {
                          title: function(tooltipItems, data) {
                            if(tooltipItems[0].datasetIndex === 1) {
                              return moment(+tooltipItems[0].label);
                            } else {
                              return data.labels[tooltipItems[0].index];
                            }
                          },
                          label: function(tooltipItem, data) {
                            if(tooltipItem.datasetIndex === 1) {
                              return 'last reset DateTime';
                            } else {
                              return `time(hours): ${tooltipItem.value}`;
                            }
                          }
                      }
                  },
                  scales: {
                      xAxes: [{
                          ticks: {
                              min: from,
                              max: until,
                              callback: function(value, index, values) {
                                  return moment(value).format('YYYY/MM/DD');
                              }
                          }
                      }],
                  }
                }}
              />}
        </Modal.Content>
        <Modal.Content>
          <Button onClick={() => this.stepBack(1)} icon='fast backward' />
          <Button onClick={() => this.stepBack(0.25)} icon='step backward' />
          <Button onClick={() => this.stepForward(0.25)} icon='step forward' />
          <Button onClick={() => this.stepForward(1)} icon='fast forward' />
          <label> from: </label>
          <DatePicker
            selected={moment(from).toDate()}
            value={moment(from).format('YYYY-MM-DD HH:mm')}
            maxDate={moment(Math.min(+moment(), +moment(until))).toDate()}
            onChange={(from) => this.handleSetFrom(+moment(from))}
            showTimeSelect
          />
          <label> to: </label>
          <DatePicker
            selected={moment(until).toDate()}
            value={moment(until).format('YYYY-MM-DD HH:mm')}
            label="until"
            minDate={moment(from).add(1, 'day').toDate()}
            maxDate={moment().toDate()}
            onChange={(until) => this.handleSetUntil(+moment(until))}
            showTimeSelect
          />
          <Label style={{ float: 'right' }}>
            selected
            <Label.Detail>{moment.duration(window).humanize()}</Label.Detail>
          </Label>
          <Dropdown
            placeholder='Set period'
            style={{ marginLeft: '1rem' }}
            options={[
              { key: '1', value: '1', text: '1 day' },
              { key: '7', value: '7', text: '7 days' },
              { key: '14', value: '14', text: '14 days' },
              { key: '30', value: '30', text: '30 days' }
            ]}
            onChange={this.handleSelectWindow}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button secondary basic onClick={onClose}>
            <Icon name='remove' /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default DataChart;
