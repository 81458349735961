import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "apollo-link-error";
import { withClientState } from "apollo-link-state";
import { ApolloLink, split, Observable } from "apollo-link";
import { WebSocketLink } from "@apollo/link-ws";
import { getMainDefinition } from "apollo-utilities";
import { toast } from "react-toastify";
import { fetch } from "whatwg-fetch";
import defaults from "../graphql/defaults";
import typeDefs from "../graphql/typeDefs";
import config from "../config";
import Session from "../lib";

if (!window.fetch) {
  window.fetch = fetch;
}

const promiseToObservable = promise =>
  new Observable(subscriber => {
    promise.then(
      value => {
        console.log(subscriber);
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      err => subscriber.error(err)
    );
  });

const httpLink = createUploadLink({
  uri: `${config.apiServerUrl}/api`,
  credentials: "include",
  headers: {
    ["apollo-require-preflight"]: "true"
  }
});

const wsLink = new WebSocketLink({
  uri: `${config.wsServerUrl}/sub`,
  options: {
    reconnect: true
  }
});

const subscriptionMiddleware = {
  applyMiddleware: async (options, next) => {
    options.authToken = localStorage.getItem("token");
    next();
  }
};

wsLink.subscriptionClient.use([subscriptionMiddleware]);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: []
    }
  }
});

const requestLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token");
  operation.setContext({
    headers: {
      ...(token && { authorization: `Bearer ${token}` })
    }
  });
  return forward(operation);
});

const errorHandler = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        return toast.error(message);
      });
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      if (
        networkError &&
        networkError.statusCode === 401 &&
        networkError.result.message === "jwt expired"
      ) {
        return promiseToObservable(forward(operation));
        // return promiseToObservable(session.refresh()).flatMap(() =>
        //   forward(operation)
        // );
      }
      const messageText =
        networkError && networkError.result && networkError.result.message
          ? networkError.result.message
          : networkError.name;
      const message =
        networkError.name === "ServerParseError"
          ? networkError.bodyText
          : messageText;
      console.log(`[Network error text]: ${message}`);
      toast.error(messageText);
    }
  }
);

const responseLogger = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    if (!response.errors) {
      // operation.operationName && toast.success(operation.operationName);  // success notifications
      Object.keys(response.data).map(
        k => response.data[k].error && toast.error(response.data[k].error)
      );
      operation.operationName &&
        console.log("success: ", operation.operationName);
    }
    return response;
  });
});

const cache = new InMemoryCache({ fragmentMatcher });

const client = new ApolloClient({
  link: ApolloLink.from([
    responseLogger,
    errorHandler,
    requestLink,
    withClientState({
      defaults,
      typeDefs,
      // resolvers: {}, // required for correct store initialization
      cache
    }),
    link
  ]),
  cache,
  resolvers: {} // fix for: Found @client directives in a query but no ApolloClient resolvers were specified. This means ApolloClient local resolver handling has been disabled, and @client directives will be passed through to your link chain.
});

const session = new Session(client);
session.restore(client);

export default {
  client,
  session
};
