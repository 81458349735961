import React from 'react';
import { Mutation } from 'react-apollo';
import { Confirm } from 'semantic-ui-react';
import ChildModal from '../../forms/child';
import { DELETE_CHILD, ADD_CHILD, UPDATE_CHILD } from '../../../graphql/mutations';
import { updateQuery } from '../../../utils';

const newChildData = {
  name: '',
  compressor: '',
  notes: '',
  enabled: true
};

export const DeleteChild = ({
  child,
  customer,
  deleteConfirm,
  closeDeleteChild,
  refetch,
}) =>
  <Mutation
    mutation={DELETE_CHILD}
    refetchQueries={[{ query: refetch, variables: { id: customer.id } }]}
    update={(cache, { data }) => { // use optimisticResponse data for cache updating
        const cachedData = cache.readQuery({ query: refetch,  variables: { id: customer.id } });
        cache.writeQuery({
          query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
              children: cachedData.customerChildren.children.map(child => child.id === data.response ? { ...child, id: 'TEMP_ID' } : child)
            }
          }
        });
    }}
  >
    {(deleteChild, { loading, error } ) =>
      <Confirm
        open={deleteConfirm}
        content={`Delete child ${child.name} (${child.id}). Are you sure?`}
        onCancel={closeDeleteChild}
        onConfirm={async () => {
          try {
            closeDeleteChild();
            await deleteChild({ variables: { id:  child.id }, optimisticResponse: { response: child.id } });
          } catch (e) {
            console.log(e.message);
          }
        }}
      />
    }
  </Mutation>;

export const MutateChild = ({
  child,
  customer,
  customers,
  compressors,
  edit,
  childOpen,
  closeChildModal,
  displayModal,
  refetch,
}) =>
  <Mutation
    mutation={edit ? UPDATE_CHILD : ADD_CHILD}
    refetchQueries={edit ? [] : [{ query: refetch, variables: { id: customer.id } }]}
    update={(cache, { data }) => { // use optimisticResponse data for cache updating
      if(!edit) {
        const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
        cache.writeQuery({ query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
              children: [...cachedData.customerChildren.children, data.response]
            }
          }
        });
      } else if(data.__typename !== "Mutation" && customer.id !== data.response.parentId) {
        const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
        cache.writeQuery({ query: refetch,
          variables: { id: customer.id },
          data: {
            customerChildren: {
              ...cachedData.customerChildren,
              children: cachedData.customerChildren.children.filter(child => child.id !== data.response.id)
            }
          }
        });
      }
    }}
  >
  {(mutateChild, { loading, error } ) =>
    <ChildModal
      open={childOpen}
      onClose={closeChildModal}
      customer={customer}
      customers={customers}
      compressors={compressors}
      child={edit ? child : { ...newChildData, parentId: customer.id }}
      edit={edit}
      mutateDevice={mutateChild}
      displayModal={displayModal}
      loading={loading}
      error={error}
    />}
  </Mutation>;

export const TableDeleteChild = ({
  child,
  customer,
  deleteConfirm,
  closeDeleteChild,
  fetchMore,
  variables,
}) =>
  <Mutation
    mutation={DELETE_CHILD}
    update={(cache, { data }) => {
      if(data.___typename !== "Mutation") {
        fetchMore({variables, updateQuery});
      }
    }}
  >
    {(deleteChild, { loading, error } ) =>
      <Confirm
        open={deleteConfirm}
        content={`Delete child ${child.name} (${child.id}). Are you sure?`}
        onCancel={closeDeleteChild}
        onConfirm={async () => {
          try {
            closeDeleteChild();
            await deleteChild({ variables: { id:  child.id }, optimisticResponse: { response: child.id } });
          } catch (e) {
            console.log(e.message);
          }
        }}
      />
    }
  </Mutation>;

export const TableMutateChild = ({
  child,
  customer,
  customers,
  compressors,
  edit,
  childOpen,
  closeChildModal,
  displayModal,
  fetchMore,
  variables,
}) =>
  <Mutation
    mutation={edit ? UPDATE_CHILD : ADD_CHILD}
    update={(cache, { data }) => {
      if(data.__typename !== "Mutation") {
        if(!edit) {
          fetchMore({variables, updateQuery});       
        } else if(customer.id !== data.response.parentId) {
          fetchMore({variables, updateQuery});
        }        
      }
    }}
  >
  {(mutateChild, { loading, error } ) =>
    <ChildModal
      open={childOpen}
      onClose={closeChildModal}
      customer={customer}
      customers={customers}
      compressors={compressors}
      child={edit ? child : { ...newChildData, parentId: customer.id }}
      edit={edit}
      mutateDevice={mutateChild}
      displayModal={displayModal}
      loading={loading}
      error={error}
    />}
  </Mutation>;
