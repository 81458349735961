import React, { Component, Fragment }  from "react";
import { Formik, Form as FormikForm } from 'formik';
import {object as yupObject, string as yupString, boolean as yupBoolean, ref as yupRef } from 'yup';
import { Button, Form, Icon, Container, Message } from 'semantic-ui-react';

import FormField from './formField';

const composeData = values => {
  return values.changePassword
  ? {
      name: values.name,
      email: values.email,
      password: values.password,
    }
  : {
      name: values.name,
      email: values.email,
  };
}

const UserProfileVS = yupObject().shape({
  changePassword: yupBoolean(),
  name: yupString()
    .required('name is required'),
  email: yupString()
    .email('Invalid email')
    .required('email is required'),
  password: yupString()
    .when('changePassword', {
      is: true,
      then: yupString()
        .required('password is required'),
      otherwise: yupString()
    }),
  confirmPassword: yupString()
    .when('changePassword', {
      is: true,
      then: yupString()
        .oneOf([yupRef('password', null)], 'Passwords do not match')
        .required('password is required'),
      otherwise: yupString()
    }),
});

const UserProfile = ({
  profile,
  ...props
}) =>
  <Formik
  	initialValues={{...profile, changePassword: false, password: '', confirmPassword: '',}}
  	validationSchema={UserProfileVS}
    enableReinitialize
    isInitialValid={props => {
      UserProfileVS.isValid(props.initialValues)
      .then(valid => valid)
    }}
  	onSubmit={async (values, { setFieldValue }) => {
    		const data = composeData(values);
        try {
          await props.updateProfile({ variables: data });
          setFieldValue('password', '');
          setFieldValue('confirmPassword', '');
          setFieldValue('changePassword', false);
        } catch (e) {
          console.log(e.message);
        }
    	}
    }
    render={renderProps => <User
      {...props}
      {...renderProps}
    />}
  />

class User extends Component {
  render() {
    const {
      errors,
      touched,
      values,
      isValid,
      loading,
      error
    } = this.props;
    return (
        <FormikForm className="ui form error">
          <FormField
            name="name"
            label="Name"
            placeholder="Name"
            disabled={loading}
            errors={errors}
            touched={touched}
            Component={Form.Input}
          />
          <FormField
            name="email"
            label="Email"
            placeholder="Email"
            disabled={loading}
            type='email'
            errors={errors}
            touched={touched}
            Component={Form.Input}
          />
          <FormField
            name="changePassword"
            label="Change password"
            disabled={loading}
            errors={errors}
            touched={touched}
            Component={Form.Checkbox}
          />
          {(values.changePassword) &&
          <Fragment>
            <FormField
              name="password"
              label='Password'
              type='password'
              placeholder='Password'
              disabled={loading}
              errors={errors}
              touched={touched}
              Component={Form.Input}
            />
            <FormField
              name="confirmPassword"
              label='Confirm password'
              type='password'
              placeholder='Confirm password'
              disabled={loading}
              errors={errors}
              touched={touched}
              Component={Form.Input}
            />
          </Fragment>}
          {error &&
          <Message
            error
            header='Server error'
            content={error.message}
          />}
          <Container textAlign='right'>
            <Button primary type='submit' role='button' disabled={!isValid || loading} loading={loading}>
              <Icon name='checkmark' /> Update
            </Button>
          </Container>
        </FormikForm>
    );
  }
}

export default UserProfile;
