import React, { Component, Fragment } from "react";
import {
  Icon,
  Segment,
  Message,
  Popup,
  Header,
  Divider,
  Button,
  Loader,
  Label
} from "semantic-ui-react";
import { DeleteCalendar, MutateCalendar } from "../mutations/calendar";
import CalendarMaintenance from "../alarms/calendarMaintenance";
import moment from "moment";

import { getSorting } from "../../utils";

const initModals = {
  deleteConfirm: false,
  calendarOpen: false,
  calendarMounted: false,
  edit: false,
  calendar: null
};

class CalendarsList extends Component {
  state = initModals;

  openAddCalendar = () => {
    this.setState({
      calendarOpen: true,
      calendarMounted: true
    });
  };

  openEditCalendar = calendar => {
    this.setState({
      calendarOpen: true,
      calendarMounted: true,
      edit: true,
      calendar
    });
  };

  openDeleteCalendar = calendar => {
    this.setState({
      deleteConfirm: true,
      calendar
    });
  };

  displayModal = state => this.setState({ calendarOpen: state });

  closeModal = () => {
    this.setState(initModals);
  };

  render() {
    const { customer, customers, refetch, currentUser } = this.props;

    return (
      <Fragment>
        <Divider horizontal>
          <Header as="h5">
            <Icon name="calendar alternate outline" />
            Calendar devices
          </Header>
        </Divider>
        <Segment.Group>
          {this.props.data && this.props.data.length ? (
            this.props.data
              .sort(getSorting("ascending", "name"))
              .map((calendar, i) => (
                <Segment key={i}>
                  <Header
                    as="h5"
                    style={{
                      display: "inline-flex",
                      width: "75%",
                      marginBottom: "0",
                      color: calendar.id === "TEMP_ID" ? "lightgrey" : ""
                    }}
                  >
                    <div
                      style={{
                        width: "40%",
                        cursor:
                          currentUser.role === 4 || currentUser.role === 3
                            ? "pointer"
                            : "inherit"
                      }}
                      onClick={() =>
                        currentUser.role === 4 || currentUser.role === 3
                          ? this.openEditCalendar(calendar)
                          : {}
                      }
                    >
                      {calendar.name}
                    </div>
                    <Label style={{ width: "25%" }}>
                      warning:
                      <Label.Detail>
                        {moment(calendar.maintenanceWarningDateTime).format(
                          "YYYY/MM/DD"
                        )}
                      </Label.Detail>
                    </Label>
                    <Label style={{ width: "25%" }}>
                      maintenance:
                      <Label.Detail>
                        {moment(calendar.maintenanceDateTime).format(
                          "YYYY/MM/DD"
                        )}
                      </Label.Detail>
                    </Label>
                  </Header>
                  <Loader active={calendar.id === "TEMP_ID"} />
                  {currentUser.role === 4 || currentUser.role === 3 ? (
                    <Fragment>
                      <Popup
                        trigger={
                          <Icon
                            link
                            name="trash alternate outline"
                            color="red"
                            style={{ float: "right" }}
                            onClick={() => this.openDeleteCalendar(calendar)}
                          />
                        }
                        content="delete calendar"
                        size="mini"
                      />
                      <Popup
                        trigger={
                          <Icon
                            link
                            name="edit outline"
                            color="blue"
                            style={{ float: "right" }}
                            onClick={() => this.openEditCalendar(calendar)}
                          />
                        }
                        content="edit calendar"
                        size="mini"
                      />
                    </Fragment>
                  ) : null}
                  <CalendarMaintenance
                    currentUser={currentUser}
                    item={calendar}
                    alignRight
                  />
                </Segment>
              ))
          ) : (
            <Message color="yellow">No calendars found</Message>
          )}
        </Segment.Group>
        {currentUser.role === 4 || currentUser.role === 3 ? (
          <Button
            icon
            labelPosition="right"
            primary
            size="small"
            onClick={() => this.openAddCalendar()}
          >
            <Icon name="calendar alternate outline" /> Add New Calendar Device
          </Button>
        ) : null}
        {this.state.calendar && (
          <DeleteCalendar
            calendar={this.state.calendar}
            customer={customer}
            deleteConfirm={this.state.deleteConfirm}
            closeDeleteCalendar={this.closeModal}
            refetch={refetch}
          />
        )}
        {this.state.calendarMounted && (
          <MutateCalendar
            calendar={this.state.calendar}
            customer={customer}
            customers={customers}
            role={currentUser.role}
            edit={this.state.edit}
            calendarOpen={this.state.calendarOpen}
            closeCalendarModal={this.closeModal}
            displayModal={this.displayModal}
            refetch={refetch}
          />
        )}
      </Fragment>
    );
  }
}

export default CalendarsList;
