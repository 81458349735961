import React, { Component } from 'react';
import LoaderPage from '../components/loaderPage';
import { Container } from 'semantic-ui-react'
import { Query } from 'react-apollo';
import { CUSTOMERS } from '../graphql/queries';

import ErrorMessage from '../components/errorMessage';
import CustomersList from '../components/customer/list';

class Customers extends Component {
  render() {
    const { currentUser } = this.props;
    return <Query query={CUSTOMERS} fetchPolicy="network-only">
            {({ data, loading, client, error, networkStatus }) =>
              loading
              ? <LoaderPage />
              : <Container>
                  {error
                  ? <ErrorMessage error={error} />
                  : <CustomersList
                      data={data.customers}
                      client={client}
                      currentUser={currentUser}
                      refetch={CUSTOMERS}
                    />}
                </Container>
            }
            </Query>
  }
}

export default Customers;
