import React, { Component, Fragment } from "react";
import {
  Icon,
  Segment,
  Header,
  Label,
  Divider,
  Loader
} from "semantic-ui-react";

class ChildrenList extends Component {
  render() {
    const { compressors } = this.props;

    return this.props.data && this.props.data.length ? (
      <Fragment>
        <Divider horizontal>
          <Header as="h5">
            <Icon name="wrench" />
            Child devices
          </Header>
        </Divider>
        {this.props.data.map((child, i) => {
          const compressor = compressors.find(
            compresssor => compresssor.id === child.compressor
          );
          return (
            <Segment
              key={child.id}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline"
              }}
            >
              <Header
                as="h5"
                style={{
                  display: "inline-flex",
                  width: "70%",
                  marginBottom: "0",
                  color: child.id === "TEMP_ID" ? "lightgrey" : ""
                }}
              >
                <div style={{ width: "50%" }}>{child.name}</div>
                <Label
                  style={{ width: "40%" }}
                  color={compressor ? null : "red"}
                >
                  compressor:
                  <Label.Detail>
                    {compressor ? compressor.name : "not found"}
                  </Label.Detail>
                </Label>
              </Header>
              <Loader active={child.id === "TEMP_ID"} />
              <Fragment>
                <Label
                  color={
                    child.alarms.warning.length
                      ? child.alarms.warning[0].status === "ACKNOWLEDGED"
                        ? "grey"
                        : "yellow"
                      : child.alarms.maintenance.length
                      ? child.alarms.maintenance[0].status === "ACKNOWLEDGED"
                        ? "grey"
                        : "red"
                      : "green"
                  }
                  style={{
                    float: "right",
                    marginRight: "1rem",
                    minWidth: "150px",
                    textAlign: "center"
                  }}
                >
                  {child.currentValue}
                </Label>
              </Fragment>
            </Segment>
          );
        })}
      </Fragment>
    ) : null;
  }
}

export default ChildrenList;
