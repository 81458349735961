import React, { Component, Fragment } from 'react'
import { Menu, Icon, Transition } from 'semantic-ui-react'
import { NavLink, Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import services from '../services';
import Conf from '../config';
import UserAvatar from './userAvatar';
const session = services.session;

const DesktopMenu = ({
  marginTop,
  path,
  currentUser,
  openProfile,
  className,
  client,
  close,
  ...props}) => (
    <Menu inverted stackable className={className} style={{
      marginTop: marginTop || 0,
      position: 'fixed',
      top: 0,
      width: "100%",
      zIndex: 2,
    }}>
      <NavLink
        className='item'
        exact
        to={`${path}/dashboard`}
        activeClassName='active'
        onClick={close}
      >
        Dashboard
      </NavLink>
      {currentUser.role === 4 &&
      <NavLink
        className='item'
        to={`${path}/users`}
        activeClassName='active'
        onClick={close}
      >
        Users
      </NavLink>}
      <NavLink
        className='item'
        to={`${path}/customers`}
        activeClassName='active'
        onClick={close}
      >
        Customers
      </NavLink>
      <NavLink
        className='item'
        to={`${path}/gateways`}
        activeClassName='active'
        onClick={close}
      >
        Gateways
      </NavLink>
      <NavLink
        className='item'
        to={`${path}/devices`}
        activeClassName='active'
        onClick={close}
      >
        Devices
      </NavLink>
      <NavLink
        className='item'
        to={`${path}/map`}
        activeClassName='active'
        onClick={close}
      >
        Map
      </NavLink>
      {currentUser.role === 4 &&
      <NavLink
        className='item'
        to={`${path}/data`}
        activeClassName='active'
        onClick={close}
      >
        Data
      </NavLink>}
{/*      <a
        className='item'
        href='/docs'
      >
        Docs
      </a> */}
      <UserAvatar
        user={currentUser}
        signout={() => {
          session.signout();
        }}
        openProfile={openProfile}
        close={close}
        role={Conf.roles[currentUser.role - 1]}
      />
    </Menu>
);

class MobileMenu extends Component {
  state = { open: false }
  render() {
    return (
    <Fragment>
      <Menu inverted style={{
        marginBottom: this.state.open ? 0 : null,
        position: 'fixed',
        top: 0,
        width: "100%",
        zIndex: 2,
      }} >
        <Link to='/' className='item'>Kaeser app</Link>
        <Menu.Item
          position='right'
          as='a'
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <Icon name={this.state.open ? 'close' : 'bars'} />
        </Menu.Item>
      </Menu>
      <Transition visible={this.state.open} animation='slide down' duration={500}>
        <DesktopMenu marginTop="40px"
          currentUser={this.props.currentUser}
          signout={this.props.signout}
          openProfile={this.props.openProfile}
          path={this.props.path}
          close={() => this.setState({ open: false })}
        />
      </Transition>
    </Fragment>);
  }
}

class TopMenu extends Component {
  render() {
    return (
      <Fragment>
          <MediaQuery minWidth={768}>
            <DesktopMenu {...this.props} />
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <MobileMenu {...this.props} />
          </MediaQuery>
      </Fragment>
    )
  }
}

export default TopMenu;
