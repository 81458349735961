import React, { Component } from 'react';
import { Card, Container, Loader } from 'semantic-ui-react';
import DataTable from '../components/dataTable';
import { Query, withApollo } from 'react-apollo';
import { USERS, USER } from '../graphql/queries';
import ErrorMessage from '../components/errorMessage';
import { DeleteUser, MutateUser } from '../components/mutations/user';


import cfg from '../config';

class Users extends Component {
  state = {
      isLoading: true,
      modalOpen: false,
      edit: false,
      user : null,
      deleteConfirm: false,
  };

  closeUserModal = () => {
    this.setState({ modalOpen: false, edit: false, user: null })
  }

  openAddUser = () => {
    this.setState({ modalOpen: true })
  }

  openEditUser = async (user) => {
    const { data } = await this.props.client.query({ query: USER, variables: { id: user.id } });
    this.setState({
      modalOpen: true,
      edit: true,
      user: {
        ...data.user,
        password: '',
        confirmPassword: '',
      }
    });
  }

  openDeleteUser = (user) => {
    this.setState({
      deleteConfirm: true,
      user: {
        id: user.id,
        email: user.email,
        name: user.name,
      }
    })
  }

  closeDeleteUser = () => {
    this.setState({ deleteConfirm: false, user: null });
  }

  render() {
    const { currentUser } = this.props;
    return <Query query={USERS} fetchPolicy="network-only" notifyOnNetworkStatusChange>
            {({ data, loading, client, error, fetchMore, networkStatus }) =>
              <Container>
                {error
                ? <ErrorMessage error={error} />
                : (<Card fluid>
                    <Card.Content style={{paddingBottom: '0.7rem', paddingTop: '0.7rem'}}>
                      <Card.Header>Users</Card.Header>
                    </Card.Content>
                    <Card.Content>
                      <Loader active={loading} />
                      <DataTable
                        currentUser={currentUser}
                        networkStatus={networkStatus}
                        isRequestPending={loading}
                        openAddNew={this.openAddUser}
                        addNewIcon='user'
                        addNewText='Add User'
                        data={(data && data.users) ? data.users.values.map(user => ({ ...user, role: cfg.roles[user.role - 1] })) : []}
                        limit={(data && data.users) ? data.users.limit : 10}
                        skip={(data && data.users) ? data.users.skip : 0}
                        total={(data && data.users) ? data.users.total : 0}
                        columns={[
                          { label: 'name', field: 'name', sort: true },
                          { label: 'email', field: 'email', sort: true },
                          { label: 'Email Verified', field: 'emailVerified', bool: true, width: 2 },
                          { label: 'enabled', field: 'enabled', bool: true, width: 2 },
                          { label: 'role', field: 'role', sort: true, width: 2 },
                        ]}
                        actions={[
                          { color: 'blue', iconName: 'edit outline', onClick: this.openEditUser, tooltip: 'edit' },
                          { color: 'red', iconName: 'trash alternate outline', onClick: this.openDeleteUser, tooltip: 'delete' },
                        ]}
                        users
                        fetchMore={fetchMore}
                      />
                    </Card.Content>
                  </Card>)}
                  {this.state.user &&
                    <DeleteUser
                      user={this.state.user}
                      deleteConfirm={this.state.deleteConfirm}
                      closeDeleteUser={this.closeDeleteUser}
                    />
                  }
                  {this.state.modalOpen &&
                    <MutateUser
                      user={this.state.user}
                      edit={this.state.edit}
                      modalOpen={this.state.modalOpen}
                      closeUserModal={this.closeUserModal}
                      role={this.props.currentUser.role}
                    />
                  }
                </Container>
            }
          </Query>
  }
}

export default withApollo(Users);
