import React from 'react';
import {Mutation} from 'react-apollo';
import {Confirm} from 'semantic-ui-react';
import MaintenanceModal from '../../forms/maintenance';
import {DELETE_MAINTENANCE, ADD_MAINTENANCE, UPDATE_MAINTENANCE} from '../../../graphql/mutations';
import {updateQuery} from '../../../utils';

const newMaintenanceData = {
    name: '',
    gatewayId: '',
    params: [],
    maintenanceInterval: 0,
    enabled: true
};

export const DeleteMaintenance = ({
                                      maintenance,
                                      customer,
                                      deleteConfirm,
                                      closeDeleteMaintenance,
                                      refetch
                                  }) =>
    <Mutation
        mutation={DELETE_MAINTENANCE}
        refetchQueries={[{ query: refetch, variables: { id: customer.id } }]}
        update={(cache, { data }) => { // use optimisticResponse data for cache updating
            const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
            cache.writeQuery({
                query: refetch,
                variables: { id: customer.id },
                data: {
                    customerChildren: {
                        ...cachedData.customerChildren,
                        maintenances: cachedData.customerChildren.maintenances.map(maintenance => maintenance.id === data.response ? {
                            ...maintenance,
                            id: 'TEMP_ID'
                        } : maintenance)
                    }
                }
            });
        }}
    >
        {(deleteMaintenance, { loading, error }) =>
            <Confirm
                open={deleteConfirm}
                content={`Delete maintenance ${maintenance.name} (${maintenance.id}). Are you sure?`}
                onCancel={closeDeleteMaintenance}
                onConfirm={async () => {
                    try {
                        closeDeleteMaintenance();
                        await deleteMaintenance({
                            variables: { id: maintenance.id, customerId: maintenance.parentId },
                            optimisticResponse: { response: maintenance.id }
                        });
                    } catch (e) {
                        console.log(e.message);
                    }
                }}
            />
        }
    </Mutation>;

export const MutateMaintenance = ({
                                      maintenance,
                                      customer,
                                      customers,
                                      gateways,
                                      edit,
                                      maintenanceOpen,
                                      closeMaintenanceModal,
                                      displayModal,
                                      role,
                                      refetch,
                                  }) =>
    <Mutation
        mutation={edit ? UPDATE_MAINTENANCE : ADD_MAINTENANCE}
        refetchQueries={edit ? [] : [{ query: refetch, variables: { id: customer.id } }]}
        update={(cache, { data }) => { // use optimisticResponse data for cache updating
            if (!edit) {
                const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
                cache.writeQuery({
                    query: refetch,
                    variables: { id: customer.id },
                    data: {
                        customerChildren: {
                            ...cachedData.customerChildren,
                            maintenances: [...cachedData.customerChildren.maintenances, data.response]
                        }
                    }
                });
            } else if (data.__typename !== "Mutation" && customer.id !== data.response.parentId) {
                const cachedData = cache.readQuery({ query: refetch, variables: { id: customer.id } });
                cache.writeQuery({
                    query: refetch,
                    variables: { id: customer.id },
                    data: {
                        customerChildren: {
                            ...cachedData.customerChildren,
                            maintenances: cachedData.customerChildren.maintenances.filter(gateway => maintenance.id !== data.response.id)
                        }
                    }
                });
            }
        }}
    >
        {(mutateMaintenance, { loading, error }) =>
            <MaintenanceModal
                open={maintenanceOpen}
                onClose={closeMaintenanceModal}
                customer={customer}
                customers={customers}
                gateways={gateways}
                maintenance={edit ? maintenance : { ...newMaintenanceData, parentId: customer.id }}
                edit={edit}
                mutateDevice={mutateMaintenance}
                displayModal={displayModal}
                loading={loading}
                role={role}
                error={error}
            />}
    </Mutation>;

export const TableDeleteMaintenance = ({
                                           maintenance,
                                           customer,
                                           deleteConfirm,
                                           closeDeleteMaintenance,
                                           fetchMore,
                                           variables,
                                       }) =>
    <Mutation
        mutation={DELETE_MAINTENANCE}
        update={(cache, { data }) => {
            if (data.___typename !== "Mutation") {
                fetchMore({ variables, updateQuery });
            }
        }}
    >
        {(deleteMaintenance, { loading, error }) =>
            <Confirm
                open={deleteConfirm}
                content={`Delete maintenance ${maintenance.name} (${maintenance.id}). Are you sure?`}
                onCancel={closeDeleteMaintenance}
                onConfirm={async () => {
                    try {
                        closeDeleteMaintenance();
                        await deleteMaintenance({
                            variables: { id: maintenance.id, customerId: maintenance.parentId },
                            optimisticResponse: { response: maintenance.id, ___typename: "Mutation" }
                        });
                    } catch (e) {
                        console.log(e.message);
                    }
                }}
            />
        }
    </Mutation>;

export const TableMutateMaintenance = ({
                                           maintenance,
                                           customer,
                                           customers,
                                           gateways,
                                           edit,
                                           maintenanceOpen,
                                           closeMaintenanceModal,
                                           displayModal,
                                           fetchMore,
                                           role,
                                           variables,
                                       }) =>
    <Mutation
        mutation={edit ? UPDATE_MAINTENANCE : ADD_MAINTENANCE}
        update={(cache, { data }) => {
            if (data.__typename !== "Mutation") {
                if (!edit) {
                    fetchMore({ variables, updateQuery });
                } else if (customer.id !== data.response.parentId) {
                    fetchMore({ variables, updateQuery });
                }
            }
        }}
    >
        {(mutateMaintenance, { loading, error }) =>
            <MaintenanceModal
                open={maintenanceOpen}
                onClose={closeMaintenanceModal}
                customer={customer}
                customers={customers}
                gateways={gateways}
                maintenance={edit ? maintenance : { ...newMaintenanceData, parentId: customer.id }}
                edit={edit}
                mutateDevice={mutateMaintenance}
                displayModal={displayModal}
                loading={loading}
                role={role}
                error={error}
            />}
    </Mutation>;
