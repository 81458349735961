import React, { Component } from 'react';
import { Container, Button, Input, Icon } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { DATA_RESTORE } from '../../../graphql/mutations';

class DataRestore extends Component {
  state = {
    file: '',
  }

  handleChange = ({
    target: {
      validity,
      files: [file]
    }
  }) => {
    if(validity.valid && file) {
      this.setState({ file });
    } else {
      this.setState({ file: '' });
    }
  }

  render() {
    return (
            <Mutation
                mutation={DATA_RESTORE}
                update={(cache, { data }) => {
                  if(data && data.dataRestore) {
                    this.setState({ file: '' }, () => toast.success(data.dataRestore));
                  }
                }}
            >
                {(dataRestore, { loading, error }) => (
                  <Container textAlign='right'>
                    <Input
                        type='file'
                        onChange={this.handleChange}
                        accept=".json"
                    />
                    <Button
                        disabled={!this.state.file}
                        icon
                        loading={loading}
                        type="button"
                        labelPosition='left'
                        primary
                        onClick={() => dataRestore({ variables: { file: this.state.file } })}
                      >
                        <Icon name='upload' />
                        Restore
                    </Button>
                  </Container>)}
              </Mutation>
        );
    }
}

export default DataRestore;
