import React from 'react';
import { Container, Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import { object as yupObject, string as yupString } from 'yup';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import logo from '../../img/kaeser_logo-300x138.png';

const ForfgotPasswordForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  loading,
  error,
  isValid,
}) => (
  <div className='login-form'>
    {values.success
      ? (<Container>
          <Message positive>
            <Message.Header>Success!</Message.Header>
            Email was sent to {values.email}. Check your mailbox to continue.
          </Message>
        </Container>
      ):(
      <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Link to='/'>
          <Image src={logo} style={{marginLeft: 'auto', marginRight: 'auto'}} />
        </Link>
        <Header as='h2' color='blue' textAlign='center'>
          Reset password form
        </Header>
        <Form size='large' error onSubmit={handleSubmit}>
          <Segment stacked>
            <Form.Input
              fluid
              icon='user'
              iconPosition='left'
              placeholder='E-mail address'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              name='email'
              disabled={loading}
            />
            {errors.email && touched.email &&
              <Message
                error
                header='error'
                content={errors.email}
              />
            }
            <Button
              color='blue'
              type='submit'
              fluid
              size='large'
              disabled={loading || !isValid}
              loading={loading}
            >
              Send
            </Button>
            {error &&
              <Message
                error
                header='error'
                content={error.message}
              />
            }
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>)}
  </div>
)

export default withFormik({
  mapPropsToValues: () => ({email: ''}),
  validationSchema: yupObject().shape({
    email: yupString()
      .email('Invalid email')
      .required('email is required'),
  }),
  handleSubmit: async (values, { setFieldValue, props }) => {
    try {
      await props.sendResetPasswordEmail({ variables: { ...values } });
      setFieldValue('success', true);
    } catch (e) {
      setFieldValue('success', false);
      console.log(e.message);
    }
  },
})(ForfgotPasswordForm);
