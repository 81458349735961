import React, { Component, Fragment }  from "react";
import { Formik, Field, Form as FormikForm } from 'formik';
import {object as yupObject, string as yupString, boolean as yupBoolean, ref as yupRef, number as yupNumber } from 'yup';
import { Button, Form, Icon, Container, Modal, Message } from 'semantic-ui-react'

import FormField from './formField';

const composeData = values => {
  return values.changePassword
  ? {
      name: values.name,
      email: values.email,
      emailVerified: values.emailVerified,
      password: values.password,
      enabled: values.enabled,
      role: values.role,
    }
  : {
      name: values.name,
      email: values.email,
      emailVerified: values.emailVerified,
      enabled: values.enabled,
      role: values.role,
  };
}

const UserFormVS = yupObject().shape({
  edit: yupBoolean(),
  changePassword: yupBoolean(),
  name: yupString()
    .required('name is required'),
  email: yupString()
    .email('Invalid email')
    .required('email is required'),
  emailVerified: yupBoolean(),
  phoneNumber: yupString()
    .matches(/^\+?[1-9]\d{8,14}$/),
  password: yupString()
    .when('changePassword', {
      is: true,
      then: yupString()
        .required('password is required'),
      otherwise: yupString()
    }),
  confirmPassword: yupString()
    .when('changePassword', {
      is: true,
      then: yupString()
        .oneOf([yupRef('password', null)], 'Passwords do not match')
        .required('password is required'),
      otherwise: yupString()
    }),
  enabled: yupBoolean(),
  role: yupNumber()
    .required('role is required')
});

const UserModal = ({
  user,
  edit,
  mutateUser,
  ...props
}) =>
  <Formik
  	initialValues={{...user, edit, changePassword: !edit}}
  	validationSchema={UserFormVS}
    enableReinitialize
    isInitialValid={props => {
      UserFormVS.isValid(props.initialValues)
      .then(valid => valid)
    }}
  	onSubmit={async (values) => {
    		const data = composeData(values);
        try {
          await mutateUser({
            variables: { user: data, ...(edit && { id: user.id }) },
          });
          props.onClose();
        } catch (e) {
          console.log(e.message);
        }
      }
    }
    render={renderProps => <User
      {...props}
      {...renderProps}
    />}
  />

class User extends Component {
  render() {
    const {
      errors,
      touched,
      values,
      open,
      onClose,
      loading,
      error,
      isValid
    } = this.props;
    return (
      <Modal
        open={open}
        onClose={onClose}
        size='tiny'
        closeOnDocumentClick
      >
        <Modal.Header>{values.edit ? 'Edit user' : 'Add user'}</Modal.Header>
        <Modal.Content>
          <FormikForm className="ui form error">
            <FormField
              className="required"
              name="name"
              label="Name"
              placeholder="Name"
              errors={errors}
              touched={touched}
              Component={Form.Input}
            />
            <FormField
              className="required"
              name="email"
              label="Email"
              placeholder="Email"
              type='email'
              errors={errors}
              touched={touched}
              Component={Form.Input}
            />
            <FormField
              name="emailVerified"
              label="Email verified"
              errors={errors}
              touched={touched}
              Component={Form.Checkbox}
            />
            <Field
              name="role"
              render={({field, form}) => (
                <Fragment>
                <Form.Select
                  className="required"
                  name={field.name}
                  label='Role'
                  placeholder="Select user's Role"
                  value={field.value}
                  options={['viewer', 'editor', 'admin', 'superadmin'].map((role, i) => ({
                    value: i + 1,
                    text: role
                  }))}
                  error={errors[field.name] && touched[field.name]}
                  onChange={(e, selected) => {
                    form.setFieldValue(field.name, selected.value);
                  }}
                />
                {errors[field.name] && touched[field.name] &&
                  <Message
                    error
                    header='error'
                    content={errors[field.name]}
                  />
                }
                </Fragment>
              )}
            />
            {values.edit &&
            <FormField
              name="changePassword"
              label="Change password"
              errors={errors}
              touched={touched}
              Component={Form.Checkbox}
            />}
            {(!values.edit || values.changePassword) &&
            <Fragment>
              <FormField
                name="password"
                label='Password'
                type='password'
                placeholder='Password'
                errors={errors}
                touched={touched}
                Component={Form.Input}
              />
              <FormField
                name="confirmPassword"
                label='Confirm password'
                type='password'
                placeholder='Confirm password'
                errors={errors}
                touched={touched}
                Component={Form.Input}
              />
            </Fragment>}
            <FormField
              name="enabled"
              label="User enabled"
              errors={errors}
              touched={touched}
              Component={Form.Checkbox}
            />
            {error &&
            <Message
              error
              header='Server error'
              content={error.message}
            />}
            <Container textAlign='right'>
              <Button primary type='submit' role='button' disabled={loading || !isValid} loading={loading}>
                <Icon name='checkmark' /> Save
              </Button>
              <Button secondary basic onClick={onClose}>
                <Icon name='remove' /> Cancel
              </Button>
            </Container>
          </FormikForm>
        </Modal.Content>
      </Modal>
    );
  }
}

export default UserModal;
