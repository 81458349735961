import React from 'react';
import {Editor as TinyEditor} from '@tinymce/tinymce-react';
import config from '../../../config';

export default function Editor({
                                   fieldValue,
                                   setFieldValue
                               }) {
    return (
        <TinyEditor
            value={fieldValue}
            apiKey={config.TINY_MCE_EDITOR_APIKEY}
            onEditorChange={(content) => {
                setFieldValue("notes", content);
            }}
            init={{
                height: 350,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help | table',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
        />
    );
}
