import React, {Component} from "react";
import {Formik, Form as FormikForm} from 'formik';
import {object as yupObject, ref as yupRef, number as yupNumber} from 'yup';
import {Button, Form, Icon, Container, Modal, Message} from 'semantic-ui-react'
import moment from 'moment';
import DatePicker from "react-datepicker";

import AddTime from './addTime';
import cfg from '../../config';

const CalendarSetNewDateModal = ({
                                     calendar,
                                     updateAlarm,
                                     ...props
                                 }) =>
    <Formik
        initialValues={{
            ...calendar,
            maintenanceDateTime: +moment().add(calendar.defaultCalendarMaintenanceCycle || cfg.defaultSettings.defaultCalendarMaintenanceCycle, 'month'),
            maintenanceWarningDateTime: +moment().add(calendar.defaultCalendarWarningCycle || cfg.defaultSettings.defaultCalendarWarningCycle, 'month'),
        }}
        validationSchema={yupObject().shape({
            maintenanceDateTime: yupNumber()
                .required('maintenanceDateTime is required')
                .min(yupRef('maintenanceWarningDateTime')),
            maintenanceWarningDateTime: yupNumber()
                .required('maintenanceWarningDateTime is required')
                .min(+moment().add(1, 'day')),
        })}
        isInitialValid
        onSubmit={async (values, {setStatus, setSubmitting}) => {
            console.log(values);
            try {
                await updateAlarm({
                    variables: {
                        id: calendar.alarms.maintenance.length ? calendar.alarms.maintenance[0].id : calendar.alarms.warning[0].id,
                        status: "CLEARED",
                        calendarId: calendar.id,
                        maintenanceDateTime: +moment(values.maintenanceDateTime),
                        maintenanceWarningDateTime: +moment(values.maintenanceWarningDateTime),
                        customerId: calendar.parentId,
                    }
                });
            } catch (e) {
                console.log(e.message);
            }
        }
        }
        render={renderProps => <CalendarSetNewDate
            {...props}
            {...renderProps}
        />}
    />

class CalendarSetNewDate extends Component {
    handleChange = (name, value) => {
        this.props.setFieldValue(name, +moment(value));
    }

    render() {
        const {
            errors,
            touched,
            values,
            open,
            onClose,
            loading,
            isValid,
            role,
        } = this.props;
        return (
            <Modal
                open={open}
                onClose={onClose}
                size='tiny'
                closeOnDocumentClick
            >
                <Modal.Header>{`Set new maintenance and warning date/time ${values.name}`}</Modal.Header>
                <Modal.Content>
                    <FormikForm className="ui form error">
                        <Form.Group widths='equal' style={{alignItems: 'flex-end'}}>
                            <Form.Field
                                width='7'
                                className="required"
                            >
                                <label>Maintenance DateTime</label>
                                <DatePicker
                                    name="maintenanceDateTime"
                                    value={moment(values.maintenanceDateTime).format('YYYY-MM-DD HH:mm')}
                                    selected={moment(values.maintenanceDateTime).toDate()}
                                    label="Maintenance DateTime"
                                    placeholder="Maintenance Warning DateTime"
                                    minDate={moment(values.maintenanceWarningDateTime).toDate()}
                                    readOnly={role < 3}
                                    onChange={(value) => this.handleChange('maintenanceDateTime', value)}
                                    error={errors["maintenanceDateTime"] && touched["maintenanceDateTime"]}
                                    noError
                                    showTimeSelect
                                />
                                {errors["maintenanceDateTime"] && touched["maintenanceDateTime"] &&
                                    <Message
                                        error
                                        header='error'
                                        content={errors["maintenanceDateTime"]}
                                    />
                                }
                            </Form.Field>
                            <AddTime
                                baseValue={values.maintenanceDateTime}
                                calendar
                                disabled={role < 3}
                                setValue={(value) => this.props.setFieldValue("maintenanceDateTime", value)}
                                options={[
                                    {value: 'hour', text: 'hours'},
                                    {value: 'day', text: 'days'},
                                    {value: 'month', text: 'months'}
                                ]}
                            />
                        </Form.Group>
                        <Form.Group widths='equal' style={{alignItems: 'flex-end'}}>
                            <Form.Field
                                width='7'
                                className="required"
                            >
                                <label>Maintenance Warning DateTime</label>
                                <DatePicker
                                    name="maintenanceWarningDateTime"
                                    value={moment(values.maintenanceWarningDateTime).format('YYYY-MM-DD HH:mm')}
                                    selected={moment(values.maintenanceWarningDateTime).toDate()}
                                    label="Maintenance Warning DateTime"
                                    placeholder="Maintenance Warning DateTime"
                                    minDate={values.edit ? moment(values.maintenanceWarningDateTime).toDate() : moment().add(1, 'day').toDate()}
                                    readOnly={role < 3}
                                    onChange={(value) => this.handleChange('maintenanceWarningDateTime', value)}
                                    error={errors["maintenanceWarningDateTime"] && touched["maintenanceWarningDateTime"]}
                                    noError
                                    showTimeSelect
                                />
                                {errors["maintenanceWarningDateTime"] && touched["maintenanceWarningDateTime"] &&
                                    <Message
                                        error
                                        header='error'
                                        content={errors["maintenanceWarningDateTime"]}
                                    />
                                }
                            </Form.Field>
                            <AddTime
                                baseValue={values.maintenanceWarningDateTime}
                                calendar
                                disabled={role < 3}
                                setValue={(value) => this.props.setFieldValue("maintenanceWarningDateTime", value)}
                                options={[
                                    {value: 'hour', text: 'hours'},
                                    {value: 'day', text: 'days'},
                                    {value: 'month', text: 'months'}
                                ]}
                            />
                        </Form.Group>
                        <Container textAlign='right'>
                            <Button secondary basic onClick={onClose}>
                                <Icon name='remove'/> Cancel
                            </Button>
                            <Button primary type='submit' role='button' disabled={loading || !isValid}
                                    loading={loading}>
                                <Icon name='checkmark'/> Save
                            </Button>
                        </Container>
                    </FormikForm>
                </Modal.Content>
            </Modal>
        );
    }
}

export default CalendarSetNewDateModal;
